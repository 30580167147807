import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnChanges } from '@angular/core';
import * as JsonObjects from '../../classes'
import { CustomString } from '../../custom-string';
import { FormGroup } from '@angular/forms';
import { ResponsiveService } from '../../services/resonsive.service';
import { FormComponent } from '../../structureObject';
import { ShowSumService } from '../../services/show-sum.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
    selector: 'app-textControl',
    templateUrl: './textControl.component.html',
    styleUrls: ['./textControl.component.scss']
})
export class TextControlComponent implements OnInit, AfterViewInit{

    @Input() input: JsonObjects.TextControl;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש
    isHidden: boolean=false;
    
  

    // displayHtml:string = '';
    idExtension: string = '';//מזהה עותק
    get eDevice(){
        return FormComponent.eDevice;
    }
    get device(){
        return  this.responsiveService.device;
    }
    constructor(private responsiveService:ResponsiveService,private customString: CustomString, private showSum : ShowSumService, private cdref: ChangeDetectorRef) {
      
    }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק 
        this.showSum.getHidden(this.input.ControlId+this.idExtension).subscribe(currentIsHidden=>{
        this.isHidden=currentIsHidden;
        })
 
    }

    ngAfterViewInit() {        
   setTimeout(() => {
       let status = this.customString.execCode(this.input.ControlId+this.idExtension);
       this.isHidden=!status;
   });

    }
   
    ngAfterViewChecked() {

        this.cdref.detectChanges();
    
      }
}
