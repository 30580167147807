

//namespace a{
 
    
    export class BankBranch {
       
Code? :string;
Name? :string;
BankCode? :string;
BankName? :string;
Address? :string;
City? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










