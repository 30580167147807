

//namespace a{

import { Control } from './Control'

export class Chart extends Control {

    TypeChart?: string;
    ChartControls?: ChartControl[];
}

import { SectionControl } from './SectionControl'
export class ChartControl {

    Control?: SectionControl;
    Color?: string;
}

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










