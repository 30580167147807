import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JsonObjects from '../../classes'
@Component({
  selector: 'app-how-it-work',
  templateUrl: './how-it-work.component.html',
  styleUrls: ['./how-it-work.component.scss']
})
export class HowItWorkComponent implements OnInit {
  @Input() input: JsonObjects.HowItWork;//הפקד הנוכחי
  @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
  @Input() userForm: FormGroup;//טופס משתמש
  constructor() { }
  idExtension: string = '';//מזהה עותק
  classList: string[] = [];//קלאסים של הפקד הנוכחי

  ngOnInit() {
      this.classList = this.input.Class ? this.input.Class.split(' ') : [];//שליפת הקלאסים של הפקד
      this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק 
  }
  isClassExists(isClass: string = '') {
      return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
  } 
 

}
