

//namespace a{
 
    
    export class DistributionRoute {
       
RouteId? :string;
RouteName? :string;
RoutePercent? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










