import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as JsonObjects from '../../classes'
import { Subscription } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/reducer';
import { ApiService } from '../../services/api.service';
import { LevelSectionService } from '../../services/levelSection.service';
import { ConditionalLogic } from '../../services/conditionalLogic.service';
import { ResponsiveService } from '../../services/resonsive.service';
import { FormComponent } from '../../structureObject';

@Component({
    selector: 'app-refSection',
    templateUrl: './ref-section.component.html',
    styleUrls: ['./ref-section.component.scss']
})
export class RefSectionComponent implements OnInit, OnDestroy {

    @Input() input: JsonObjects.RefSection;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Output() setSectionCompleted: EventEmitter<string> = new EventEmitter();//הצהרה שהמקטע הושלם

    idExtension: string = '';//מזהה עותק
    currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי
    refSections: JsonObjects.RefJsonFile[] = [];//המקטעים שאליו הוא מצביע 
    currentRefSection: JsonObjects.FormSection;//המקטע שאליו הפקד הצביע מכיל פקדים
    expansionPanelSection: {//מערך המקטעים שהם בתצוגת לוח הרחבה 
        [key: string]: {
            isOpen: boolean;//הגדרה האם המקטע פתוח
            isDisable: boolean;//הגדרה האם המקטע מושבת
        }
    } = {};
    get eDevice(){
        return FormComponent.eDevice;
      }
      get device(){
        return this.responsiveService.device;
      }
    private subscription: Subscription = new Subscription();
    constructor(private responsiveService:ResponsiveService,private ngRedux: NgRedux<IAppState>, public apiService: ApiService, private cd: ChangeDetectorRef
        , private levelSectionService: LevelSectionService, private conditionalLogic: ConditionalLogic) {
    }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
        this.subscription.add(this.ngRedux.select('Currentlevel').subscribe((currentLevel: JsonObjects.RefJsonFile) => {//עדכון השלב הנוכחי
            this.currentLevel = JSON.parse(JSON.stringify(currentLevel));
            var sections = this.currentLevel.Children.filter(s => s.Num == this.input.SectionNum);//שליפת המקטעים שהוא מצביע אליו כולל המוכפלים
            if (this.refSections != sections) {
                this.refSections = sections;
                if (this.refSections.length)
                    this.setSection();
            }
        }))

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        var idExtension;
        this.refSections.forEach((rSection, index) => {
            //יש להפוך את הפקדים של המקטע לדסייבל
            idExtension = rSection.Copy != null ? `_COPY_${rSection.Copy}` : '';//הוספת מזהה עותק
            this.conditionalLogic.disableControlFormSection(this.currentRefSection.Controls, idExtension);
        })

    }

    setSection() { //שליפת המקטע יש לשלוף רק מהראשון כי השאר הם מקטעים מוכפלים
        this.currentRefSection = this.levelSectionService.getSectionArray()[this.refSections[0].Num];  //הכנסה למערך את המקטעים
            var idExtension;
            this.refSections.forEach((rSection, index) => {
                this.expansionPanelSection[rSection.Num + index] = { isOpen: index == 0 ? false : true, isDisable: false };
                //יש להפוך את הפקדים של המקטע לפעילים
                idExtension = rSection.Copy != null ? `_COPY_${rSection.Copy}` : '';//הוספת מזהה עותק
                var enableControls: JsonObjects.Control[] = this.currentRefSection.Controls.filter(control => {
                    if (!(control instanceof JsonObjects.Input && (control as JsonObjects.Input).Hidden) && !control.Disabled)
                        if (control.ConditionalLogicGroup.Activated == true) {
                            if (this.conditionalLogic.checkCondition(false,control.ControlId + idExtension, control.ConditionalLogicGroup, false, idExtension, this.currentRefSection.SectionNum))
                                return control
                        }
                        else return control


                })
                this.conditionalLogic.enableControlFormSection(enableControls, idExtension);
            })
    }


    setRefSectionCompleted(sectionNum: string) {
        this.setSectionCompleted.emit(sectionNum);//המקטע הושלם
    }

    expansionPanelOpen(sectionNum: string) {//כשלוח הרחבה של מקטע נפתח    
        //יש לסגור את המקטעים
        Object.keys(this.expansionPanelSection).filter(sNum => sNum != sectionNum && this.expansionPanelSection[sNum].isOpen).forEach((sNum: string) => {
            this.expansionPanelSection[sNum].isOpen = false;
        });

        if (this.expansionPanelSection[sectionNum] && !this.expansionPanelSection[sectionNum].isOpen)//אם המקטע קיים ברשימת המקטעים המוצגים כלוח הרחבה
            this.expansionPanelSection[sectionNum].isOpen = true;//ונעדכן שהמקטע הנוכחי פתוח    
    }
}