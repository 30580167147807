import { UPDATE_LOCATION } from '@angular-redux/router';
import { CounterActions } from './actions';
import * as JsonObjects from '../../src/app/shared/classes'
import { FormComponent } from '../app/shared/structureObject'
import { AbstractControl, FormGroup } from '@angular/forms';

export interface IAppState {
  router: string;//ראוטר
  UserForm: FormGroup;//טופס משתמש הנוכחי
  UserDetailsEntered?: JsonObjects.UserInputs;//ערכי משתמש
  Currentlevel: JsonObjects.RefJsonFile;//השלב הנוכחי
  CurrentLevelSettings: JsonObjects.Level;//הגדרות שלב נוכחי
  FormSettings: FormComponent.GeneralSetting;//הגדרות טופס 
  Navigate: FormComponent.Navigate;//מערכן כאשר מנווטים לשלב אחר
}

export const INITIAL_STATE: IAppState = {
  router: "",
  UserForm: new FormGroup({}),
  UserDetailsEntered: { Id: "userInputs", Value: "userInputs", Type: "process", Children: [] } as JsonObjects.UserInputs,
  Currentlevel: {} as JsonObjects.RefJsonFile,
  CurrentLevelSettings: {} as JsonObjects.Level,
  Navigate: { LevelNum: "" },
  FormSettings: {
    ProcessNum: "",
    RunNum: create_UUID(),
    StatusUser: FormComponent.eStatusUser.InProcess,
    IsCompletedSections: false,
    SecondaryProcess: {}
  } as FormComponent.GeneralSetting,

};
function create_UUID() {//יוצר מספר רנדומלי עבור כל ריענון של האתר
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
export const rootReducer =
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case UPDATE_LOCATION://עדכון הראוטר ע"י סיפריה
        return { ...state, router: action.payload };

      case CounterActions.UPDATE_USER_FORM://עדכון טופס משתמש
        return {
          ...state, UserForm: new FormGroup(action.userFormControls)
        }

      case CounterActions.DISABLE_CONTROL_FORM://מחיקת פקד מטופס משתמש
        state.UserForm.controls[action.controlId].disable()//הכונה לכבות את הולידציות של הפקד
        return state

      case CounterActions.ENABLE_CONTROL_FORM://הוספת פקד לטופס משתמש
        {
          let control: AbstractControl = state.UserForm.controls[action.controlId];
          if (control)
            control.enable()//הוספת הפקד - הכונה לאפשר לפקד לעורר את הולידציות שלו
          return { ...state }
        }

      case CounterActions.DISABLE_CONTROLS_FORM://מחיקת פקדים מטופס משתמש
        return { ...state, UserForm: action.userForm }

      case CounterActions.ENABLE_CONTROLS_FORM://הוספת פקדים לטופס משתמש      
        return { ...state, UserForm: action.userForm }


      case CounterActions.SET_VALUE_CONTROL_FORM://הכנסת ערך לפקד טופס משתמש
        {
          state.UserForm.controls[action.control.id].setValue(action.control.value)
          state.UserForm.controls[action.control.id].markAsDirty();
          return state
        }

      case CounterActions.SET_ERROR_CONTROL_FORM://הכנסת שגיאה לפקד טופס משתמש
        if (state.UserForm.controls[action.control.id])
          state.UserForm.controls[action.control.id].setErrors(action.control.value)
        return state
      case CounterActions.ADD_COPY_SECTION_INPUTS_COMPONENT://הכנסת שגיאה לפקד טופס משתמש 
        {
          let IsectionToCopy: number = state.UserDetailsEntered.Children.map(s => s.Id).lastIndexOf(action.sectionId);//אל תחזיר את הקומפוננטה שצריכה להימחק
          if (IsectionToCopy != -1) {
            let sectionToCopy: JsonObjects.UserInputs = JSON.parse(JSON.stringify(state.UserDetailsEntered.Children[IsectionToCopy]));
            let idExtension = `_COPY_${action.copy}`
            sectionToCopy.Id = sectionToCopy.Id + idExtension;
            state.UserDetailsEntered.Children.splice(IsectionToCopy + 1, 0, sectionToCopy);//הכפלת המקטע בשלב הנוכחי          
            state.UserDetailsEntered.Children[IsectionToCopy + 1].Children.forEach((control: JsonObjects.UserInputs, i) => {
              state.UserDetailsEntered.Children[IsectionToCopy + 1].Children[i].Id = control.Id + idExtension
              state.UserDetailsEntered.Children[IsectionToCopy + 1].Children[i].Value = "";//איפוס
            });
          }
          return state
        }
      case CounterActions.INSERT_INPUTS_COMPONENT://הכנסת ערכי השתמש לפי מקטע
        //אם מזהה הקומפוננטה לא קיים בקלטים הקודמים
        if (state.UserDetailsEntered.Children.length === 0 || state.UserDetailsEntered.Children.map(s => s.Id).indexOf(action.userInputs.Id) < 0)
          //הוספת מזהה הקופוננטה

          state.UserDetailsEntered.Children.push({ Id: action.userInputs.Id, Value: action.userInputs.Value, Type: action.userInputs.Type, IsDraft: action.userInputs.IsDraft } as JsonObjects.UserInputs);
        //עדכון ודריסת פקדי הקומפוננטה לפי המזהה שלה
        return {
          ...state, UserDetailsEntered: {
            ...state.UserDetailsEntered,
            Children: state.UserDetailsEntered.Children.map(
              (input) => input.Id === action.userInputs.Id ? { ...input, Children: action.userInputs.Children } : input
            )
          }
        }
      case CounterActions.REMOVE_INPUTS_COMPONENT://מחיקת ערכי משתמש לפי מקטע
        //אם מזהה הקומפוננטה  קיים בקלטים הקודמים
        if (state.UserDetailsEntered.Children.length != 0 || state.UserDetailsEntered.Children.map(s => s.Id).indexOf(action.IdInput) >= 0) {
          //רק אז תמחק
          var updateUserDetailsEntered = state.UserDetailsEntered.Children.filter((input) => { return input.Id != action.IdInput });//אל תחזיר את הקומפוננטה שצריכה להימחק
          return {
            ...state, UserDetailsEntered: {
              ...state.UserDetailsEntered,
              Children: updateUserDetailsEntered
            }
          }

        }
        return state;
      case CounterActions.UPDATE_INPUTS_COMPONENT://עדכון ערכי משתמש לפי מקטע
        {  //אם מזהה הקומפוננטה קיים בקלטים הקודמים
          if (state.UserDetailsEntered.Children.length != 0 && state.UserDetailsEntered.Children.map(s => s.Id).indexOf(action.userInputs.Id) >= 0) {
            //עדכון פקדי הקומפוננטה לפי המזהה שלה
            state.UserDetailsEntered.Children.forEach(
              (section, index) => {
                if (section.Id === action.userInputs.Id) {
                  section.Children.forEach(
                    (child, i) => {
                      var updateChildIndex = action.userInputs.Children.findIndex(updateChild => updateChild.Id == child.Id);
                      if (updateChildIndex >= 0) {
                        state.UserDetailsEntered.Children[index].Children[i].Value = action.userInputs.Children[updateChildIndex].Value;
                        state.UserDetailsEntered.Children[index].Children[i].IsDraft = !action.userInputs.Children[updateChildIndex].IsDraft;
                      }
                    })
                }
              })
            return state;
          }
          else {
            return { ...state, UserDetailsEntered: action.userInputs }
          }
        }

      case CounterActions.UPDATE_DRAFT_COMPONENTS://עדכון ערכי משתמש המושבתים- טיוטה שבמקטעים מושבתים
        {  //אם מזהה הקומפוננטה קיים בקלטים הקודמים
          if (state.UserDetailsEntered.Children.length != 0 && action.draftComponentsId) {
            //עדכון הקומפוננטה כמושבתת
            action.draftComponentsId.forEach(id => {
              var updateSectionIndex = state.UserDetailsEntered.Children.findIndex(updateChild => updateChild.Id == id);
              if (updateSectionIndex >= 0)
                state.UserDetailsEntered.Children[updateSectionIndex].IsDraft = true;
            })
          }
          return state;
        }
      case CounterActions.UPDATE_DIS_DRAFT_COMPONENTS://עדכון ערכי משתמש המאופשרים- לא טיוטה שבמקטעים מאופשרים
        {  //אם מזהה הקומפוננטה קיים בקלטים הקודמים
          if (state.UserDetailsEntered.Children.length != 0 && action.disdraftComponentsId) {
            //עדכון הקומפוננטה כמאופשרת
            action.disdraftComponentsId.forEach(id => {
              var updateSectionIndex = state.UserDetailsEntered.Children.findIndex(updateChild => updateChild.Id == id);
              if (updateSectionIndex >= 0)
                state.UserDetailsEntered.Children[updateSectionIndex].IsDraft = false;
            })
          }
          return state;
        }

      case CounterActions.CHANGE_CURRENT_LEVEL://שינוי השלב הנוכחי
        return { ...state, Currentlevel: action.payload };

      case CounterActions.CHANGE_CURRENT_LEVEL_SETTINGS://שינוי הגדרות שלב
        return { ...state, CurrentLevelSettings: action.payload }

      case CounterActions.CHANGE_STATUS_USER://שינוי הגדות טופס
        {
          (state.FormSettings as FormComponent.GeneralSetting).StatusUser = action.payload
          return { ...state };
        }
      case CounterActions.CHANGE_STATUS_USER_SECONDRAY_PROCESS://שינוי הגדות טופס
        {
          (state.FormSettings as FormComponent.GeneralSetting).SecondaryProcess.StatusUser = action.payload
          return { ...state };
        }
      case CounterActions.CHANGE_NUM_SECOUND_SECONDRAY_PROCESS://שינוי הגדות טופס
        {
          (state.FormSettings as FormComponent.GeneralSetting).SecondaryProcess.ProcessNum = action.payload
          return { ...state };
        }
      case CounterActions.SET_PROCESS_NUM:
        {
          (state.FormSettings as FormComponent.GeneralSetting).ProcessNum = action.payload
          return { ...state };
        }
      case CounterActions.CHANGE_SERVER_ERROR_MESSAGE:
        {
          (state.FormSettings as FormComponent.GeneralSetting).ServerErrorMessage = action.payload
          return { ...state };
        }
      case CounterActions.CHANGE_IS_COMPLETED_SECTIONS:
        {
          (state.FormSettings as FormComponent.GeneralSetting).IsCompletedSections = action.payload
          return { ...state };
        }
      case CounterActions.CHANGE_SECTION_HEADER:
        {
          state.Currentlevel.Children[action.payload.indexSection].CompName = action.payload.headerSection;
          return { ...state };
        }
      case CounterActions.CHANGE_NAVIGATE_LEVEL:
        {
          state.Navigate.StatusNavigate = action.payload.statusNavigate;
          state.Navigate.LevelNum = action.payload.levelNum;
          return { ...state };
        }
    }
    return state;
  }





