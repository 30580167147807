<div class="popup-container" >

    <button  
            aria-label="אינפיניטי. לחץ לסגירת ההודעה" class="btn-cancel" mat-button (click)="closePopup()"><img
            src="./assets/img-lg/x.svg" alt></button>
  
    <div class="popup-header" role="heading" aria-level="1">כדי להמשיך, עלייך להזין את קוד האימות שנשלח אלייך לנייד
    </div>
    <div class="popup-header" role="heading" aria-level="4">הקוד יפוג בעוד 5 דקות</div>
    <div class="popup-body">
        <div class="otp-container" [formGroup]="otps">
            <input class="otp-input" placeholder="******" maxlength="6" type="number" id="otpInsert"  [formControl]="otps.get('otpInput')">
            <div class="otp-input-container" *ngFor="let otpInput of otpInputs; let i = index">
                <!-- <input class="otp-input" id="otpInsert" type="text" [value]="otpInput.value" (keydown)="onKeydown($event, i)"> -->
                <!--
            <input _ngcontent-blt-c325="" kendotextbox="" placeholder="****" formcontrolname="otpCode" required="" autocomplete="one-time-code" type="tel" inputmode="numeric" class="otp-input" maxlength="4" aria-describedby="">
        -->
            </div>
        </div>
        <!-- <div class="resendOtp" (click)="resendOtp()">
            <button [disabled]="isDisabled">לא קיבלת קוד? <u>לשליחה חוזרת</u></button>
        </div> -->
        <p *ngIf="isDisabled" style="color: rgb(223, 16, 16);">לשליחה חוזרת אנא המתן :  {{ countdown }} שניות</p>
        <div class="resendOtp" 
            (click)="!isDisabled ? resendOtp() : null"
            [ngClass]="{'disabled': isDisabled}">
        <a>לא קיבלת קוד? <u>לשליחה חוזרת</u></a>
        </div>
            <div class="otp-error d-none-md">הקוד שהוכנס שגוי או פג תוקף</div>
        </div>
    <div class="popup-footer">
        <button type="submit" class="submit-button" (click)="submitOtp()">להמשך התהליך</button>
        <!--[disabled]="!isValidOtp()" -->
    </div>
</div>