import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducer';
import { CounterActions } from '../../../store/actions';
import * as JsonObjects from '../classes';
import { CustomString } from '../custom-string';
import { UserValueEnterrd } from './user-value-entered.service';


@Injectable({ providedIn: 'root' })
export class ProgrammingExpressionService {

    constructor(private customString: CustomString, private ngRedux: NgRedux<IAppState>, private actions: CounterActions, private userValueService: UserValueEnterrd) {
    }
    activatingProgrammerCodeControls(currentSection: JsonObjects.FormSection, userForm: FormGroup, idExtension: string)//הפעלת הפקדים שיש להם קוד מתכנת
    {
        currentSection.Controls.filter(c => c.ProgrammingExpression && c.ProgrammingExpression.Expression && c.ControlType != 'button')
            .forEach((c: JsonObjects.Control) => {
                let container = userForm.controls;//פקדי טופס משתמש חובה לצורך Expression                            
                let programmerCode = c.ProgrammingExpression.Expression//"container['bdb7c2382ad743e3af225a1d5f807841'].value=='2'?4:7"  
                let watchExpression = c.ProgrammingExpression.WatchExpression;
                if (c.ControlType == 'headerSection') {
                    let headerSection: JsonObjects.HeaderSection = c as (JsonObjects.HeaderSection);
                    this.activatingProgrammerCodeHeader(container, currentSection, watchExpression, programmerCode, headerSection.Icon, userForm)
                }
                else {
                    this.activatingProgrammerCodeControl(container, c, idExtension)
                }
            });
    }
    activatingProgrammerCodeControl(container, c: JsonObjects.Control, idExtension) {

        let control: AbstractControl = container[c.ControlId + idExtension];
        let programmerCode = c.ProgrammingExpression.Expression;
        let watchExpression = c.ProgrammingExpression.WatchExpression;
        if (c instanceof JsonObjects.Button)//מעדכן רק פקדים שנמצאים באותו שלב ללא עדכון ערך עצמי כמו בהמשך
        {
            eval(programmerCode);
        }
        else {
            let sectionNum = watchExpression.substring(watchExpression.indexOf("[") + 2, watchExpression.indexOf("]") - 1);//המקטע שמאזין אליו
            let ControlId = watchExpression.substring(watchExpression.lastIndexOf("[") + 2, watchExpression.lastIndexOf("]") - 1);
            if (container[ControlId])//בדיקה אם ריק סימן שלא קיים ברדקס UserValueEntered
            {
                this.activatingProgrammerCode(container, control, ControlId, programmerCode)
            }
            else {
                let value = this.userValueService.selectUserValueEntered({ sectionId: sectionNum, fieldId: ControlId });
                control.setValue(value)
            }
        }
    }
    activatingProgrammerCodeHeader(container: { [key: string]: AbstractControl; }, currentSection: JsonObjects.FormSection, watchExpression: string, programmerCode: string, icon: string, userForm: FormGroup)//הפעלת קוד מתכנת
    {
        //container : חובה לצורך EVAL
        if (watchExpression) {
            watchExpression.split(';').forEach(watchExpression => {
                let sectionNum = watchExpression.substring(watchExpression.indexOf("[") + 2, watchExpression.indexOf("]") - 1)//המקטע שמאזין אליו
                let ControlId = watchExpression.substring(watchExpression.lastIndexOf("[") + 2, watchExpression.lastIndexOf("]") - 1)
                let currentlevel: JsonObjects.RefJsonFile = this.ngRedux.getState().Currentlevel;
                userForm.get(ControlId).valueChanges.subscribe(
                    currentText => {
                        let textToReplace = 'container["' + ControlId + '"].Value';
                        let tmpProgrammerCode = programmerCode;
                        tmpProgrammerCode = this.customString.replaceAll(tmpProgrammerCode, textToReplace, '"' + currentText + '"');
                        let headerSection = eval(tmpProgrammerCode);
                        let indexSection = currentlevel.Children.findIndex(section => section.Num == currentSection.SectionNum);
                        headerSection = icon + headerSection;
                        this.ngRedux.dispatch(this.actions.actions.changeCurrentSectionHeader(indexSection, headerSection));
                    });
            })
        }
    }
    activatingProgrammerCode(container: { [key: string]: AbstractControl; }, control: AbstractControl, watchControls: string, programmerCode: string)//הפעלת קוד מתכנת
    {
        if (container)//הטופס לא ריק
        {
            try {
                var result = eval(programmerCode);
                if (result)
                    control.setValue(result)
            }
            catch (e) {
                console.log(e);
            }
        }
        //הכנסת ההאזנה ל
        if (watchControls)
            try {

                watchControls.split(';').forEach(watchControlID => {//אם יש כמה פקדים להאזנה
                    container[watchControlID].valueChanges.pipe(distinctUntilChanged())//מניעת ההרצה מעגלית
                        .subscribe(val => {
                            if (control) {
                                try {
                                    var result = eval(programmerCode);
                                    if (result)
                                        control.setValue(result)
                                }
                                catch (e) {
                                    console.log(e);
                                }
                            }

                        });
                })
            }
            catch (e) {
                console.log(e);
            }
    }
}