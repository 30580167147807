import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: 'ngx-mat-file-input[acceptFile]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AcceptFileDirective
    }
  ]
})
export class AcceptFileDirective implements Validator {
  @Input('acceptFile')
  accept: string = '';
  fileSizeLimitMB: number = 3 * 1024 * 1024; // 3MB in bytes


  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    const acceptType = this.accept.split("\"").join("");
    if (acceptType) {
      let arrExtention: string[] = acceptType.split(",");	//"application/pdf","image/png","image/jpeg","image/gif"		
      if (control.value !== null && control.value !== "" && control.value._files) {
        let nOK: number = control.value._files.map(p => p.type).filter((extention: string) => { return arrExtention.find(p => extention == p) }).length
        let nOrigin: number = control.value._files.length;
        let nSize: number = control.value._files.reduce((acc, file) => acc + file.size, 0);
        if (nOK != nOrigin || nSize > this.fileSizeLimitMB)//גודל הקובץ קטן 3MB וסוג מתוך הרשימה
          return { 'acceptFile': this.accept };

        else
          return null;
      }
    }
  }

}
