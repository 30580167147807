

//namespace a{
 
    
    export class Settlement {
       
Symbol? :string;
Name? :string;
EngName? :string;
CouncilName? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










