

//namespace a{
 
    import {ConditionalLogicGroup} from './ConditionalLogicGroup' 
    export class Level {
       
LevelName? :string;
LevelHeader? :string;
LevelNum? :string;
ConditionalLogicGroup? :ConditionalLogicGroup;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










