

//namespace a{
 
    import {Control} from './Control' 
    export class Button extends Control {
       
Event? :string;
LevelNum? :string;
SectionNum? :string;
QuantityLimit? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










