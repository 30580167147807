<div id="menu" *ngIf=" (data$ | async) && menu_page.Children.length && currentProcess; else loading">
        <app-partial-header [currentProcess]=currentProcess [menu_page]=menu_page></app-partial-header>
        <div id="content_menu">
                <ng-container [ngSwitch]="isLogin">
                        <ng-template [ngSwitchCase]="false">
                                <div class="main-screen-content">
                                        <div class="main-screen-card">
                                                <div [innerHtml]="currentProcess.MainScreenContent|keepHtml"></div>
                                                <div class="form-button-start ember-view">
                                                        <button mat-raised-button mat-button
                                                                class="custom-button btn btn-primary-outline pull-right"
                                                                (click)="login()">{{currentProcess.LoginButtonTitle}}
                                                                <span></span>
                                                        </button>

                                                </div>
                                        </div>

                                </div>
                        </ng-template>
                        <ng-template [ngSwitchCase]="true">
                                <div id="wrapper">
                                        <div class="stepper" *ngIf="currentMainLevel">
                                                <mat-progress-spinner class="track-stepper" mode="determinate"
                                                        value="100" aria-label="Rating" diameter="132" strokeWidth="5">
                                                </mat-progress-spinner>
                                                <mat-progress-spinner class="spinner-stepper" mode="determinate"
                                                        [value]="valueProgressSpinner" aria-label="Rating"
                                                        diameter="132" strokeWidth="5">
                                                </mat-progress-spinner>
                                                <div class="text-inner-stepper">
                                                        <span> {{currentMainLevel.indexMainLevel+1}} מתוך
                                                                {{currentProcess.MainLevels.length}}</span>
                                                </div>
                                                <div class="header-stepper">
                                                        <div class="title-stepper">{{currentMainLevel.name}}</div>

                                                        <div class="subtitle-stepper">
                                                                {{menu_page.Children[nevigateIndex]?menu_page.Children[nevigateIndex].CompName:''}}
                                                        </div>
                                                </div>

                                        </div>
                                        <router-outlet></router-outlet>
                                </div>
                                <div id="aside" *ngIf="(device==eDevice.Desktop||device==eDevice.Laptop)&&isAsideApear()"></div>
                        </ng-template>
                </ng-container>
        </div>
</div>
<ng-template #loading>
        <div class="loading cdk-overlay-container">
                <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
                <div class="cdk-global-overlay-wrapper">
                  <div class="loading-dialog">
                    <img src="./assets/img-sm/loading_screen-sm.svg" srcset="./assets/img-sm/loading_screen-sm.svg 800w, ./assets/img-lg/loading_screen-lg.svg 1440w" alt="הדף נטען" />
                    <label class="loading-dialog-label">הדף נטען תודה על הסבלנות</label>
                  </div>
                </div>
              </div>
</ng-template>