import './polyfills';
import 'zone.js'
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';
import * as $ from 'jquery';//לא להוריד !! jquery למערכת

if (environment.production) {
  window.console.log=()=>{};
  enableProdMode(); 
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
