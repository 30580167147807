

//namespace a{
 
    import {ProgrammingExpression} from './ProgrammingExpression' 
import {ConditionalLogicGroup} from './ConditionalLogicGroup' 
    export class Control {
       
ControlId? :string;
Hidden? :boolean;
Pointed? :boolean;
Label? :string;
ControlName? :string;
ControlType? :string;
Tooltip? :string;
Class? :string;
ClassMobile? :string;
Note? :string;
Disabled? :boolean;
ProgrammingExpression? :ProgrammingExpression;
ConditionalLogicGroup? :ConditionalLogicGroup;
IgnoreSession? :boolean;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










