

//namespace a{
 
    import {Input} from './Input' 
    export class UploadFile extends Input {
       
Accept? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










