

//namespace a{
 
    import {Control} from './Control' 
import {DefaultValue} from './DefaultValue' 
import {RequiredValidation} from './RequiredValidation' 
    export class Input extends Control {
       
Title? :string;
ReadOnly? :boolean;
DefaultValue? :DefaultValue;
EdingChar? :string;
Validators? :RequiredValidation;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










