

//namespace a{
 
    
    export class SectionControl {
       
Section? :string;
Field? :string;
FieldLabel? :string;
SectionName? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










