import { Component, AfterViewInit, Input, OnInit } from '@angular/core';
import * as JsonObjects from '../../classes'
import * as Chart from 'chart.js'
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UserValueEnterrd } from '../../services/user-value-entered.service';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit, AfterViewInit {//הצגת תרשים

    @Input() input: JsonObjects.Chart;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    doughnutChart: Chart;//אוביקט תרשים
    dataChart: number[] = []; //ערכי הפקדים שמרכיבים את התרשים
    idExtension: string = '';//מזהה עותק

    private subscription: Subscription = new Subscription();
    constructor(private userValueService: UserValueEnterrd) {
    }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק        
    }

    ngAfterViewInit() {
        setTimeout(() => { 
            this.valueChart();
            this.styleChart();
        });     
    }
    styleChart(){
        var canvas: any = document.querySelector(`canvas[id="${this.input.ControlId + this.idExtension}"]`);//קבלת הקאנוס ליצירת התרשים
        var ctx: any = canvas.getContext('2d');
        var colors: string[] = this.input.ChartControls ? this.input.ChartControls.map(c => c.Color) : [];//שליפת הצבעים מהפקדים שבתרשים
        var cutoutPercentage: number = this.input.TypeChart == "doughnut" ? 75 : 0;
        var draw = Chart.controllers.doughnut.prototype.draw;
        Chart.controllers.doughnut = Chart.controllers.doughnut.extend({
        draw: function() {
            draw.apply(this, arguments);
            let ctx = this.chart.chart.ctx;
            let _fill = ctx.fill;
            ctx.fill = function() {
                ctx.save();
                ctx.shadowColor = '#143158';
                // ctx.shadowBlur = 60;
                //  ctx.shadowOffsetX = 2;
                //  ctx.shadowOffsetY = 2;
                _fill.apply(this, arguments)
                ctx.restore();
            }
        }
        });
      
        this.doughnutChart = new Chart(ctx, {//יצירת התרשים
            type: this.input.TypeChart,//סוג תרשים פאי/ סופגניה
            data: {
                datasets: [{
                    label: '',
                    data: this.dataChart,
                    backgroundColor: colors,
                    hoverOffset: 4,
                    borderWidth: 0//הורדת המסגרת בפאי
                }]
            },

            options: {
                legend: {//שלא יציג תוויות
                    display: false
                },
                tooltips: {//שלא יציג תוויות
                    callbacks: {
                        label: function (tooltipItem) {
                            return tooltipItem.yLabel;
                        }
                    }
                },
                cutoutPercentage: cutoutPercentage, //הגדלה לפי אחוזים את הרווח הפנימי של המעגל
                maintainAspectRatio: false //השבתת יחס הגובה ורוחב
            }
        });
    }
    valueChart(){
        if (this.input.ChartControls)
        this.input.ChartControls.map(c => c.Control).forEach((control, index) => { //מעבר על פקדי התרשים
            if (control && control.Field) {
                if (this.userForm.controls[control.Field])//אם הפקד קיים באותו טופס
                {
                    this.dataChart.push(
                        !isNaN(+this.userForm.controls[control.Field].value) ?//בדיקה שהערך הוא מספר
                            this.userForm.controls[control.Field].value as number : 0)//הכנסת ערך פקד תרשים למערך

                    this.subscription.add(this.userForm.controls[control.Field].valueChanges.subscribe((newValue) => {//האזנה אם ערך הפקד השתנה
                        if(this.doughnutChart)
                        //בדיקה שהערך הוא מספר
                        this.doughnutChart.data.datasets[0].data[index] = !isNaN(+newValue) ? newValue : 0;//עדכון הנתונים בתרשים
                        this.doughnutChart.update();//עדכון התרשים
                    }))

                }
                else {//אם לא, אז שלוף מהרידקס
                    var value = this.userValueService.selectUserValueEntered({ sectionId: control.Section, fieldId: control.Field });
                    //בדיקה שהערך הוא מספר 
                    this.dataChart.push(!isNaN(+value) ? +value : 0)//הכנסת ערך פקד תרשים למערך                          
                }
            }
        }); 
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}