

//namespace a{
 
    import {FormSection} from './FormSection' 
    export class ResearchSection extends FormSection {
       

    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










