<div id="header">
  <div class="form-title-header" role="heading" aria-level="1">
        <a aria-label="לחץ למעבר לדף הבית" href="https://infinity.co.il/" target="_blank">
        <img  loading="lazy" id="img-logo" alt="אינפיניטי לוגו"
        srcset="./assets/img-sm/logo-sm.svg 600w,./assets//img-lg/logo.svg 1440w" 
        src="./assets/img-lg/logo.svg"   />
        </a>
          <span class="form-title-header-span ">{{currentProcess.ProcessTitle}}</span>
  </div>
  <div class="form-tallk-header">
          <button  aria-label="אינפיניטי. לחץ כאן דברו איתנו " *ngIf="currentProcess" mat-button (click)="openContact()">
                  <img src="./assets/img-lg/chat.svg" alt>
                  <span class="operatorTitle" *ngIf="device==eDevice.Desktop"> {{currentProcess.Contact.OperatorTitle}} </span>
          </button>
  </div>

         <!-- <app-otp-popup></app-otp-popup>  -->
</div>