<div [class]="input.Class?input.Class:''+' checkbox'">
  <ng-container [ngSwitch]="isClassExists('checkbox-switch')">
    <ng-template [ngSwitchCase]="true">
      <div floatPlaceholder="always" appearance="none" 
        [formGroup]="userForm.get(input.ControlId+idExtension)" [id]="input.ControlId+idExtension">
        <div *ngFor="let option of input.InputOptions">
          <input type="checkbox" class="switch" [id]="input.ControlId+option.OptionName+idExtension"
            name="{{option.OptionName}}" [value]="option.OptionName" (change)="ChangeColorParent($event)"
            [formControl]="userForm.get(input.ControlId+idExtension).get(option.OptionName+idExtension)">
          <span [innerHtml]="option.Label|keepHtml"></span>
        </div>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="false">
      <ng-container [ngSwitch]="isClassExists('startRoboticConsultant')">

        <ng-template [ngSwitchCase]="true">


          <div floatPlaceholder="always" appearance="none"
            [formGroup]="userForm.get(input.ControlId+idExtension)" [id]="input.ControlId+idExtension">
            <div *ngFor="let option of input.InputOptions">
              <div class="c-checkbox_rectangle ">
                <input class="" [id]="input.ControlId+option.OptionName+idExtension" type="checkbox"
                aria-hidden="true"
                  name="{{option.OptionName}}" [value]="option.OptionName"
                  [formControl]="userForm.get(input.ControlId+idExtension).get(option.OptionName+idExtension)" />
                <label class="c-checkbox_rectangle__label" [for]="input.ControlId+option.OptionName+idExtension">
                </label>
                <span [innerHtml]="option.Label|keepHtml"></span>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template [ngSwitchCase]="false">
            <section floatPlaceholder="always" appearance="none"
              [formGroup]="userForm.get(input.ControlId+idExtension)" [id]="input.ControlId+idExtension">
              <div *ngFor="let option of input.InputOptions">
                <div class="c-checkbox c-checkbox--normal">
                  <input type="checkbox"  class="screenreader-checkbox" [id]="input.ControlId+option.OptionName+idExtension"  aria-hidden="true"               
                  [formControlName]="option.OptionName+idExtension">
                  <label class="c-checkbox__label" [for]="input.ControlId+option.OptionName+idExtension">
                    <span [innerHtml]="option.Label|keepHtml"></span>
                  </label>
                </div>
              </div>              
              <mat-error class=" error">
                <div>{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
              </mat-error>
            </section>
        </ng-template>

      </ng-container>
    </ng-template>
  </ng-container>
</div>