

//namespace a{
 
    import {FileNameFormat} from './FileNameFormat' 
    export class ProcessFile {
       
Name? :string;
FileNameFormat? :FileNameFormat;
PdfFileId? :string;
JsonFieldsId? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










