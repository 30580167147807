

//namespace a{
 
    import {Input} from './Input' 
import {Option} from './Option' 
    export class Options extends Input {
       
InputOptions? :Option[];
IsDistinctOptionName? :boolean;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










