

//namespace a{
 
    import {Input} from './Input' 
    export class Signature extends Input {
       

    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










