import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';
import * as JsonObjects from 'src/app/shared/classes';

@Injectable({ providedIn: 'root' })
export class LevelSectionService {
    private savingSectionSubject = new Subject();
    SectionArray: {//מערך מקטעים לשלב לא מכילים מקטעים כפולים
        [key: string]: JsonObjects.FormSection;
      } = {};

    saveDataSection() {
        this.savingSectionSubject.next();
    }

    getsavingSectionSubject(): Observable<any> {
        return this.savingSectionSubject.asObservable();
    }

    clearSavingSectionSubject() {
        this.savingSectionSubject.unsubscribe();
        this.savingSectionSubject = new Subject();
    }

    setSectionArray(updateSectionArray:{[key:string]:JsonObjects.FormSection}){
        this.SectionArray= updateSectionArray;
    }

    getSectionArray(){
        return this.SectionArray;
    }
}