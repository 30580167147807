

//namespace a{
 

    export class FileNameFormat {
       
Controls? :FileNameFormatControl[];
    }
 
    import {FieldControl} from './FieldControl' 
    export class FileNameFormatControl {
       
Id? :string;
Type? :string;
Value? :string;
Control? :FieldControl;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










