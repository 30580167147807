import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JsonObjects from '../../classes'
import { CustomValidation } from '../../custom-validation';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']//,
    //encapsulation: ViewEncapsulation.None
})
export class InputComponent implements AfterViewInit, OnInit {

    @Input() input: JsonObjects.Input;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק
    classList: string[] = [];//קלאסים של הפקד הנוכחי
    inputValue: string;//ערך אינפוט לשדה השלמה
    CheckboxEnableEditorText: boolean = false;//בעת בחירה מאפשר עריכת טקסט וחוסם בעת מצב לא בחור

    ngOnInit() {
        this.classList = this.input.Class ? this.input.Class.split(' ') : [];//שליפת הקלאסים של הפקד
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
        this.input.EdingChar = this.input.EdingChar ? this.input.EdingChar : '';
    }
    ngAfterViewInit() {
        for (let index = 0; index < $(".text-editor").length; index++) {
            let textEditor = $(".text-editor")[index];
            let idTextEditor = $(".text-editor")[index].id;
            let sibling = $("#" + idTextEditor).parents('app-input').prev('app-checkbox').find('.c-checkbox_toggle')[0];
            if (sibling != undefined)//לא הועבר כבר
            {
                let siblingSpan = sibling.nextElementSibling
                $($("#" + idTextEditor).parents('app-input')).insertAfter(siblingSpan)
                if ($("#" + idTextEditor).parents('app-input').length > 1)
                    $("#" + idTextEditor).parents('app-input')[1].remove();
            }
        }
        
        if (this.userForm.controls[this.input.ControlId + this.idExtension].value && (this.isClassExists('text-completion') || this.isClassExists('text-completion-before') || this.isClassExists('text-editor')))
            $($("#" + this.input.ControlId + this.idExtension).parent()).attr('data-value', this.userForm.controls[this.input.ControlId + this.idExtension].value + ' ');
    }

    isClassExists(isClass: string = '') {
        return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
    }

    onKeyUp(event) {
        var numNoComman: string = event.target.value;//הורדת הפסיקים 
        if (numNoComman.length > 1)
            numNoComman = numNoComman.replace(/^0+/gi, "");//הורדת אפס ראשון
        event.target.value = numNoComman;
    }
    onCheckboxChangeFocusEditorText(event, id) {
        if (event.target.checked)//מוכן לעריכה
        {
            setTimeout(() => $("#" + id).focus());
        }

    }

}