<mat-form-field *ngIf="userForm" class="full-width" appearance="outline" [floatLabel]="input.Label">
    <mat-label>{{input.Label}}<span class="label-required" *ngIf='input.Validators && input.Validators.Required'>
            *</span> </mat-label>
    <input autocomplete="off" #autocomplete matInput [matDatepicker]="datePicker" [id]="input.ControlId+idExtension"
        [placeholder]="input.Placeholder" (dateChange)="onDateChange($event.value)"
        [formControl]="userForm.get(input.ControlId+idExtension)" />
    <mat-error> {{validation(userForm.controls[input.ControlId+idExtension])}}</mat-error>
    <mat-datepicker-toggle matSuffix [for]="datePicker">
        <mat-icon matDatepickerToggleIcon class="date-picker-icon">
            <img class="img-date-picker-icon" src="./assets/img-lg/calendar.svg" alt="לחץ לבחיקת תאריך מתוך לוח שנה">
        </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
</mat-form-field>