

//namespace a{
 
    import {DistributionRoute} from './DistributionRoute' 
    export class Classification {
       
ClassificationId? :string;
ClassificationName? :string;
BottomRange? :string;
TopRange? :string;
DistributionRoutes? :DistributionRoute[];
TotalShares? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










