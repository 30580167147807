import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { ApiService } from '../shared/services/api.service'
import { Observable, Subscription, Subject } from 'rxjs';
import { IAppState } from '../../store/reducer';
import { CounterActions } from '../../store/actions'
import { FormComponent } from '../shared/structureObject'
import { ConditionalLogic } from '../shared/services/conditionalLogic.service';
import * as JsonObjects from '../shared/classes';
import { HttpErrorResponse } from '@angular/common/http';
import { Serializable } from '../shared/classes/Serializable';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/controls/dialog/dialog.component';
import { AbstractControl } from '../shared/services/abstractControl.service'
import { ResponsiveService } from '../shared/services/resonsive.service';
import { PartialHeaderComponent } from '../shared/components/partial-header/partial-header.component';
import { OtpPopupComponent } from '../shared/controls/otp-popup/otp-popup.component';
import { promise } from 'protractor';
@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class menuComponent implements OnInit, OnDestroy {
  menu_page: JsonObjects.RefJsonFile = { Num: "", CompName: "", Template: "", Hierarchy: "", Children: [] };//איחסון כל השלבים מהשרת
  currentProcess: JsonObjects.Process;//הגדרות תהליך נוכחי
  currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי בו השתמש נמצא
  data$: Observable<JsonObjects.RefJsonFile>;//הנתונים מהשרת
  //indexLevel: number;//אינקס שלב
  currentMainLevel: { num: string, name: string, indexMainLevel: number, indexLevel: number };//פרטי השלב הראשי
  valueProgressSpinner: number = 0;//ערך הספינר
  userForm: FormGroup;//טופס משתמש
  controlsIdTriggerNotification: {
    [key: string]: string[]
  } = {}; //שמירת הפקדים שמזמנים את ההודעה
  isLogin: boolean = false;//האם נכנס כבר לאתר
  nevigateIndex = 0;// הבא לא כולל אינדקס שלב urlה 
  nevigateIndex_second = 0;// הבא לא כולל אינדקס תת תהליך שלב urlה 
  nevigatePrefix = ['l', 'registeration'].join("/");//סטרינג של ניווט קידומת שלב
  nevigate_second = "secondaryProcess";//סטרינט של ניווט קידומת תת תהליך שלב
  private subscription: Subscription = new Subscription();//מערך האזנות, כדי למחוק כשהקומפוננטה נהרסת
  get eDevice(){
    return FormComponent.eDevice;
  }
  get device(){
    return this.responsiveService.device;
  }
  constructor(private responsiveService:ResponsiveService,private ngRedux: NgRedux<IAppState>, public apiService: ApiService, public dialog: MatDialog,
    private router: Router, public route: ActivatedRoute, private actions: CounterActions,
    private conditionalLogic: ConditionalLogic, private abstractControl: AbstractControl) {
  }

  ngOnInit() {
    this.subscription.add(this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => { this.userForm = userForm }));//שליפת טופס משתמש  
    this.nevigateIndex = 0;//איפוס אינקס שלב

    //להטעין מהסרבר את שמות השלבים של השאלון
    this.data$ = this.apiService.GetProcessMenu();
    this.data$.toPromise().then(response => {
      var main: JsonObjects.RefJsonFile = response != null ? Serializable.fromJSON('RefJsonFile', response) : { Num: "", CompName: "", Children: [], Type: "", Level: "" }//<JsonObjects.RefJsonFile>response
      // שם שיופיע בכותרת    
      this.menu_page = { Num: main.Num, CompName: main.CompName, Template: main.Template, Hierarchy: main.Hierarchy, Children: [] };
      main.Children.forEach(Level => {//השלבים של תהליך הרשמה בלבד
        //מעבר על כל שלב
        this.menu_page.Children.push(Level)
      });

      //שליפת ההגדרות של התהליך הנוכחי
      this.apiService.getProcess(this.menu_page.Num, this.menu_page.Template).toPromise().then(response => {
        var spliteType = response.$type.split('.');
        var typeObject = spliteType[spliteType.length - 1].split(',')[0]
        this.currentProcess = Serializable.fromJSON(typeObject, response);//הכנסת האוביקט מהשרת
        this.changeProgressSpinner(this.nevigateIndex);//שינוי השלב הראשי בספינר
        var completedFormGroup: Promise<any> = new Promise(resolve => {
          this.abstractControl.FillControlsFormGroup(this.menu_page, this.currentProcess.Contact.Controls, resolve);//יצירת טופס
        });

        completedFormGroup.then(() => {
          let formControls = this.abstractControl.getFormControls();//קבלת פקדי טופס
          this.ngRedux.dispatch(this.actions.actions.updateUserForm(formControls))//הכנס את הפקדים לטופס משתמש ברידקס
        })
        //הגדרת הפקדים של ההודעה כדיסייבל 
        this.conditionalLogic.disableControlFormSection(this.currentProcess.Contact.Controls, '');
      })

      var isLastLevel = this.menu_page.Children.length == 1;//חישוב האם הוא האחרון
      this.ngRedux.dispatch(this.actions.actions.changeStatusUser(isLastLevel ? FormComponent.eStatusUser.InLastLevel : FormComponent.eStatusUser.InProcess));//שמירת הגדרות טופס ברידקס
      this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(this.menu_page.Children[0]));//שמירת שלב נוכחי ברידקס
      let nextNevigate = ['l', 'registeration', '0'].join("/");
      this.router.navigateByUrl(nextNevigate);//עבור לשלב הראשון
    })
      .catch((error) => {
        this.apiService.errorHandler.handleError(error);
        this.apiService.errorMessage = this.apiService.errorHandler.errorMessage;
      });

      this.subscription.add(this.ngRedux.select('Currentlevel').subscribe((currentLevel: JsonObjects.RefJsonFile) => {//כל פעם שמשתנה השלב הנוכחי לעדכן את התפריט
      this.currentLevel = currentLevel;//שמירת השלב הנוכחי
      if (this.nevigateIndex != null && currentLevel.Num)
        this.menu_page.Children[this.nevigateIndex] = currentLevel;
    }));

    this.subscription.add(this.ngRedux.select(s => s.FormSettings.StatusUser).subscribe((statusUser: FormComponent.eStatusUser) => {//האזנה לשינויים במצב משתמש שבהגדרות שלב
      if (statusUser == FormComponent.eStatusUser.EndProcess)//אם המשתמש סיים את התליך
        this.CheckInputValidationOnServer();//בדיקת הערכים בשרת
    }));
    this.subscription.add(this.ngRedux.select(s => s.FormSettings.IsCompletedSections).subscribe((isCompletedSections: boolean) => {//האזנה לשינויים בהאם הושלמו יצירת המקטעים שבהגדרות שלב
      if (isCompletedSections == true) {
        this.abstractControl.FillControlsFormGroup(this.menu_page, this.currentProcess.Contact.Controls, null);//יצירת טופס
        //הגדרת הפקדים של ההודעה כדיסייבל 
        this.conditionalLogic.disableControlFormSection(this.currentProcess.Contact.Controls, '');
      }
      else
        this.conditionalLogic.clearRecheckConditionalLogic();//אתחול האזנות
    }));
    this.subscription.add(this.ngRedux.select(s => s.Navigate.LevelNum).subscribe(() => {//מוזנק תמיד כל פעם שהמשתמש לחץ על הכפתורים של הניווט
      let isPreventContinueNextLevel = false;
      //בדיקת התצוגה מותנית
      let navigate = this.ngRedux.getState().Navigate;
      if (navigate.StatusNavigate) {

        if (navigate.StatusNavigate == FormComponent.eStatusNavigate.nextInProcess ||
          navigate.StatusNavigate == FormComponent.eStatusNavigate.nextSecondaryProcess)//לחיצה שלב הבא)
        {
          const notification:JsonObjects.Notification = this.checkProcessControlsCondition()
          if (notification)//התנאי התקיים אז תוסיף פקד לטופס
          {
            isPreventContinueNextLevel = true;
            setTimeout(() => {
              this.dialog.open(DialogComponent, {//הצג הודעה
                width: '700px',
                data:
                {
                  Type: 'processStoppedNotify',
                  NotificationControl: notification,
                  Message: notification.Message
                }
              });
            });

            if (this.conditionalLogic.controlValueChangeList[notification.ControlId])
              //not used -becouse it was for stop process on click control 
              this.conditionalLogic.controlValueChangeList[notification.ControlId].forEach(controlIdCheck => {//מעבר על הפקדים שקשורים בתצוגה מותנית 
                this.checkControlNotificationCondition(notification, false, controlIdCheck, false);//סימון הפקדים כ-לא חוקיים
              })
          }
        }
        console.log('navigate.LevelNum ', navigate.LevelNum);
        if(this.nevigateIndex==0)
        //if(navigate.LevelNum=="d08d1fe7-e60a-4fe7-a9dc-2331f244ab15")
        {
          isPreventContinueNextLevel = true;
          this.openOtp().then((otp) => {
            console.log(otp);
            isPreventContinueNextLevel = !Boolean(otp);
            console.log('path');
            if (isPreventContinueNextLevel == false)
              this.navigationLevel(navigate);
          }).catch((error) => {
            console.log(error);
            isPreventContinueNextLevel = true; // set isPreventContinueNextLevel to true if an error occurs
          });
        }
        if (isPreventContinueNextLevel==false)
          this.navigationLevel(navigate);
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();//מחיקת האזנות
    this.subscription = new Subscription();
  }

  login() {//כניסה לאתר
    this.isLogin = true;
  }
  isAsideApear() {
    return this.currentLevel.Children.findIndex(s => s.Template == 'information') != -1
  }
  changeProgressSpinner(currentIndexLevel: number) {//שינוי שלב הספינר  
    //בדיקה אם השלב הנוכחי הוא שלב ראשי
    if (this.currentProcess.MainLevels) {
      var mainInedxLevel = this.currentProcess.MainLevels.findIndex(level => level.LevelNum == this.menu_page.Children[currentIndexLevel].Num);
      var indexMainLevel;//אידקס שלב ראשי
      if (mainInedxLevel >= 0)//אם השלב הנוכחי
      {
        //שמירת מזהה שלב ראשי
        indexMainLevel = mainInedxLevel;
        this.currentMainLevel = { num: this.currentProcess.MainLevels[mainInedxLevel].LevelNum, name: this.currentProcess.MainLevels[mainInedxLevel].MainLevelTitle, indexMainLevel: mainInedxLevel, indexLevel: currentIndexLevel };//פרטי השלב הראשי
      }
      else {//אם אינו שלב ראשי
        //מציאת השלב הראשי שמעליו
        var mainOldLevels: JsonObjects.RefJsonFile[] = this.menu_page.Children.filter((l, index) => index < currentIndexLevel && this.currentProcess.MainLevels.findIndex(ml => ml.LevelNum == l.Num) >= 0);//שליפת השלבים הראשיים עד הנוכחי
        if (mainOldLevels.length == 0)//אם אין שלב ראשי לפניו- לא אמור לקרות
        {
          indexMainLevel = -1;
          this.currentMainLevel = { num: '', name: '', indexMainLevel: indexMainLevel, indexLevel: -2 };//פרטי השלב הראשי
        }
        else {
          var indexLevel = this.menu_page.Children.findIndex(m => m.Num == mainOldLevels[mainOldLevels.length - 1].Num);
          indexMainLevel = this.currentProcess.MainLevels.findIndex(ml => ml.LevelNum == mainOldLevels[mainOldLevels.length - 1].Num);
          //שמירת מזהה שלב ראשי
          this.currentMainLevel = { num: this.currentProcess.MainLevels[indexMainLevel].LevelNum, name: this.currentProcess.MainLevels[indexMainLevel].MainLevelTitle, indexMainLevel: indexMainLevel, indexLevel: indexLevel };//פרטי השלב הראשי
        }

      }
      this.valueProgressSpinner = ((indexMainLevel + 1) / this.currentProcess.MainLevels.length) * 100;//הצגת שלבי הספינר  
    }
  }

  checkProcessControlsCondition(): JsonObjects.Notification {//בדיקת תצוגה מותנית לפקדי תהליך
    let isDialogDisplay: JsonObjects.Notification = null;
    if (this.currentProcess)
      this.currentProcess.Controls.forEach(control => {
        //לפקד מוסתר או מושבת אין לבדוק תצוגה מותנית ויש להשאיר אותו מוסתר
        if (control instanceof JsonObjects.Notification)//הפקדים של תהליך הם הודעות
        {
          if (control.ConditionalLogicGroup.Activated == true) {//אם יש לפקד תצוגה מותנית
            //הכנסה לשירות של תצוגה מותנית את הפונקציה שיש להפעיל כשנשתנה הפקד שעליו התנאי היה
            //בדיקה אם צריך להקפיץ הודעה
            var isControlsConditionTrue = this.conditionalLogic.checkCondition(true,control.ControlId, control.ConditionalLogicGroup, true);
            if (isControlsConditionTrue == true) {
              isDialogDisplay = control;
            }
          }
        }
      });
    return isDialogDisplay;
  }

  checkControlNotificationCondition(control: JsonObjects.Control, isOnInit: boolean, controlIdChange: string, isValueChange: boolean = true) {//בדיקת תנאי פקד בודד להודעה
    if (control.ConditionalLogicGroup.Activated == true) {
      //יש לבדוק גם פקדים כפוליםםםםםםםםםםםםםםםםםםםם
      var controlValue = this.controlsIdTriggerNotification[control.ControlId]//קבלת ערך הפקד המזהים של הפקדים שמזמנים את ההודעה ללקוח
      var controlsIdInvalid: string[] = controlValue != null ? controlValue as string[] : [];
      var controlIdChangeSplitted: string[] = controlIdChange.split('_COPY_');
      var idExtensionCondition: string = controlIdChangeSplitted[1] ? '_COPY_' + controlIdChangeSplitted[1] : null;
      var currentSectionNum: string = idExtensionCondition ? 'NotificationCondition' : null;
      var isNotificationConditionTrue: boolean = this.conditionalLogic.checkNotificationCondition(control.ConditionalLogicGroup, control, isOnInit, controlIdChangeSplitted[0], idExtensionCondition, currentSectionNum);//בדיקת תצוגה מותנית הודעה על הפקד שהשתנה
      if (isNotificationConditionTrue)//התנאי התקיים אז תציג את ההודעה ללקוח
      {
        if (controlsIdInvalid.findIndex(cId => cId == controlIdChange) == -1) {//בתנאי שהפקד הנוכחי לא קיים ברשימת הפקדים הלא חוקיים
          if (isValueChange) //אם הבדיקה התבצע על הפקד כי השתנה לו הערך אז יש להקפיץ הודעה
            setTimeout(() =>
              this.dialog.open(DialogComponent, {//הצג הודעה
                width: '700px',
                //data: control
                data:
                {
                  Type: 'not used now',
                  NotificationControl: control,
                  Message: (control as JsonObjects.Notification).Message
                }
              }));

          controlsIdInvalid.push(controlIdChange)//שמירת הפקד שמזמין את ההודעה- הלא חוקי        
          //כל הקוד המיורק זה למקרה ורוצים לסמן שדה שגוי
          // this.userForm.controls[controlIdChange].markAsDirty();
          // this.userForm.controls[controlIdChange].setErrors({ 'incorrect': true });//סימון פקד כ-לא חוקי
          // this.userForm.controls[controlIdChange].setValidators(()=>{return { 'incorrect': true }});//התחלתי לבדוק הכנסת ולידציה ולא רק שגיאה
          //כדי לשמור על שגיאת השדה אחרי המחיקה שלו וחזרתו לטופס
          // this.userForm.controls[controlIdChange].updateValueAndValidity();
        }
        //this.userForm.controls[controlIdChange].setValue(null);//איפוס ערך שדה
      }
      else {
        controlsIdInvalid = controlsIdInvalid.filter(idInvalid => {//מחיקת פקד מרשימת הפקדים הלא חוקיים
          return idInvalid != controlIdChange
        });
        // this.userForm.controls[controlIdChange].setErrors({ 'incorrect': null });//סימון פקד כ- חוקי
        // this.userForm.controls[controlIdChange].setValidators(()=>{return null});
        // this.userForm.controls[controlIdChange].updateValueAndValidity();
      }
      this.controlsIdTriggerNotification[control.ControlId] = controlsIdInvalid;//שמירת רשימת הפקדים שמזמנים את ההודעה      
      // if (!isNotificationConditionTrue && controlsIdInvalid.length > 0)//אם ההודעה לא אמורה להופיע ויש עוד פקדים לא חוקיים
      //   //בדיקת הפקד שאחריו
      //   this.checkControlNotificationCondition(control, false, controlsIdInvalid[0], false)//פונקצייית בדיקה לפקד הודעה מבלי שהשתנה הערך שלו    
    }
  }

  //בדיקת נתוני רידקס בשרת
  public CheckInputValidationOnServer() {
    //שליפה מהרדקס את כל הנתונים שהמשתמש הכניס במטרה לעדכן בשרת
    let userInputs: JsonObjects.UserInputs = this.ngRedux.getState().UserDetailsEntered
    this.apiService.isValidInput(userInputs as JsonObjects.UserInputs).toPromise()
      .then((result) => {
        this.router.navigateByUrl('ViewFiles/display');
      }).catch((result: HttpErrorResponse) => {

        let errorServer: FormComponent.ErrorServer = result.error;
        let IndexForNavigateByUrl: number = this.getIndexForNavigateByUrl(errorServer.NumSection);//מקבל מזהה מקטע ומחזיר מספר שלב
        //כאן צריך להחזיר פוקוס לעמוד המתאים צריך לפנות לפנוקציה שממלא את הנתונים
        //הפונקציה שמזינה את הנתונים עובדת באמצעות שינוי בURL 
        //יש אפשרות ללכת לתפריט ולמצוא את הדף ולהציגו
        if (IndexForNavigateByUrl != -1) {
          var isLastLevel = this.menu_page.Children.length == +IndexForNavigateByUrl + 1;//חישוב האם הוא האחרון              
          this.ngRedux.dispatch(this.actions.actions.changeServerErrorMessage(errorServer));//שמירת הגדרות טופס ברידק//במידה וחזר שגיאה מהשרת
          this.ngRedux.dispatch(this.actions.actions.changeStatusUser(isLastLevel ? FormComponent.eStatusUser.InLastLevel : FormComponent.eStatusUser.InProcess));//שמירת הגדרות טופס ברידקס//האם זה השלב האחרון בתהליך רישום             
          if (!isLastLevel) {
            let nextNevigate = ['l', 'registeration', '0'].join("/");
            this.router.navigateByUrl(nextNevigate);
          }
        }
      })
  }

  public getIndexForNavigateByUrl(idSection): number {//get Location Level
    return this.menu_page.Children.findIndex((menu_pageLevel) => {
      if (menu_pageLevel.Children != undefined)
        return menu_pageLevel.Children.filter((section) => section.Num == idSection ? section : null)[0] != null;
      return false;
    });
  }

  navigationLevel(navigate: FormComponent.Navigate) {
    let currentNavigate = this.getCurrentNavigate(navigate);//קבלת הניווט החדש

    this.apiService.getLevel(this.menu_page.Children[this.nevigateIndex].Num, this.menu_page.Children[this.nevigateIndex].Template).toPromise()
      .then((json: Object) =>//שליפת השלב הבא
      {
        var level: JsonObjects.Level = Serializable.fromJSON('Level', json);
        //שמירת הגדות שלב ראשי ברידקס
        this.ngRedux.dispatch(this.actions.actions.changeCurrentLevelSettings(level));
        if (level.LevelNum && !this.conditionalLogic.checkCondition(false,level.LevelNum, level.ConditionalLogicGroup)) //במידה והתנאי על השלב לא התקיים
        {
          //במידה והמקטע כבר הושךם בעבר יש לסמנו כטיוטה        
          var disabledSectionsId: string[] = this.menu_page.Children[this.nevigateIndex].Children.map(s => s.Num);
          if (disabledSectionsId.length > 0)
            this.ngRedux.dispatch(this.actions.actions.updateDraftComponents(disabledSectionsId));//עדכון המקטעים כמושבתים בשלב זה
          this.navigationLevel(navigate);

        }
        else {
          //אם התצוגה מותנית החזירה חיובי    או שאין כלל תצוגה מותנית                     
          if (navigate.StatusNavigate == FormComponent.eStatusNavigate.pointerLevel && this.menu_page.Children[this.nevigateIndex].Template == "refLevel") {
            this.nevigateIndex_second = 0;
            currentNavigate = [currentNavigate, this.nevigate_second, this.nevigateIndex_second].join("/");
            this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.StartProcess));//שמירת סטאטוס תהליך משני טופס ברידקס           
            this.menu_page.Children[this.nevigateIndex].Children = [];//(שלב שמצביע לתהליך משני אין מקטעים משלו (איפוס מקטעים למקרה שנשאר מהפעם הקודמת
          }

          var isLastLevel = this.menu_page.Children.length == +this.nevigateIndex + 1;//חישוב האם הוא האחרון              
          this.ngRedux.dispatch(this.actions.actions.changeStatusUser(isLastLevel ? FormComponent.eStatusUser.InLastLevel : FormComponent.eStatusUser.InProcess));//שמירת הגדרות טופס ברידקס               

          this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(this.menu_page.Children[this.nevigateIndex]));//שמירת שלב נוכחי ברידקס               
          this.router.navigateByUrl(currentNavigate).then(() => {//לאחר הניווט 
            if (this.currentProcess) {
              this.changeProgressSpinner(this.nevigateIndex);//שינוי השלב הראשי בספינר        
            }
          });
        }
      })
  }

  getCurrentNavigate(navigate: FormComponent.Navigate) {//קבלת הניווט החדש
    //הרכבת currentNavigate לפי eStatusNavigate
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.pointerLevel)//כאשר נבחר כפתור מסוג מצביע לשלב
    {
      let nevigateIndexCurrent: number = this.menu_page.Children.findIndex(level => level.Num == navigate.LevelNum);
    
      if (this.menu_page.Children[this.nevigateIndex].Template == "refLevel" && nevigateIndexCurrent != this.nevigateIndex)//אם כעת הוא מנווט מחוץ לתהליך המקונן
      {
        this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.CancelProcess));//הופסק באמצע או נלחץ על ביטול
        //יש למחוק מהרדקס את התוצאות של היועץ הרובוטי-לבדוק
      }

      this.nevigateIndex = nevigateIndexCurrent;//עדכון מיקום חדש
      return [this.nevigatePrefix, this.nevigateIndex].join("/");
    }
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.prevInProcess)//לחיצה שלב אחורה
    {
      if (this.menu_page.Children[this.nevigateIndex - 1].Template != "refLevel")//ניווט רגיל אחד אחורה
        this.nevigateIndex = this.nevigateIndex - 1;
      else//שתי שלבים אחורה במידה והשלב הקודם הוא תהליך משני (מנווט רק ע"י כפתור מסוג מצביע)
        this.nevigateIndex = this.nevigateIndex - 2;
      return [this.nevigatePrefix, this.nevigateIndex].join("/");
    }
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.nextInProcess)//לחיצה שלב הבא
    {
        //console.log('nevigateIndex ', this.nevigateIndex);
      
      if (this.menu_page.Children[this.nevigateIndex + 1].Template != "refLevel")//רגיל שלב אחד קדימה
        this.nevigateIndex = this.nevigateIndex + 1;
      else//שתי שלבים קדימה במידה והשלב הבא הוא תהליך משני (מנווט רק ע"י כפתור מסוג מצביע)
      {
        this.nevigateIndex = this.nevigateIndex + 2;
      }
      return [this.nevigatePrefix, this.nevigateIndex].join("/");
    }
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.prevSecondaryProcess)//ניווט בתוך תהליך משני אחורה
    {
      if (this.nevigateIndex_second == 1)//במידה ועובר לשלב הראשון 0 לעדכן שהוא בהתחלה
        this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.StartProcess));
      return [this.nevigatePrefix, this.nevigateIndex, this.nevigate_second, +(--this.nevigateIndex_second)].join("/");
    }
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.nextSecondaryProcess)//ניווט בתוך תהליך משני קדימה
    {
      if (this.nevigateIndex_second == 0)//במידה ועובר לשלב אחרי הראשון לעדכן שהוא באמצע
        this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.InProcess));
      return [this.nevigatePrefix, this.nevigateIndex, this.nevigate_second, +(++this.nevigateIndex_second)].join("/");
    }
    if (navigate.StatusNavigate == FormComponent.eStatusNavigate.backToProcess)//ניווט לשלב האחרון בתהליך
    {
      this.nevigateIndex = this.menu_page.Children.length;
      if (this.menu_page.Children[this.nevigateIndex - 1].Template != "refLevel")//ניווט רגיל אחד אחורה
        this.nevigateIndex = this.nevigateIndex - 1;
      else//שתי שלבים אחורה במידה והשלב הקודם הוא תהליך משני (מנווט רק ע"י כפתור מסוג מצביע)
        this.nevigateIndex = this.nevigateIndex - 2;
      return [this.nevigatePrefix, this.nevigateIndex].join("/");
    }
    return '';


  }

  openOtp(): Promise<any> {
    $('.otp').addClass('d-none');
    const dialogRef = this.dialog.open(OtpPopupComponent, {
      width: '700px',
    });
  
    return new Promise((resolve, reject) => {
      dialogRef.afterClosed().subscribe(result => {
        if (result?.data) {
          $('.otp').removeClass('d-none');
          this.conditionalLogic.disableControlFormSection(this.currentProcess.Contact.Controls, '');
          console.log("otp");
          resolve(result?.data ?? false); // resolve the Promise with the OTP data
        } else {
          resolve(false); // reject the Promise with an error message
        }
      });
    });
  }
}