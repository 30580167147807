import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as JsonObjects from '../../classes';
import { FormGroup } from '@angular/forms';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/reducer';
import { ApiService } from '../../services/api.service';
import { catchError, finalize, map, mergeMap, share} from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { FormComponent } from '../../structureObject'
import { FileInput } from 'ngx-material-file-input';
import { APIService } from '../../../export-files/viewer-pdf/services/rest.service';
import { AccountService } from 'src/app/account.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
    currentUserForm: FormGroup;//טופס משתמש

    constructor(private apiService: APIService, private api: ApiService,
        public dialogRef: MatDialogRef<DialogComponent>,private router: Router, private ngRedux: NgRedux<IAppState>,
        private account: AccountService,
        @Inject(MAT_DIALOG_DATA) public data: {
            Type: string,
            NotificationControl: JsonObjects.Notification,
            Header: string,
            Message: string,
            Controls: JsonObjects.Control[],
            Component: JsonObjects.RefJsonFile
        }) { }
    idExtension;
    public statusEmail$: Observable<FormComponent.MappedData<string>> = of(
        {
            message: "",
            statusRequest: FormComponent.eStatusRequestServer.BeforeRequest
        }
    );
    ngOnInit() {
        if (this.data.Component) {
            this.idExtension = this.data.Component.Copy != null ? `_COPY_${this.data.Component.Copy}` : '';
            this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => {
                var CurrentformControls = {}
                Object.keys(userForm.controls).forEach(controlId => {
                    var indexControl = this.data.Controls.findIndex(c => c.ControlId + this.idExtension == controlId)
                    if (indexControl >= 0)
                        CurrentformControls[controlId] = userForm.controls[controlId];
                })
                this.currentUserForm = new FormGroup(CurrentformControls);
            })
        }

    }

    //************************* */
    public saveFiles(userInputs: JsonObjects.UserInputs): Observable<FormComponent.MappedData<string>> {
        let files: Array<{ file: FileInput, control_id: string }> = []
        //שמירה בשרת קבצים
        userInputs.Children.forEach((section, i) => {
            section.Children.forEach((userInput, j) => {
                if (userInput.Type == 'uploadFile' && !userInput.IsDraft)
                    files[files.length] = { file: (userInput.Value as FileInput), control_id: section.Id + "/" + userInput.Id }
            });
        });
        return this.apiService.postFile(files);
    }
    public sendEmailEndProcess() {
        const $UserDetailsEntered = this.ngRedux.select(o => o.UserDetailsEntered);
        this.statusEmail$ = $UserDetailsEntered.pipe(
            share(),
            mergeMap((userDetailsEntered: JsonObjects.UserInputs) => {
                return this.saveFiles(userDetailsEntered).pipe(
                    catchError((e) => {
                        console.log(`Error ${e.Message}`);
                        setTimeout(() => {
                            this.dialogRef.close({ data:    {
                                message: "ארעה תקלה במערכת",
                                statusRequest: FormComponent.eStatusRequestServer.FailRequest
                              } })
                        }, 8000);
                        return throwError(e.Message)
                    }),
                    mergeMap((statusUploadURL: FormComponent.MappedData<string>) => {
                        if (statusUploadURL.statusRequest == FormComponent.eStatusRequestServer.SucceedRequest)
                            return this.apiService.savePdf(userDetailsEntered,  this.account.getAccount()).pipe(
                                map((statusSavePdf: FormComponent.MappedData<string>) => {
                                    if (statusSavePdf.statusRequest == FormComponent.eStatusRequestServer.FailRequest || statusSavePdf.statusRequest == FormComponent.eStatusRequestServer.SucceedRequest) {
                                        setTimeout(() => {
                                            this.dialogRef.close({ data:  statusSavePdf })
                                            this.router.navigate(['']);
                                            window.location.href='/';
                                            //window.location.reload();
                                        }, 8000);
                                        
                                    }
                                    return statusSavePdf
                                }))
                        else
                            return of(statusUploadURL);
                    })//,
                    // finalize(() => {
                    //     setTimeout(() => {
                    //         this.dialogRef.close({ data: 'data' })
                    //     }, 8000);
                    // })
                )
            }
            ))
    }

    //************************************** */
    sendEmailProcessStopped(): void {
        this.statusEmail$ =
            this.ngRedux.select((o) => o.UserDetailsEntered)
                .pipe(

                    mergeMap((uDetails: JsonObjects.UserInputs) => {
                        return this.api.sendEmailwithDatailsEmail(uDetails, this.data.NotificationControl)
                    }));
    }
    sendEmailTalkus(): void {
        const datailsEmail: JsonObjects.ControlDatailesEmail[] = this.data.Controls.map(c => {
            return <JsonObjects.ControlDatailesEmail>{ id: c.ControlId, Label: c.Label, controlName: c.ControlName, value: this.currentUserForm.controls[c.ControlId + this.idExtension].value }
        });
        this.statusEmail$ = this.api.sendEmailcontrolDatailesEmail(datailsEmail).pipe(
            finalize(() => {
                setTimeout(() => {
                    this.dialogRef.close()
                }, 8000);
            })
        );
    }
    sendEmail(status: string): void {
        var result = this.ngRedux.select(o => o.UserDetailsEntered).pipe(
            map((uDetails) => {
                return this.api.sendEmailGeneral(status, uDetails).pipe(
                    finalize(() => { this.dialogRef.close(); })
                ).toPromise()
            })
        ).toPromise()
    }

    // CopyFilesToBackupFolder(): void {
    //     this.statusEmail$ =
    //         this.ngRedux.select((o) => o.UserDetailsEntered)
    //             .pipe(

    //                 mergeMap((uDetails: JsonObjects.UserInputs) => {
    //                     return this.api.CopyFilesInOutpuFolder(uDetails, this.data.NotificationControl)
    //                 }));
    // }

    onNoClick() {
        this.dialogRef.close();
    }
}