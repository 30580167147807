<button  
 aria-label="אינפיניטי. לחץ כאן לאישור" 
 class="btn-confirmation" 
 [disabled]="formInvalid"
 [ngClass]="{'footer-button-disable':formInvalid=== true ,'footer-button-active': formInvalid === false }"
 mat-raised-button 
 mat-button 
 type="submit" 
 (click)="enterInformation()">
 {{typeEmail == "endProcess"?'קבלתי':'אשר'}}
</button>
