

//namespace a{
 
    import {TypeInput} from './TypeInput' 
    export class TypesValidation{       
        TypeInputs? :TypeInput[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










