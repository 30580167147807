

//namespace a{
 
    import {Conditional} from './Conditional' 
    export class ConditionalLogicGroup extends Conditional {
       
LogicalOperator? :string;
ConditionalList? :Conditional[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










