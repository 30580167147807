

//namespace a{
 
    import {RequiredValidation} from './RequiredValidation' 
    export class DateExpandValidation extends RequiredValidation {
       
Mindate? :string;
Maxdate? :string;
Minage? :string;
Maxage? :string;

    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










