

//namespace a{
 
    import {SectionControl} from './SectionControl' 
    export class DefaultValue {
       
Disabled? :boolean;
Value? :string;
SourceValue? :string;
IsBindingControl? :boolean;
CalculationControls? :string;
Controls? :SectionControl[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










