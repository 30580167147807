import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
var errors: { [id: string] : string; } = {};
errors["404"] ="This page doesn't exist";
errors["500"] = "500 SERVER ERROR, CONTACT ADMINISTRATOR!!!!";
errors["0"]="Unknown Error";
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  ErrorCode:string;
  ErrorMassege:string;
  constructor(private route: ActivatedRoute) {
 
  }
  ngOnInit() {
    this.ErrorCode=this.route.snapshot.paramMap.get('Type')
    this.ErrorMassege =errors[this.ErrorCode];
  }
  
}
