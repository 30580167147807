

//namespace a{
 
    
    export class Conditional {
       
Activated? :boolean;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










