import { Injectable } from '@angular/core';
import { UserValueEnterrd } from './services/user-value-entered.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store/reducer'///src/store/reducer';
import { FormGroup } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { NumberFormatPipe } from '../shared/pipes/numberFormat.pipe';
import { ShowSumService } from './services/show-sum.service';

@Injectable({ providedIn: 'root' })
export class CustomString {
  userForm: FormGroup;//טופס משתמש

  constructor(private userValueService: UserValueEnterrd, private ngRedux: NgRedux<IAppState>, private numberFormatPipe: NumberFormatPipe,private showSum : ShowSumService) {
    this.ngRedux.select(o=>o.UserForm).subscribe((userForm: FormGroup) => { this.userForm = userForm; });//שליפת טופס משתמש
  }

  escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  replaceAll(str, find, replace) {
    return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
  }

  execCode(idWithidExtension: string):boolean {//הפעלת תגיות קוד לפי מזהה
    let status:boolean=true;
    let elementHtml = document.getElementById(`${idWithidExtension}`);
    if (elementHtml) {
      let codeTagHtmls: NodeListOf<Element> = elementHtml.querySelectorAll('code');
      if (codeTagHtmls.length > 0) {
        codeTagHtmls.forEach((codeTagHtml: Element) => {
          var codeContentSplit: string[] = codeTagHtml.innerHTML.split(',');//שם פונקציה, מזהה מקטע, מזהה פקד
          if (codeContentSplit[0] == 'getValueControl' && codeContentSplit[1] && codeContentSplit[2])//שם פונקציה תואם לקבלת ערך ומזהה מקטע ופקד קיימים
          {
            let value= this.getValueControl(idWithidExtension,codeTagHtml, codeContentSplit[1], codeContentSplit[2])//קבלת ערך פקד                  
            if(value=="")            
              status=false;
              
          }
          else if (codeContentSplit[0] == 'getPercentageCalculation' && codeContentSplit[1] && codeContentSplit[2] && codeContentSplit[3] && codeContentSplit[4])//שם פונקציה תואם לחישוב אחוז ומזהה מקטע ופקד קיימים
          {
           let value= this.getPercentageCalculation(codeTagHtml, codeContentSplit[1], codeContentSplit[2], codeContentSplit[3], codeContentSplit[4])//קבלת חישוב אחוז לפי ערך פקד                
           if(value=="")            
              status=false;
          }
        })
      }     
    }
   return status;
  }

  getValueControl(idWithidExtension:string,codeTagHtml: Element, sectionId: string, controlId: string) {//קבלת ערך פקד       
  
    //אם כל הערכים מלאים עושים בדיקה
    var value = "";
    if (sectionId !== null && controlId !== null) {
      var control = this.userForm.get(controlId);// שליפת הפקד מהטופס הנוכחי       
      if (control != null)//אם אתה באותו שלב                
      {
        control.valueChanges.pipe().subscribe((currentValue: string) => {//האזנה לשינויים

          currentValue = this.numberFormatPipe.localeString(currentValue);//הוספת פסיקים למספר
          codeTagHtml.innerHTML = currentValue;
          this.showSum.setHidden( idWithidExtension ,currentValue=="");
        });
        value = this.userForm.controls[controlId].value;  
   
      }
      else //בדיקה ברידקס
        value = this.userValueService.selectUserValueEntered({ sectionId: sectionId, fieldId: controlId })
    }
    value = value == null ? '' : this.numberFormatPipe.localeString(value);//הוספת פסיקים למספר
    codeTagHtml.innerHTML = value;
   
    return value;
  }

  getPercentageCalculation(codeTagHtml: Element, amountSectionId: string, amountControlId: string, percentSectionId: string, percentControlId: string) {//קבלת חישוב אחוז לפי ערך פקד
    //אם כל הערכים מלאים עושים בדיקה
    var value = "";
    if (amountSectionId !== null && amountControlId !== null && percentSectionId !== null && percentControlId !== null) {
      //קבלת הסכום לחישוב אחוז
      var amount = ""
      var amountControl = this.userForm.get(amountControlId);// שליפת הפקד מהטופס הנוכחי       
      if (amountControl != null)//אם אתה באותו שלב                
      {
        amountControl.valueChanges.pipe(distinctUntilChanged()).subscribe((currentValue) => {//האזנה לשינויים
          if(typeof currentValue ==='string'){
            amount = currentValue.replace(/[,]/g, '');//הורדת פסיקים
            //חישוב סכום האחוז
            if (!isNaN(+amount) && !isNaN(+percent))
              value = (+amount * +percent / +100).toString();
            value = value == null ? '' : this.numberFormatPipe.localeString(value);//הוספת פסיקים למספר
            codeTagHtml.innerHTML = value;
          }
          else{
            console.log('currentValue: ',currentValue);
          }
        });

        amount = this.userForm.controls[amountControlId].value;
      }
      else //בדיקה ברידקס
        amount = this.userValueService.selectUserValueEntered({ sectionId: amountSectionId, fieldId: amountControlId })
      if(amount)
      amount = amount.replace(/[,]/g, '');//הורדת פסיקי ם

      //קבלת האחוז
      var percent = ""
      var percentControl = this.userForm.get(percentControlId);// שליפת הפקד מהטופס הנוכחי       
      if (percentControl != null)//אם אתה באותו שלב                
      {
        percentControl.valueChanges.pipe(distinctUntilChanged()).subscribe((currentValue) => {//האזנה לשינויים
          percent = currentValue.toString().replace(/[,]/g, '');//הורדת פסיקים
          //חישוב סכום האחוז
          if (!isNaN(+amount) && !isNaN(+percent))
            value = (+amount * +percent / +100).toString();
          value = value == null ? '' : this.numberFormatPipe.localeString(value);//הוספת פסיקים למספר
          codeTagHtml.innerHTML = value;
        });

        percent = this.userForm.controls[percentControlId].value;
      }
      else //בדיקה ברידקס
        percent = this.userValueService.selectUserValueEntered({ sectionId: percentSectionId, fieldId: percentControlId })

      if (percent)
        percent = percent.replace(/[,]/g, '');//הורדת פסיקים

      //חישוב סכום האחוז
      if (!isNaN(+amount) && !isNaN(+percent))
        value = (+amount * +percent / +100).toString();
    }
    value = value == null ? '' : this.numberFormatPipe.localeString(value);//הוספת פסיקים למספר
    codeTagHtml.innerHTML = value;
    return value
  }
}