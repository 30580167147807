

//namespace a{
 
    import {MainLevel} from './MainLevel' 
import {EmailUser} from './EmailUser' 
import {EmailEditor} from './EmailEditor' 
import {Contact} from './Contact' 
import {Control} from './Control' 
    export class Process {
       
Name? :string;
ProcessNum? :string;
MainLevels? :MainLevel[];
EmailsUser? :EmailUser[];
EmailsEditor? :EmailEditor[];
ProcessTitle? :string;
MainScreenContent? :string;
LoginButtonTitle? :string;
Contact? :Contact;
Controls? :Control[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










