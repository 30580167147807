

//namespace a{
 
    import {Control} from './Control' 
    export class Contact {
       
ContactHeader? :string;
OperatorTitle? :string;
ContactNum? :string;
Controls? :Control[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










