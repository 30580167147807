import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { UserInputs } from '../shared/classes';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'src/store/reducer';
import { catchError, map, mergeMap, share } from 'rxjs/operators';
import { FormComponent } from '../shared/structureObject';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { DialogComponent } from '../shared/controls/dialog/dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { FileInput } from 'ngx-material-file-input';
import { APIService } from '../export-files/viewer-pdf/services/rest.service';

@Component({
  selector: 'app-resend-sms',
  templateUrl: './resend-sms.component.html',
  styleUrls: ['./resend-sms.component.scss']
})
export class ResendSMSComponent implements OnInit {
  processNum: string; 
  constructor(private api: ApiService, private apiService: APIService, private ngRedux: NgRedux<IAppState>, 
    public dialogRef: MatDialogRef<DialogComponent>) {
      var url = new URL(window.location.href);
      this.processNum = url.searchParams.get("processNum");
     }

  ngOnInit(): void {
  }
  public setClientSMS() {
    return this.apiService.setClientSMS();
  }
  
  
}
