import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JsonObjects from '../../classes'
import { CustomValidation } from '../../custom-validation';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

    @Input() input: JsonObjects.Options;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק
    classList: string[] = [];//קלאסים של הפקד הנוכחי

    ngOnInit() {
        this.classList = this.input.Class ? this.input.Class.split(' ') : [];//שליפת הקלאסים של הפקד
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק 
    }
    isClassExists(isClass: string = '') {
        return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
    } 
}