import { Injectable } from '@angular/core';
import * as JsonObjects from 'src/app/shared/classes';
import { FormComponent } from 'src/app/shared/structureObject';
import { AbstractControl, FormGroup } from '@angular/forms';

@Injectable()
export class CounterActions {

  static UPDATE_USER_FORM = 'UPDATE_USER_FORM';
  static DISABLE_CONTROL_FORM = 'DISABLE_CONTROL_FORM';
  static ENABLE_CONTROL_FORM = 'ENABLE_CONTROL_FORM';
  static DISABLE_CONTROLS_FORM = 'DISABLE_CONTROLS_FORM';
  static ENABLE_CONTROLS_FORM = 'ENABLE_CONTROLS_FORM';
  static SET_VALUE_CONTROL_FORM = 'SET_VALUE_CONTROL_FORM'
  static SET_ERROR_CONTROL_FORM = 'SET_ERROR_CONTROL_FORM'
  static INSERT_INPUTS_COMPONENT = 'INSERT_INPUTS_COMPONENT';
  static REMOVE_INPUTS_COMPONENT = 'REMOVE_INPUTS_COMPONENT';
  static UPDATE_INPUTS_COMPONENT = 'UPDATE_INPUTS_COMPONENT';
  static UPDATE_DRAFT_COMPONENTS = 'UPDATE_DRAFT_COMPONENTS';
  static UPDATE_DIS_DRAFT_COMPONENTS = 'UPDATE_DIS_DRAFT_COMPONENTS';
  static CHANGE_CURRENT_LEVEL = 'CHANGE_CURRENT_LEVEL';
  static CHANGE_CURRENT_LEVEL_SETTINGS = 'CHANGE_CURRENT_LEVEL_SETTINGS';
  static CHANGE_STATUS_USER = 'CHANGE_STATUS_USER';
  static CHANGE_STATUS_USER_SECONDRAY_PROCESS = 'CHANGE_STATUS_USER_SECONDRAY_PROCESS';
  static CHANGE_NUM_SECOUND_SECONDRAY_PROCESS='CHANGE_NUM_SECOUND_SECONDRAY_PROCESS';
  static SET_PROCESS_NUM = 'SET_PROCESS_NUM';
  static CHANGE_SERVER_ERROR_MESSAGE = 'CHANGE_SERVER_ERROR_MESSAGE';
  static CHANGE_SECTION_HEADER='CHANGE_SECTION_HEADER'
  static CHANGE_IS_COMPLETED_SECTIONS='CHANGE_IS_COMPLETED_SECTIONS';
  static ADD_COPY_SECTION_INPUTS_COMPONENT='ADD_COPY_SECTION_INPUTS_COMPONENT'
  static CHANGE_NAVIGATE_LEVEL='CHANGE_NAVIGATE_LEVEL'

  actions = {
    updateUserForm: (userFormControls:{[key: string]: AbstractControl;}) => ({ type: CounterActions.UPDATE_USER_FORM, userFormControls: userFormControls }),//עדכון טופס משתמש
    disableControlForm: (controlId: string) => ({ type: CounterActions.DISABLE_CONTROL_FORM, controlId: controlId }),//הורדת ולידשן פקד מטופס משתמש
    enableControlForm: (controlId: string) => ({ type: CounterActions.ENABLE_CONTROL_FORM, controlId: controlId }),//הוספת פקד לטופס משתמש
    disableControlsForm: (userForm: FormGroup) => ({ type: CounterActions.DISABLE_CONTROLS_FORM, userForm: userForm }),//הורדת ולידשן לפקדים מטופס משתמש
    enableControlsForm: (userForm: FormGroup) => ({ type: CounterActions.ENABLE_CONTROLS_FORM, userForm: userForm }),//הוספת לפקדים לטופס משתמש
    setValueControlForm: (controlId: string, value: any) => ({ type: CounterActions.SET_VALUE_CONTROL_FORM, control: { id: controlId, value: value } }),//הכנסת ערך לפקד טופס משתמש
    setErrorControlForm: (controlId: string, value: any) => ({ type: CounterActions.SET_ERROR_CONTROL_FORM, control: { id: controlId, value: value } }),//הכנסת שגיאה לפקד טופס משתמש
    insertInputsComponent: (userInputs: JsonObjects.UserInputs) => ({ type: CounterActions.INSERT_INPUTS_COMPONENT, userInputs: userInputs }),//הכנסת ערכי המשתמש לפי מקטע
    addCopySectionInputsComponent: (sectionId: string,copy:number) => ({ type: CounterActions.ADD_COPY_SECTION_INPUTS_COMPONENT, sectionId: sectionId,copy:copy}),//הכנסת ערכי המשתמש לפי מקטע
    removeInputsComponent: (IdInput: string) => ({ type: CounterActions.REMOVE_INPUTS_COMPONENT, IdInput: IdInput }),//מחיקת ערכי המשתמש לפי מקטע
    updateInputsComponent: (userInputs: JsonObjects.UserInputs) => ({ type: CounterActions.UPDATE_INPUTS_COMPONENT, userInputs: userInputs }),//עדכוני ערכי המשתמש לפי מקטע
    updateDraftComponents: (draftComponentsId: string[]) => ({ type: CounterActions.UPDATE_DRAFT_COMPONENTS, draftComponentsId: draftComponentsId }),//עדכון ערכי משתמש המושבתים- טיוטה שבמקטעים מושבתים
    updateDisDraftComponents: (disdraftComponentsId: string[]) => ({ type: CounterActions.UPDATE_DIS_DRAFT_COMPONENTS, disdraftComponentsId: disdraftComponentsId }),//עדכון ערכי משתמש המושבתים- לא טיוטה שבמקטעים מאופשרים
    changeCurrentLevel: (currentlevel: JsonObjects.RefJsonFile) => ({ type: CounterActions.CHANGE_CURRENT_LEVEL, payload: currentlevel }),//שינוי שלב נוכחי
    changeCurrentSectionHeader: (indexSection:number,headerSection: string) => ({ type: CounterActions.CHANGE_SECTION_HEADER, payload: {indexSection:indexSection,headerSection: headerSection} }),//שינוי שלב נוכחי
    changeCurrentLevelSettings: (currentlevelSettings: JsonObjects.Level) => ({ type: CounterActions.CHANGE_CURRENT_LEVEL_SETTINGS, payload: currentlevelSettings }),//שינוי הגדרות שלב נוכחי
    changeStatusUser: (statusUser: string) => ({ type: CounterActions.CHANGE_STATUS_USER, payload: statusUser }),//שינוי סטטוס ריצה
    changeStatusUserSecondaryProcess: (statusUserSecondaryProcess: string) => ({ type: CounterActions.CHANGE_STATUS_USER_SECONDRAY_PROCESS, payload: statusUserSecondaryProcess }),// שינוי סטטוס ריצה תהליך משני
    changeNumSecondaryProcess: (numSecondaryProcess: string) => ({ type: CounterActions.CHANGE_NUM_SECOUND_SECONDRAY_PROCESS, payload: numSecondaryProcess }),// שינוי סטטוס ריצה תהליך משני
    changeServerErrorMessage: (ErrorMessage: FormComponent.ErrorServer) => ({ type: CounterActions.CHANGE_SERVER_ERROR_MESSAGE, payload: ErrorMessage }),//שגיאה שבאה מהשרת בסיום התהליך
    changeIsCompletedSections: (isCompletedSections: boolean) => ({ type: CounterActions.CHANGE_IS_COMPLETED_SECTIONS, payload: isCompletedSections }),//שינוי אם הושלמו בניית המקטעים
    changeNavigateLevel: (statusNavigate,levelNum: string="") => ({ type: CounterActions.CHANGE_NAVIGATE_LEVEL, payload: {statusNavigate:statusNavigate,levelNum:levelNum} }),//שינוי אם הושלמו בניית המקטעים
    setProcessNum: (processNum: string) => ({ type: CounterActions.SET_PROCESS_NUM, payload: processNum }),  //מזהה תהליך
  }
}