

//namespace a{
 
    
    export class RefJsonFile {
       
CompName? :string;
Num? :string;
Hierarchy? :string;
Template? :string;
Copy? :string;
Children? :RefJsonFile[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










