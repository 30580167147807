

//namespace a{
 
    
    export class MainLevel {
       
IsMainLevel? :boolean;
LevelNum? :string;
MainLevelTitle? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










