

//namespace a{
 
    import {Conditional} from './Conditional' 
import {SectionControl} from './SectionControl' 
    export class ConditionalLogic extends Conditional {
       
EqualityOperator? :string;
Control? :SectionControl;
EqualityValue? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










