

//namespace a{
 
    export class TypeInput{
        Text? :string;
        Value? :string;
        Pattern? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










