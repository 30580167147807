

//namespace a{
 
    import {SectionControl} from './SectionControl' 
    export class EmailDetailsMember {
       
ClientInputSelected? :SectionControl[];
SystemSelectedID? :string[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










