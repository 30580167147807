import { CustomValidation } from '../../../shared/custom-validation'
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, AfterViewInit, Output, EventEmitter, ViewChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgRedux } from '@angular-redux/store';
import { MatDialog } from '@angular/material/dialog';
import { IAppState } from '../../../../store/reducer';
import { Subscription, Subject } from 'rxjs';
import * as JsonObjects from '../../../shared/classes';
import { CounterActions } from '../../../../store/actions';
import { ApiService } from '../../../shared/services/api.service'
import { ConditionalLogic } from '../../../shared/services/conditionalLogic.service';
import { ProgrammingExpressionService } from '../../../shared/services/programmingExpression.service'
import { LevelSectionService } from '../../../shared/services/levelSection.service';
import { FormComponent } from '../../../shared/structureObject'
import { ResponsiveService } from '../../../shared/services/resonsive.service'
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']//,
  //encapsulation: ViewEncapsulation.None
})
export class SectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() currentSection: JsonObjects.FormSection;//המקטע הנוכחי עם הפקדים
  @Input() currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי
  @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
  @Output() setSectionCompleted: EventEmitter<string> = new EventEmitter();//הצהרה שהמקטע הושלם

  userForm: FormGroup;//טופס משתמש
  validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
  idExtension: string = '';//מזהה עותק
  isControlsConditionTrue: any = {};//מערך ששומר לכל פקד האם הוא מוצג או לא
  private subscription: Subscription[] = new Array<Subscription>();//מערך האזנות, כדי למחוק כשהקומפוננטה נהרסת
  get eDevice() {
    return FormComponent.eDevice;
  }
  get device() {
    return this.responsiveService.device;
  }
  @ViewChildren(SectionComponent) arrSection: QueryList<SectionComponent>;//מערך קופוננטות עבור כל שלב

  constructor(private responsiveService: ResponsiveService, private cd: ChangeDetectorRef, private ngRedux: NgRedux<IAppState>, private actions: CounterActions, public customValidation: CustomValidation,
    private conditionalLogic: ConditionalLogic, public dialog: MatDialog, public apiService: ApiService, public programmingExpressionService: ProgrammingExpressionService,
    private levelSectionService: LevelSectionService) {
    this.subscription.push(this.levelSectionService.getsavingSectionSubject().subscribe(response => { if (this.currentComponent.Template != "information") this.saveUserInputRedux() }));
  }

  ngOnInit() {
    this.subscription.push(this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => { this.userForm = userForm }));//שליפת טופס משתמש      
    //this.ngRedux.select(n=>n.FormSettings.ServerErrorMessage).subscribe((serverError:FormComponent.ErrorServer)=>{ if(serverError!=undefined) this.setServerErrorMessageControl(serverError) });   
    this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
    this.InitValues();//איתחול הקלטים מהרידקס
    this.checkSectionControlsCondition();//בדיקת התצוגה מותנית למקטע ולפקדים
    this.programmingExpressionService.activatingProgrammerCodeControls(this.currentSection, this.userForm, this.idExtension);////הפעלת הפקדים שיש להם קוד מתכנת
    this.cd.detectChanges();
    // this.confirmValidParentMatcher=new confirmValidParentMatcher()   
  }
  setServerErrorMessageControl(serverError: FormComponent.ErrorServer) {
    let input: JsonObjects.Input = (this.currentSection.Controls
      .find(control => { return control.ControlId == serverError.ControlId }) as JsonObjects.Input);
    if (input != undefined) {
      let control: FormControl = this.userForm.controls[serverError.ControlId] as FormControl;
      this.userForm.controls[serverError.ControlId] = this.customValidation.addValidatorServer(control, serverError.ErrorMessage, input);
      this.cd.detectChanges();
      this.ngRedux.dispatch(this.actions.actions.changeServerErrorMessage(undefined));
    }
  }
  //מציאת הפוקוס הבא לזמן בעת אירוע לחיצה
  focusNextElement() {
    //add all elements we want to include in our selection
    var focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    if (document && document) {
      var focussable = Array.prototype.filter.call(document.querySelectorAll(focussableElements),
        function (element) {
          //check for visibility while always include the current activeElement 
          return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document
        });
      var index = focussable.indexOf(document.activeElement);
      if (index > -1) {
        var nextElement = focussable[index + 1] || focussable[0];
        nextElement.focus();
      }
    }
  }
  InitValues() {

    //בדיקה האם יש קלטים של הקומפוננטה ברידקס
    this.ngRedux.select('UserDetailsEntered').subscribe((userInputs: JsonObjects.UserInputs) => {
      //localStorage הכנסת ערך שמור מ
      let x = this.currentSection.SectionNum;
      var values66: Map<string, string> = JSON.parse(localStorage.getItem(x));
      if (values66)
        Object.keys(this.userForm.controls).forEach(key => {
          if (this.userForm.controls[key].value == "" && values66[key] != undefined && values66[key] != "")
            this.userForm.controls[key].setValue(values66[key]);
        });
      //יצירת פונקציה ברדקס שמקבלת מזהה קומפוננטה ומחזיר מערך של שמות שדות וערכים
      let currentIndexComp = userInputs.Children.map(i => i.Id).indexOf(this.currentSection.SectionNum + this.idExtension);

      if (currentIndexComp >= 0) {
        //הכנסת הערכים מהרידקס לתצוגה- fromGroup
        userInputs.Children[currentIndexComp].Children.forEach(userInput => {
          //checkbox בתנאי שהפקד קיים בטופס והוא ריק (אים אינו ריק סימן שכשיצרו את הפקד איתחלו אותו כבר) או שהוא מכיל טופס פקדים- שזה
          //פקד מקושר אינו מופיע ברידקס אלא מי שאליו מקושר רק הוא קיים
          //ערך ברידקס יותר מעודכן מערך דיפולטיבי
          //this.userForm.controls[userInput.Id] != null היה שימושי כאשר היו מוחקים את השדה מקושר מהFROMGROUP
          var control: JsonObjects.Control = this.currentSection.Controls.find(c => c.ControlId + this.idExtension == userInput.Id)
          if (this.userForm.controls[userInput.Id] != null && (control.ControlType.includes("checkbox") || this.userForm.controls[userInput.Id].value == "" || (userInput.Value == '0' || userInput.Value != '')))
            if (!control.Hidden)//אין לעדכן פקדים מוסתרים- חישוביים
              this.userForm.controls[userInput.Id].setValue(userInput.Value)//עדכון ערך פקד UserForm

        })
        //markFormGroupTouched(this.form); אין צורך להראות שגיאות עם לא עשו 'הבא' סבמיט


      }
    }).unsubscribe();
  }
  checkSectionControlsCondition() {
    this.currentSection.Controls.forEach(control => {
      this.isControlsConditionTrue[control.ControlId + this.idExtension] = false;//איתחול למערך פקדים
      //לפקד מוסתר או מושבת אין לבדוק תצוגה מותנית ויש להשאיר אותו מוסתר
      if (!(control instanceof JsonObjects.Input && (control as JsonObjects.Input).Hidden) && !control.Disabled)//בדיקה אם מסומן לפקד שלא יהיה מוסתר
      {
        if (control.ConditionalLogicGroup.Activated == true) {//אם יש לפקד תצוגה מותנית
          //הכנסה לשירות של תצוגה מותנית את הפונקציה שיש להפעיל כשנשתנה הפקד שעליו התנאי היה
          this.conditionalLogic.recheckConditionalLogic[control.ControlId + this.idExtension] = new Subject<any>();
          this.subscription.push(this.conditionalLogic.recheckConditionalLogic[control.ControlId + this.idExtension].subscribe((controlIdChange: string) => {
            this.checkControlCondition(control, false);//פונקציית בדיקה לפקד רגיל
          }));
          this.checkControlCondition(control, true);//בדיקת תצוגת הפקדים ומילוי מערך הפקדים
        }
        else {
          this.isControlsConditionTrue[control.ControlId + this.idExtension] = true;//הצג פקד          
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.forEach(sub => sub.unsubscribe());//מחיקת האזנות
  }
  ngAfterViewInit(): void {
    //this.saveUserInputRedux();
    this.setCurrentSectionCompleted(this.currentComponent.Num + this.idExtension);
  }
  getClassSection(classSection)
  {
    let sectionClass=classSection?classSection:'';
    sectionClass= sectionClass+' form'
    if(this.currentComponent.Template=='information')
      return 'informationSection '+sectionClass;    
    return sectionClass;
  }
  setCurrentSectionCompleted(sectionNum: string) {//המקטע הנוכחי הושלם
    this.setSectionCompleted.emit(sectionNum);//המקטע הושלם
  }

  saveUserInputRedux() {//שמירת המקטע
    //הגדרת אוביקט של שמירת קלטי משתמש
    var section: JsonObjects.UserInputs;
    section = { Id: this.currentComponent.Num + this.idExtension, Value: this.currentComponent.Num + this.idExtension, Type: "section",IsDraft:false , Children: [] } as JsonObjects.UserInputs;
    section = this.addControlsToSave(section);
    //שמירה בטופס ברידקס ערכים שהוזנו
    this.ngRedux.dispatch(this.actions.actions.insertInputsComponent(section));
   this.saveToLocalSession(section);//localStorage שמירת מזהה מקטע ורשימת מזהי פקדים וערכיהם ל
  }

  saveToLocalSession(section: JsonObjects.UserInputs) {//localStorage שמירת מזהה מקטע ורשימת מזהי פקדים וערכיהם ל
    var values: { [key: string]: string } = {};
    this.currentSection.Controls.filter(x => !x.IgnoreSession).forEach(//איסוף מזהה פקד וערכו  
      x => {
        var controlIndex = section.Children.findIndex(c => c.Id == x.ControlId + this.idExtension);
        values[x.ControlId + this.idExtension] = controlIndex >= 0 && x.ControlType != 'signature' && x.ControlType != 'uploadFile' ? section.Children[controlIndex].Value : null;
      }
    );
    localStorage.setItem(section.Id, JSON.stringify(values));
  }

  addControlsToSave(section: JsonObjects.UserInputs): JsonObjects.UserInputs {//הוספת פקדים לשמירה
    let currentSectionPass = JSON.parse(JSON.stringify(this.currentSection.Controls))
    currentSectionPass.forEach((controlData: JsonObjects.Control) => {
      //יש לשמור גם פקדים מוסתרים אבל אין לשמור פקדים מושבתים
      if (!controlData.Disabled)//יש לשמור פקדים פעילים
      {
        let input: JsonObjects.Input = controlData;
        //פקד מקושר רק לפקד אחד
        if (input && input.DefaultValue && input.DefaultValue.Disabled && input.DefaultValue.IsBindingControl && input.DefaultValue.Controls) {

          //אם ערך הפקד מקושר לפקד אחר- אז יש לעדכן ברידקס את הפקד השני בערך החדש
          if (!this.userForm.controls[input.DefaultValue.Controls[0].Field] && input.DefaultValue.Controls[0].Section && input.DefaultValue.Controls[0].Field) {
            var updateChildrenSection: JsonObjects.UserInputs = { Id: input.DefaultValue.Controls[0].Section, Value: '', Children: [] };
            updateChildrenSection.Children.push({ Id: input.DefaultValue.Controls[0].Field, Value: this.userForm.controls[controlData.ControlId + this.idExtension].value, IsDraft: this.userForm.controls[controlData.ControlId + this.idExtension].status == "VALID" } as JsonObjects.UserInputs)
            this.updateInputsComponent(updateChildrenSection);//שליחת ערך פקד לעידכון הפקד השני ברידקס
          }
        }
        else//אם הפקד מקושר אז אין לשמורו ברידקס כי הוא מסתמך על פקד אחר
          if (controlData.ControlName != 'headerSection' && controlData.ControlType != 'textControl')//אין צורך לשמור ברדקס textControl כי אין לו ערך
            section.Children.push(this.getValueControl(controlData))
      }
    });
    return section;
  }

  getValueControl(controlData: JsonObjects.Control): JsonObjects.UserInputs {//קבלת ערך פקד
    //קבלת ערך פקד
    let value = this.userForm.controls[controlData.ControlId + this.idExtension].value;
    return { Id: controlData.ControlId + this.idExtension, Value: value, Type: controlData.ControlType, IsDraft: !this.isControlsConditionTrue[controlData.ControlId + this.idExtension]} as JsonObjects.UserInputs;
  }

  updateInputsComponent(userInputs: JsonObjects.UserInputs) {//עידכון ערכי משתמש ברידקס
    this.ngRedux.dispatch(this.actions.actions.updateInputsComponent(userInputs));
  }

  checkControlCondition(control: JsonObjects.Control, isOnInit: boolean) {//בדיקת תנאי פקד בודד
    this.isControlsConditionTrue[control.ControlId + this.idExtension] = this.conditionalLogic.checkCondition(false,control.ControlId + this.idExtension, control.ConditionalLogicGroup, isOnInit, this.idExtension, this.currentSection.SectionNum);
    if (this.isControlsConditionTrue[control.ControlId + this.idExtension])//התנאי התקיים אז תוסיף פקד לטופס
    {
      if (this.userForm.controls[control.ControlId + '' + this.idExtension].disabled) {
        let input: JsonObjects.Input = control
        if (input == null || input.ReadOnly == false)//disabled פקד לקריאה בלבד יש להשאיר אותו כ        
          setTimeout(() => { this.ngRedux.dispatch(this.actions.actions.enableControlForm(control.ControlId + this.idExtension)) }, 1)//הוספת פקד
      }
    }
    else
      if (this.userForm.controls[control.ControlId + '' + this.idExtension].enabled)
        setTimeout(() => { this.ngRedux.dispatch(this.actions.actions.disableControlForm(control.ControlId + '' + this.idExtension)) }, 1)//מחיקת פקד    
  }
}