

//namespace a{
 
    
    export class Bank {
       
Code? :string;
Name? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










