

//namespace a{
 
    
    export class ComparingControlValueAmount {
       
Operator? :string;
Amount? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










