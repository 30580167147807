import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as JsonObjects from '../../classes'
import { CustomValidation } from '../../custom-validation';
import { CustomString } from '../../custom-string';
import { distinctUntilChanged } from 'rxjs/operators';
import { LevelSectionService } from '../../services/levelSection.service';
import { ChangeDetectorRef } from '@angular/core';


@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit, OnDestroy ,AfterViewInit{

    @Input() input: JsonObjects.Options;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש
    
    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק
    classList: string[] = [];//קלאסים של הפקד הנוכחי

    private subscription: Subscription = new Subscription();

    constructor(private cdRef:ChangeDetectorRef,private customValidation: CustomValidation, private levelSectionService: LevelSectionService, private customString: CustomString) {
    }

    ngOnInit() {
        this.classList = this.input.Class ? this.input.Class.split(' ') : [];//שליפת הקלאסים של הפקד
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק 
    }

    ngAfterViewInit() {
        this.onValueChangesOptions( this.userForm.controls[this.input.ControlId+this.idExtension].value,true);
        this.subscription.add(this.userForm.controls[this.input.ControlId+this.idExtension].valueChanges.pipe(distinctUntilChanged()).subscribe(//תאזין לכל שינוי טקסט של הפקד שתלוי בו
            (optionSelectName) => {this.onValueChangesOptions(optionSelectName,false);}));
        if(this.isClassExists('radio-risk-profile'))
            this.handleChangeStyle()
         setTimeout(()=>{
            this.customString.execCode(this.input.ControlId+this.idExtension);
             })     
            this.cdRef.detectChanges();     
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isClassExists(isClass: string = '') {
        return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
    }

    onValueChangesOptions(optionSelectName:string,isinit:boolean){//הכנסת האזנות או מחיקת האזנות בעת בחירה רדיו באטן
        this.input.InputOptions.forEach((option:JsonObjects.Option)=>{
            if(option.OptionName==optionSelectName)
                this.updateValidatorsControlPointed(option,true,isinit)//הוספת ולידשן
            else
                this.updateValidatorsControlPointed(option,false,isinit)//הורדת ולידשן
        })
    }

    updateValidatorsControlPointed(option:JsonObjects.Option,isSelectOption:boolean,isinit){
        if(option.ControlNumPointed)
        {
          let ControlPointed=this.levelSectionService.SectionArray[this.currentComponent.Num].Controls.filter(co=>co.Pointed==true).find(c=>c.ControlId==option.ControlNumPointed); 
            if(ControlPointed instanceof JsonObjects.Input) 
            {
                if(isSelectOption)   
                    this.userForm.controls[ControlPointed.ControlId + this.idExtension].setValidators(ControlPointed.Validators ? this.customValidation.InsertValidation(this.userForm.controls[ControlPointed.ControlId + this.idExtension],ControlPointed) as [] : []);
                else
                    {                        
                    this.userForm.controls[ControlPointed.ControlId + this.idExtension].clearValidators();
                         if(isinit==false)
                          this.userForm.controls[ControlPointed.ControlId + this.idExtension].setValue("") ;//מחיקת ערך של פקד מקונן בתוך אופשן כאשר בחרו אופשן אחר 
                    }
                this.userForm.controls[ControlPointed.ControlId + this.idExtension].updateValueAndValidity();    
            }                          
        }    
    }

    handleChangeStyle() {
            $("div :has(>.radio_rectangle:has(input:checked)) ").removeClass("disableNotChecked")
            $("div :has(>.radio_rectangle:has(input:checked))").siblings("div").addClass("disableNotChecked")
      }
}