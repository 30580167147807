

//namespace a{
 
    import {Control} from './Control' 
    export class Notification extends Control {
       
Message? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










