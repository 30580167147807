import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../controls/dialog/dialog.component';
import { ConditionalLogic } from '../../services/conditionalLogic.service';
import * as JsonObjects from '../../classes';
import { ResponsiveService } from '../../services/resonsive.service';
import { FormComponent } from '../../structureObject';
@Component({
  selector: 'app-partial-header',
  templateUrl: './partial-header.component.html',
  styleUrls: ['./partial-header.component.scss']
})
export class PartialHeaderComponent implements OnInit {

  @Input() currentProcess: JsonObjects.Process;//הפקד הנוכחי
  @Input() menu_page: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
  get eDevice(){
    return FormComponent.eDevice;
  }
  get device(){
    return this.responsiveService.device;
  }
  constructor(private responsiveService:ResponsiveService,public dialog: MatDialog,private conditionalLogic: ConditionalLogic) {
  }

  ngOnInit() {
  }

  openContact() {
    //הדלקת ולידציות של הפקדים
    this.conditionalLogic.enableControlFormSection(this.currentProcess.Contact.Controls, '');
    $('#menu .form-tallk-header').addClass('d-none');
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '700px',
      data:
      {
        Type:'TalkWithAs',        
        Header: this.currentProcess.Contact.ContactHeader,
        Controls: this.currentProcess.Contact.Controls,
        Component: this.menu_page
      }

    });

    dialogRef.afterClosed().subscribe(result => {
      $('#menu .form-tallk-header').removeClass('d-none');
      this.conditionalLogic.disableControlFormSection(this.currentProcess.Contact.Controls, '');
      // const datailsEmail:JsonObjects.ControlDatailesEmail[]= this.currentProcess.Contact.Controls.map(c=>{ return  <JsonObjects.ControlDatailesEmail>{id:c.ControlId,Label: c.Label,value:this.currentProcess.Contact.Controls[c.ControlId].value}
      //   this.api.sendEmail(datailsEmail);

      // });
    });

    
  }
  openOtp() {
    //הדלקת ולידציות של הפקדים
    $('.otp').addClass('d-none');
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '700px',
      data:
      {
        Type:'otpDialog',        
        Header: this.currentProcess.Contact.ContactHeader,
        Controls: this.currentProcess.Contact.Controls,
        Component: this.menu_page
      }

    });

    dialogRef.afterClosed().subscribe(result => {
      $('.otp').removeClass('d-none');
      this.conditionalLogic.disableControlFormSection(this.currentProcess.Contact.Controls, '');
      
    });
  }
}
