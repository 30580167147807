import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  
  export class TooltipComponent implements OnInit
  {    
    @Input() tooltipContent;//הפקד הנוכחי
    ngOnInit() {
    }

  }