

//namespace a{
 
    import {Input} from './Input' 
import {SectionControl} from './SectionControl' 
    export class Autocomplete extends Input {
       
TypeXML? :string;
DependsControl? :SectionControl;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










