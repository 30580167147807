

//namespace a{
 
    import {Section} from './Section' 
    export class TextSection extends Section {
       
HtmlContent? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










