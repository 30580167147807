<mat-form-field *ngIf="userForm" class="full-width" appearance="outline" [floatLabel]="input.Label">
    <mat-label>{{input.Label}} <span class="label-required" *ngIf='input.Validators && input.Validators.Required'>
            *</span> </mat-label>
    <input matInput [id]="input.ControlId+idExtension" [matAutocomplete]="auto"
        [formControl]="userForm.get(input.ControlId+idExtension)" type="text" (keydown)="onKeyDown($event)" />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    <mat-error>{{validation(userForm.controls[input.ControlId+idExtension])}}</mat-error>
    <span><img class="expansion_indicator" src="./assets/img-lg/expansion_indicator.svg"
            alt="לחץ לבחירת אפשריות נוספות" />
    </span>

</mat-form-field>