import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EscapeHtmlPipe } from './shared/pipes/keep-html.pipe';
import { NumberFormatPipe } from './shared/pipes/numberFormat.pipe';
import { SizeFieldPipe } from './shared/pipes/SizeField.Pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/Select'
import { MatCheckboxModule } from '@angular/material/Checkbox'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/Radio'
import { MatInputModule } from '@angular/material/Input'
import { MatStepperModule } from '@angular/material/Stepper'
import { MatDatepickerModule } from '@angular/material/Datepicker'
import { MatAutocompleteModule } from '@angular/material/Autocomplete'
import { MatExpansionModule } from '@angular/material/Expansion'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { LayoutModule } from '@angular/cdk/layout';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';//עיצוב מיקום תאריך
import { DateAdapter, ErrorStateMatcher, MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_LABEL_GLOBAL_OPTIONS, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';//פורמט תאריך
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatDialogModule } from '@angular/material/dialog';

import { NgReduxModule, NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, IAppState, INITIAL_STATE } from '../store/reducer';
import { NgReduxRouterModule, NgReduxRouter } from '@angular-redux/router';
import { CounterActions } from '../store/actions';

import { appRoutes } from './app.rooting.module';
import { AppComponent } from './app.component';
import { menuComponent } from './menu/menu.component';
import { LevelComponent } from './menu/level/level.component';
import { SectionComponent } from './menu/level/section/section.component';
import { TextSectionComponent } from './menu/level/textSection/textSection.component';
import { DialogComponent } from './shared/controls/dialog/dialog.component'

import { DirectAccessGuard } from './shared/services/directAccessGuard.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';

import { AutocompleteComponent } from './shared/controls/autocomplete/autocomplete.component';
import { ButtonComponent } from './shared/controls/button/button.component';
import { DateComponent } from './shared/controls/date/date.component';
import { SignaturePadComponent } from './shared/controls/signature-pad/signature_pad.component';
import { NotificationCustomerContactInfoComponent } from './shared/controls/notification/customerContactInfo/notificationCustomerContactInfo.component';
import { SelectComponent } from './shared/controls/select/select.component';
import { RadioComponent } from './shared/controls/radio/radio.component';
import { TooltipComponent } from './shared/controls/tooltip/tooltip.component';
import { CheckboxComponent } from './shared/controls/checkbox/checkbox.component';
import { FileComponent } from './shared/controls/uploadFile/uploadFile.component';
import { InputComponent } from './shared/controls/input/input.component';
import { HeaderComponent } from './shared/controls/header/header.component';
import { TextControlComponent } from './shared/controls/text-control/textControl.component';
import { ChartComponent } from './shared/controls/chart/chart.component';
import { ButtonRefLevelComponent } from './shared/controls/buttonRefLevel/buttonRefLevel.component';
import { RefSectionComponent } from './shared/controls/ref-section/ref-section.component';

import { ErrorPageComponent } from './error-page/error-page.component';
import { DatePipe } from '@angular/common';
import { PartialHeaderComponent } from './shared/components/partial-header/partial-header.component';
import { ControlFactoryComponent } from './shared/directive/control-factory.directive';
import { OptionComponent } from './shared/controls/option/option.component';
import { NotificationInfoComponent } from './shared/controls/notification/Info/notification-info/notification-info.component';
import { ResponsiveService } from './shared/services/resonsive.service';
import { BtnDialogComponent } from './shared/controls/dialog/btn-dialog/btn-dialog.component';
import { AcceptFileDirective } from './shared/directive/accept.file.directive';
import { MinLengthDirective } from './shared/directive/minlength.directive';
import { MaxLengthDirective } from './shared/directive/maxlength.directive';
import { HowItWorkComponent } from './shared/controls/how-it-work/how-it-work.component';
import { ResendSMSComponent } from './resend-sms/resend-sms.component';
import { OtpPopupComponent } from './shared/controls/otp-popup/otp-popup.component';
import { AuthInterceptor } from './auth.interceptor';

@NgModule({
  declarations: [
    ControlFactoryComponent,
    EscapeHtmlPipe,
    NumberFormatPipe,
    SizeFieldPipe,
    AppComponent,
    menuComponent,
    LevelComponent,
    SectionComponent,
    TextSectionComponent,
    InputComponent,
    HeaderComponent,
    TextControlComponent,
    FileComponent,
    CheckboxComponent,
    RadioComponent,
    TooltipComponent,
    SelectComponent,
    AutocompleteComponent,
    ButtonComponent,
    DateComponent,
    SignaturePadComponent,
    NotificationCustomerContactInfoComponent,
    ChartComponent,
    ButtonRefLevelComponent,
    RefSectionComponent,
    ErrorPageComponent,
    DialogComponent,
    PartialHeaderComponent,
    OptionComponent,
    NotificationInfoComponent,
    BtnDialogComponent,
    AcceptFileDirective,
    MaxLengthDirective,
    MinLengthDirective,
    HowItWorkComponent,
    ResendSMSComponent,
    OtpPopupComponent
  ],
  imports: [
    MaterialFileInputModule,
    appRoutes,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgReduxRouterModule.forRoot(),
    NgReduxModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatStepperModule,
    MatRippleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule, MatExpansionModule,
    MatButtonToggleModule,
    LayoutModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],

  providers: [
    ResponsiveService,
    DatePipe,
    ErrorHandlerService,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } },
    DirectAccessGuard, CounterActions, NgReduxRouter,
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'always' } },
    { provide: MAT_DATE_LOCALE, useValue: 'he' },//פורמט מיקום עיברית
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]//פורמט תאריך
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },//פורמט תאריך
    NumberFormatPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    FileComponent,
    InputComponent,
    CheckboxComponent,
    RadioComponent,
    SelectComponent,
    ButtonComponent,
    AutocompleteComponent,
    DateComponent,
    HeaderComponent,
    TextControlComponent,
    ChartComponent,
    RefSectionComponent,
    SignaturePadComponent
    , SectionComponent, TextSectionComponent, LevelComponent, NotificationCustomerContactInfoComponent, DialogComponent],
  exports: [
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(ngReduxRouter: NgReduxRouter, ngRedux: NgRedux<IAppState>, devTools: DevToolsExtension) {
    let enhancers = [];
    if (devTools.isEnabled()) {
      enhancers.push(devTools.enhancer());
    }
    ngRedux.configureStore(rootReducer, INITIAL_STATE, [], enhancers)
    if (ngReduxRouter)
      ngReduxRouter.initialize();
  }
}


