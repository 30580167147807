import SignaturePad from 'signature_pad';
import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { CustomValidation } from '../../custom-validation';
import * as JsonObjects from '../../classes';

@Component({
    selector: 'app-signature-pad',
    templateUrl: './signature_pad.component.html',
    styleUrls: ['./signature_pad.component.scss']
})
export class SignaturePadComponent implements OnInit, AfterViewInit {//חתימה דיגיטלית

    @Input() input: JsonObjects.Text;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    signaturePad: SignaturePad;//אוביקט חתימה דיגיטלית
    idExtension: string;//מזהה עותק
    public file:File[];
    constructor(private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הכנסת מזהה עותק
    }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(document.querySelector(`canvas[id="${this.input.ControlId + this.idExtension}"]`));//יצירת החתימה דיגיטלית בקנווס
        var controlValue = this.userForm.controls[this.input.ControlId + this.idExtension].value;//קבלת ערך הפקד- ציור החתימה אחרי שהוכנס אליו מהרידקס

        if (controlValue != null && controlValue != "" )
        if(controlValue.length==1)
            this.file=controlValue;
        else
            this.signaturePad.fromDataURL(controlValue);//אם יש ערך אז הכנס לחתימה 
        this.cd.detectChanges();//מעדכן תצוגה
    }

    clearButton() {//כפתור ניקוי חתימה
        this.file=undefined;
        this.signaturePad.clear();//ניקוי חתימה
        this.userForm.controls[this.input.ControlId + this.idExtension].setValue('');
    }

    onmousedown(){
        this.file=undefined;
    }
    onMouseup() {
        if (this.signaturePad.toData().length)
            this.userForm.controls[this.input.ControlId + this.idExtension].setValue(this.signaturePad.toDataURL());
    }
    fileChangeEvent(files: File[], currentInputId: string) {
        this.file=files
        this.userForm.controls[this.input.ControlId + this.idExtension].setValue(files);
        this.signaturePad = new SignaturePad(document.querySelector(`canvas[id="${this.input.ControlId + this.idExtension}"]`));//יצירת החתימה דיגיטלית בקנווס
    }
}