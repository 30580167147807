<ng-container [ngSwitch]="isClassExists('text-completion-select')">
  <ng-template [ngSwitchCase]="true">
      <span class="text-completion-select">
          <mat-label></mat-label>
      <label>
          <input type="text"
                 size="30"
                 [tabindex]="input.Pointed?-1:0"
                 [placeholder]="input.Label"
                 aria-label="Number"
                 matInput
                 [formControl]="userForm.get(input.ControlId+idExtension)"
                 [id]="input.ControlId+idExtension"
                 [matAutocomplete]="auto">
                 <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      </label>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of input.InputOptions"  [value]="option.Label">
           {{option.Label}}
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            <div class="text-completion-error" *ngIf="userForm.get(input.ControlId+idExtension).invalid">{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
          </mat-error>
        </span>
  </ng-template>
  <ng-template ngSwitchDefault>
    
    <mat-form-field class="full-width" appearance="outline" [floatLabel]="input.Label">
  <mat-label><span [innerHtml]="input.Label|keepHtml"></span><span class="label-required" *ngIf='input.Validators && input.Validators.Required'> *</span></mat-label>
  <mat-select [formControl]="userForm.get(input.ControlId+idExtension)" [id]="input.ControlId+idExtension">
    <mat-option *ngFor="let option of input.InputOptions" [value]="option.OptionName" [innerHtml]="option.Label|keepHtml"></mat-option>
  </mat-select>
  <mat-error>
    <div>{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
  </mat-error>
</mat-form-field>
  </ng-template>
</ng-container>
