

//namespace a{
 
    import {ProcessFiles} from './ProcessFiles' 
    export class ProcessFilesMenu {
       
Num? :string;
ProcessFiles? :ProcessFiles[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










