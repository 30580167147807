import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import * as JsonObjects from '../classes';
@Directive({
  selector: 'input[maxNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MaxLengthDirective
    }
  ]
})
export class MaxLengthDirective implements Validator {
  @Input('maxNumber')
  input: JsonObjects.Input ;

  constructor() { }
  validate(control: AbstractControl): ValidationErrors | null {
    if(this.input instanceof  JsonObjects.Text)
    if ((this.input as JsonObjects.Text).ValueType == "number" && this.input.Validators instanceof  JsonObjects.InputExpandValidation ) {
    let  maxNum= (this.input.Validators as  JsonObjects.InputExpandValidation ).Maxlength
    if (maxNum != undefined) {
      let maxNumNumber: string = maxNum.toString().replace(/\D/g, '');
      if (!control.value)
        return null;
      let value = control.value.toString().replace(/\D/g, '');//הורדת פסיקים	

      if (Number(value) > Number(maxNumNumber))//אם ערך הפקד גדול מהמקסימום
        return { 'max': { 'max': maxNumNumber } };//לא חוקי		
        else
        return null;
    }
    return null;
  }

}

}