
import { FormControl, Validators, AbstractControl, FormGroup, ValidatorFn, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AutocompleteService } from './services/autocomplete.service';
import { Injectable } from '@angular/core';
import { UserValueEnterrd } from './services/user-value-entered.service';
import * as JsonObjects from './classes';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store/reducer';
import { ApiService } from './services/api.service';
import SignaturePad from 'signature_pad';
import { Observable } from 'rxjs';

function isEmptyInputValue(value: any): boolean {
	// we don't check for string here so it also works with arrays
	return value === null || value.length === 0;
}

@Injectable({ providedIn: 'root' })
export class CustomValidation {
	userForm: FormGroup;//טופס משתמש
	regexGenarl = /[^א-ת0-9A-Za-z .()|\\,"'_\\-]/;

	//ההבדל בין בדיקת תקינות של ת.ז או תאריך לבין תקינות של שרת ששרת אני מעוניינת שירד ברגע ששינה את הטקסט שזוהה כשגוי מן השרת ולא לפנות לשרת כל שניה
	// ואילו ת.ז זה כמו בדיקת תקינות רגילה
	public static errorMessages: { [key: string]: string }//הודעות שגיאה מהשרת
	//public static $typesValidetion: Observable<JsonObjects.TypesValidetion>;//הודעות שגיאה מהשרת

	constructor(private autocompleteService: AutocompleteService, private userValueService: UserValueEnterrd, private ngRedux: NgRedux<IAppState>, public apiService: ApiService) {
		this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => { this.userForm = userForm });//שליפת טופס משתמש   
		this.setErrorMessages();
	}
	getErrorMessages() {
		return CustomValidation.errorMessages;
	}
	setErrorMessages() {
		this.apiService.getErrorMessages()
			.toPromise().then((response) => {
				CustomValidation.errorMessages = <{ [key: string]: string }>response;
			});
	}
	//בדיקת תקינות ת.ז
	static IDnumberValidator(validatorField: { [key: string]: boolean }): ValidatorFn {
		return (c: AbstractControl): { [key: string]: boolean } | null => {
			if (c.value !== null && !CustomValidation.isIsraeliIdValid(c.value)) {
				return validatorField;
			}

			return null;
		};
	}
	//בדיקת תקינות תאריך גדול מהיום
	static FutureDateValidator(validatorField: { [key: string]: boolean }): ValidatorFn {
		return (c: AbstractControl): { [key: string]: boolean } | null => {
			if (c.value !== null && !CustomValidation.isFutureDate(c.value)) {
				return validatorField;
			}
			return null;
		};
	}
	//השמת שגיאת שרת
	static serverValidator(control: FormControl): object {
		return {
			server: {
				value: control.value
			}
		}
	}
	static isFutureDate(date) {////בדיקת תקינות תאריך גדול מהיום
		if (date < new Date())
			return false;
		return true;
	};
	static isIsraeliIdValid(id) {//בדיקת תעודה זהות ישראלית
		let strId = String(id).trim();
		if (strId.length > 9) {
			return false;
		}
		if (strId.length < 9) {
			// 	while (strId.length < 9) strId = "0" + strId;//השלמת אפסים
			return false;
		}
		if (strId == '000000000') {
			return false;
		}
		let counter = 0, rawVal, actualVal;
		for (let i = 0; i < strId.length; i++) {
			rawVal = Number(strId[i]) * ((i % 2) + 1);
			actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
			counter += actualVal;
		}
		return (counter % 10 === 0);
	};

	static requiredSignature(validatorField: { [key: string]: boolean }): ValidatorFn {//בדיקת שדה חובה לפקד checkbox			
		return (control: FormGroup): { [key: string]: boolean } | null => {
			if (control.value != "")
				return null;
			return validatorField;//ולא, לא חוקי
		}
	}
	static minlengthSignature(validatorField: { [key: string]: boolean }): ValidatorFn {//בדיקת שדה חובה לפקד checkbox			
		return (control: FormGroup): { [key: string]: boolean } | null => {
			if (control.value != "" && !control.value.isEmpty()) {
				let signature: SignaturePad = control.value;
				const points = signature.toData();
				let countpoints = 0
				const pointCount = [].concat.apply([], points).length;
				points.forEach(element => {
					countpoints = countpoints + element.points.length;
				});
				if (countpoints > 5)//יש יותר מחמש תווים בחתימה
					return null;
			}
			return validatorField;//ולא, לא חוקי
		}
	}
	static requiredCheckbox(validatorField: { [key: string]: boolean }): ValidatorFn {//בדיקת שדה חובה לפקד checkbox
		return (control: FormGroup): { [key: string]: boolean } | null => {
			if (!control.controls)//אם אין אפשרויות אין שדה חובה
				return null;
			for (let key in control.controls) {//עובר על כל האפשרויות
				if (control.controls[key].value == true)//אם משהוא מסומן אז הפקד חוקי
					return null
			}
			return validatorField;//ולא, לא חוקי
		}
	}


	InsertValidation = (control, formControl: JsonObjects.Input): [] => {
		let Validator: JsonObjects.RequiredValidation = {}
		Validator = formControl.Validators;
		let ContainerValidation: Validators[] = [];
		if (formControl.ControlName == 'AmountFixedDepositAMonth')
			console.log(control, formControl)
		if (formControl.ControlType == "signature") {
			ContainerValidation.push(CustomValidation.requiredSignature({ 'requiredSignature': true }));
		}
		if (formControl.ControlName.startsWith("IDnumber")) {
			ContainerValidation.push(CustomValidation.IDnumberValidator({ 'IDnumber': true }));
		}
		if (formControl.ControlName == "firstDeposit") {
			ContainerValidation.push(CustomValidation.FutureDateValidator({ 'FutureDate': true }));
		}
		if (Validator.Server != null)
			ContainerValidation.push(CustomValidation.serverValidator);

		if (Validator.Required != false) {
			if (formControl.ControlType == "checkbox")//אם סוג הפקד checkbox
				ContainerValidation.push(CustomValidation.requiredCheckbox({ 'required': true }));//שדה חובה ל checkbox
			else {
				ContainerValidation.push(Validators.required);
			}
		}
		if (Validator instanceof JsonObjects.InputExpandValidation) {
			let inputValidator: JsonObjects.InputExpandValidation = Validator as JsonObjects.InputExpandValidation;
			let textControl: JsonObjects.Text = formControl as JsonObjects.Text;

			if (textControl.ValueType == "email") //ולידציה לאימייל
				ContainerValidation.push(Validators.email);

			if (textControl.ValueType != "number") {//ולידציה למספרים			

				if (inputValidator.Minlength != null)
					ContainerValidation.push(Validators.minLength(Number.parseInt(inputValidator.Minlength)));//מינימום אורך תווים

				if (inputValidator.Maxlength != null)
					ContainerValidation.push(Validators.maxLength(Number.parseInt(inputValidator.Maxlength)));//מקסימום אורך תווים
			}

			if (inputValidator.Pattern)
				ContainerValidation.push(Validators.pattern(inputValidator.Pattern));
			else {
				let validation = (<JsonObjects.TypeInput[]>this.apiService.typesValidation$.value).find(type => type.Value == textControl.ValueType);
				if (validation != undefined) {
					let pattern = validation.Pattern;
					ContainerValidation.push(Validators.pattern(pattern));
				}
			}
			if (inputValidator.ComparingValueAmount != null && inputValidator.ComparingValueAmount.Amount != null)
				ContainerValidation.push(this.ValidateComparingControlValueAmount({
					'valueAmount': {
						operator: inputValidator.ComparingValueAmount.Operator,
						amount: inputValidator.ComparingValueAmount.Amount
					}
				}, inputValidator.ComparingValueAmount
					, formControl.ControlId))
		}
		if (Validator instanceof JsonObjects.DateExpandValidation)//ולידציה לתאריך
		{
			let dateValidator: JsonObjects.DateExpandValidation = Validator as JsonObjects.DateExpandValidation;
			if (dateValidator.Maxdate)//בדיקת תאריך מקסימלי
				ContainerValidation.push(this.ValidateMaxDate({ 'maxDate': { 'requiredLength': dateValidator.Maxdate } }, dateValidator.Maxdate));
			if (dateValidator.Mindate)//בדיקת תאריך מינימלי
				ContainerValidation.push(this.ValidateMinDate({ 'minDate': { 'requiredLength': dateValidator.Mindate } }, dateValidator.Mindate));
			if (dateValidator.Minage)//בדיקת גיל מינימלי
				ContainerValidation.push(this.ValidateMinAge({ 'minAge': { 'requiredLength': dateValidator.Minage } }, dateValidator.Minage));
			if (dateValidator.Maxage)//max age for minor
				ContainerValidation.push(this.ValidateMaxAge({ 'maxAge': { 'requiredLength': dateValidator.Maxage } }, dateValidator.Maxage));
			
		}
		return ContainerValidation as [];
	}

	//בדיקת תאריך מקסימלי
	ValidateMaxDate(validatorField: { [key: string]: { [key: string]: string } }, maxDate: string): ValidatorFn {
		var dateParts = maxDate.split("/");
		// month is 0-based, that's why we need dataParts[1] - 1
		var maxDateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

		return (control: AbstractControl): { [key: string]: { [key: string]: string } } | null => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return null;
			return maxDateObject.getTime() >= control.value._d.getTime() ? null : validatorField;

		}
	}

	//בדיקת תאריך מינימלי
	ValidateMinDate(validatorField: { [key: string]: { [key: string]: string } }, minDate: string): ValidatorFn {
		var dateParts = minDate.split("/");
		var minDateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

		return (control: AbstractControl): { [key: string]: { [key: string]: string } } | null => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return null;
			return control.value._d.getTime() >= minDateObject.getTime() ? null : validatorField;

		}
	}

	//בדיקת גיל מינימלי
	ValidateMinAge(validatorField: { [key: string]: { [key: string]: string } }, minAge: string): ValidatorFn {

		return (control: AbstractControl): { [key: string]: { [key: string]: string } } | null => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return null;

			var today = new Date();
			var birthDate = new Date(control.value._d ? control.value._d : control.value);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age >= Number.parseInt(minAge) ? null : validatorField;

		}
	}
	ValidateMaxAge(validatorField: { [key: string]: { [key: string]: string } }, maxAge: string): ValidatorFn {

		return (control: AbstractControl): { [key: string]: { [key: string]: string } } | null => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return null;

			var today = new Date();
			var birthDate = new Date(control.value._d ? control.value._d : control.value);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age <= Number.parseInt(maxAge) ? null : validatorField;

		}
	}

	//בדיקת תקינות ישוב
	ValidateSettlement(validatorField: { [key: string]: boolean }): AsyncValidatorFn {
		return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return Promise.resolve(undefined);
			return this.autocompleteService.IsExistsSettlements(control.value).toPromise().then(isExistsSetttlememt => {
				console.log(isExistsSetttlememt)
				if (!isExistsSetttlememt  || this.regexGenarl.test(control.value))
					return validatorField;
				return null;
			});

		}
	}

	//בדיקת תקינות רחוב
	ValidateStreet(validatorField: { [key: string]: boolean }, formControl: JsonObjects.Autocomplete, formControls: {}, idExtension: string): AsyncValidatorFn {
		var dependentControl: AbstractControl, valueDependentControl = '';
		if (formControls != null) {//אם קיימים פקדים בטופס
			dependentControl = formControls[formControl.DependsControl.Field + idExtension];//שליפת הפקד התלוי מהטופס
			if (dependentControl == null)//אם הפקד אינו באותו שלב
				//שליפת הערך שלו מהרידקס
				valueDependentControl = this.userValueService.selectUserValueEntered({ sectionId: formControl.DependsControl.Section, fieldId: formControl.DependsControl.Field })
		}
		//בדיקת העיר והרחוב
		return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return Promise.resolve(undefined);
			return this.autocompleteService.IsExistsStreets(control.value, dependentControl ? dependentControl.value : valueDependentControl).toPromise().then(isExistsStreet => {
				if (!isExistsStreet  || this.regexGenarl.test(control.value))
					return validatorField;
				return null;
			});

		}
	}

		//בדיקת תקינות בנק
		Validatebank(validatorField: { [key: string]: boolean }): AsyncValidatorFn {
			return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
				if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
					return Promise.resolve(undefined);
				return this.autocompleteService.IsExistsBanks(control.value).toPromise().then(IsExistsbank => {
					console.log(IsExistsbank)
					if (!IsExistsbank || this.regexGenarl.test(control.value))
						return validatorField;
					return null;
				});
	
			}
		}

		ValidateBankBranches(validatorField: { [key: string]: boolean }, formControl: JsonObjects.Autocomplete, formControls: {}, idExtension: string): AsyncValidatorFn {
			var dependentControl: AbstractControl, valueDependentControl = '';
			if (formControls != null) {//אם קיימים פקדים בטופס
				dependentControl = formControls[formControl.DependsControl.Field + idExtension];//שליפת הפקד התלוי מהטופס
				if (dependentControl == null)//אם הפקד אינו באותו שלב
					//שליפת הערך שלו מהרידקס
					valueDependentControl = this.userValueService.selectUserValueEntered({ sectionId: formControl.DependsControl.Section, fieldId: formControl.DependsControl.Field })
			}
			//בדיקת בנק וסניף
			return (control: AbstractControl): Promise<{ [key: string]: boolean } | null> => {
				if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
					return Promise.resolve(undefined);
				return this.autocompleteService.IsExistsBankBranchs(control.value, dependentControl ? dependentControl.value : valueDependentControl).toPromise().then(IsExistsBankBranch => {
					if (!IsExistsBankBranch  || this.regexGenarl.test(control.value))
						return validatorField;
					return null;
				});
	
			}
		}

	//בדיקת סכימת ערך פקד מוכפל
	ValidateComparingControlValueAmount(validatorField: { [key: string]: { operator: string, amount: string } },
		comparingControl: JsonObjects.ComparingControlValueAmount, controlId: string): ValidatorFn {
		return (control: AbstractControl): { [key: string]: { operator: string, amount: string } } | null => {
			if (isEmptyInputValue(control.value))//בדיקה אם הערך אינו ריק 
				return null;
			var comparingKeyControls: string[];//רשימת הפקדים המוכפלים למזהה
			comparingKeyControls = Object.keys(this.userForm.controls).filter(key => { return key.startsWith(controlId) });//שליפת כל הפקדים המוכפלים
			//סכימת ערכם
			var controlsValueAmout = 0;
			comparingKeyControls.forEach(key => controlsValueAmout += isEmptyInputValue(this.userForm.controls[key].value) ? 0 : parseInt(this.userForm.controls[key].value));
			//השוואת הסכום 
			var resultComparing = this.isComparingControlValueAmountValid(comparingControl, controlsValueAmout);

			//עדכון סטטוס ולידציות לפקדים אחרים
			comparingKeyControls.forEach(key => {
				if (resultComparing)//הערכים חוקיים
					this.userForm.controls[key].setErrors(null)
				else
					this.userForm.controls[key].setErrors(validatorField)
			})

			return resultComparing ? null :
				validatorField;//ולא, שלח לא חוקי
		}
	}

	isComparingControlValueAmountValid(comparingControl: JsonObjects.ComparingControlValueAmount, controlsValueAmout: number): boolean {
		if (comparingControl.Operator == "EqualTo")
			return controlsValueAmout == parseInt(comparingControl.Amount);
		if (comparingControl.Operator == "SmallerThan")
			return controlsValueAmout < parseInt(comparingControl.Amount);
		if (comparingControl.Operator == "BiggerThan")
			return controlsValueAmout > parseInt(comparingControl.Amount);
		if (comparingControl.Operator == "NotEqualTo")
			return controlsValueAmout != parseInt(comparingControl.Amount);
		if (comparingControl.Operator == "Blank")
			return (controlsValueAmout == 0);
		if (comparingControl.Operator == "NotBlank")
			return (controlsValueAmout != 0);
		return true
	}

	InsertAsyncValidation = (control, formControl: JsonObjects.Input, formControls: {}, idExtension: string): AsyncValidatorFn[] => {
		let ContainerValidation: AsyncValidatorFn[] = [];
		if (formControl.ControlType == "autocomplete") {
			let autocompleteControl: JsonObjects.Autocomplete = formControl as JsonObjects.Autocomplete;
			if (autocompleteControl.TypeXML == "banks") {
				ContainerValidation.push(this.Validatebank({ bank: true }));
			}
			if (autocompleteControl.TypeXML == "bankBranches") {
				ContainerValidation.push(this.ValidateBankBranches({ bankBranche: true }, autocompleteControl, formControls, idExtension));
			}
			if (autocompleteControl.TypeXML == "settlements") {
				ContainerValidation.push(this.ValidateSettlement({ settlement: true }));
			}
			else if (autocompleteControl.TypeXML == "streets") {
				ContainerValidation.push(this.ValidateStreet({ street: true }, autocompleteControl, formControls, idExtension));
			}
		}
		return ContainerValidation;
	}
	//בעת חזרה משרת עם שגיאה לשדה כלשהו
	//בודק איזה שדה אינו חוקי ב rootForm
	findInvalidControls = (rootForm, mainControls) => {
		const invalid = [];
		const controls = rootForm.controls;
		for (const name in controls) {
			if (controls[name].invalid) {
				let a = mainControls.find(p => p.ControlId == name).Label
				invalid.push(a);
			}
		}
		return invalid;
	}
	//הוספת שגיאה של שרת
	addValidatorServer = (control: FormControl, message: string, formControl: JsonObjects.Input) => {
		formControl.Validators.Server = "true";
		CustomValidation.errorMessages.server = message;
		control = this.addValidator(control, 'Server', formControl);
		return control
	}
	addValidator = (control: FormControl, typeError: string, formControl: JsonObjects.Input) => {
		let Validation: [] = this.InsertValidation(control, formControl);
		control.setValidators(Validation);
		control.updateValueAndValidity();
		control.markAsDirty();
		let isinit: boolean = true;
		//נועד להסרת של הבדיקת תקינות ברגע ששינו את הטקסט
		let controlValueChanges = control.valueChanges.subscribe((value) => {

			if (isinit == true) { //נכנס בעת יצירת השדה		
				isinit = false;
			}
			else {//נכנס בעת שינוי השדה			
				if (typeError == "Server") {
					controlValueChanges.unsubscribe();
					formControl.Validators.Server = null;
					let Validation = this.InsertValidation(control, formControl);
					control.setValidators(Validation);
					control.updateValueAndValidity();
				}
			}
		});
		return control;
	}

	static validation = (control: AbstractControl) => {

		var errors = control.errors
		if (errors && CustomValidation.errorMessages) {
			var regex = /@str@/gi;
			if (errors.requiredSignature != null)
				return CustomValidation.errorMessages.requiredSignature
			if (errors.minlengthSignature != null)
				return CustomValidation.errorMessages.minlengthSignature
			if (errors.server != null)
				return CustomValidation.errorMessages.server
			if (errors.dateTime != null)
				return CustomValidation.errorMessages.dateTime
			if (errors.IDnumber != null)
				return CustomValidation.errorMessages.IDnumber
			if (errors.bank != null)
				return CustomValidation.errorMessages.bank
			if (errors.bankBranche != null)
				return CustomValidation.errorMessages.bankBranche
			if (errors.FutureDate != null)
				return CustomValidation.errorMessages.minDate.replace(regex, new Date().toLocaleDateString('he'));
				//console.log(errors.FutureDate)
			// return CustomValidation.errorMessages.dateTime
			if (errors.matDatepickerParse)
				return CustomValidation.errorMessages.invalidType;
			if (errors.required == true)
				return CustomValidation.errorMessages.required;
			if (errors.maxlength != null)
				return CustomValidation.errorMessages.maxlength.replace(regex, errors.maxlength.requiredLength);
			if (errors.minlength != null)
				return CustomValidation.errorMessages.minlength.replace(regex, errors.minlength.requiredLength);
			if (errors.max != null)
				return CustomValidation.errorMessages.max.replace(regex, errors.max.max);
			if (errors.min != null)
				return CustomValidation.errorMessages.min.replace(regex, errors.min.min);
			if (errors.maxDate != null)
				return CustomValidation.errorMessages.maxDate.replace(regex, errors.maxDate.requiredLength);
			if (errors.minDate != null)
				return CustomValidation.errorMessages.minDate.replace(regex, errors.minDate.requiredLength);
			if (errors.minAge != null)
				return CustomValidation.errorMessages.minAge.replace(regex, errors.minAge.requiredLength);
			if (errors.maxAge != null)
				return CustomValidation.errorMessages.maxAge.replace(regex, errors.maxAge.requiredLength);
			if (errors.pattern != null)
				return CustomValidation.errorMessages.pattern;
			if (errors.email != null)
				return CustomValidation.errorMessages.email;
			if (errors.acceptFile != null)
				return CustomValidation.errorMessages.acceptFile;
			if (errors.min != null)
				return CustomValidation.errorMessages.acceptFile;
			if (errors.settlement != null)
				return CustomValidation.errorMessages.settlement;
			if (errors.street != null)
				return CustomValidation.errorMessages.street;
			if (errors.valueAmount != null)
				return CustomValidation.errorMessages.valueAmount.replace(regex, CustomValidation.getStringOperator(errors.valueAmount.operator))
					.replace(/@num@/gi, errors.valueAmount.amount);
		}

		return '';
	}

	static getStringOperator(operator) {
		if (operator == "EqualTo")
			return 'שווה ל'
		if (operator == "SmallerThan")
			return 'קטן מ'
		if (operator == "BiggerThan")
			return 'גדול מ'
		if (operator == "NotEqualTo")
			return 'לא שווה ל'
		if (operator == "Blank")
			return 'ריק'
		if (operator == "NotBlank")
			return 'לא ריק'
		return ' '
	}

}
export const markFormGroupTouched = (formGroup: FormGroup) => {
	(<any>Object).values(formGroup.controls).forEach((control) => {
		control.markAsDirty();
		if (control.controls) {
			markFormGroupTouched(control);
		}
	});
}