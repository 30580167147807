<ng-container [ngSwitch]="indication" class="buttonRefLevel">
        <ng-template class="prev-level"
                [ngSwitchCase]="indication=='prevInProcess'||indication=='prevSecondaryProcess'?indication:''">
                <button *ngIf="indexLevel!='0'&&statusUserSecondaryProcess!='InLastLevel'" class="button-footer footer-button-prev"
                        mat-raised-button mat-button type="button" (click)="clickRefLevel()">חזור לשלב הקודם</button>
        </ng-template>

        <ng-template
                [ngSwitchCase]="indication=='nextInProcess'||indication=='nextSecondaryProcess'?indication:'lastInProcess'">
                <ng-container [ngSwitch]="statusUserSecondaryProcess">
                        <ng-template [ngSwitchCase]="'InLastLevel'">
                                <button class="button-footer next-level" [disabled]="(userForm.status == 'PENDING' ||userForm.status=='INVALID')"
                                        [ngClass]="{'footer-button-disable':(userForm.status == 'PENDING' ||userForm.status=='INVALID') ,'footer-button-active': userForm.status=='VALID' }"
                                        mat-raised-button mat-button mat-button type="submit" (click)="clickRefLevel()">
                                        <span [innerHtml]="'הרכב ההשקעה המומלץ מקובל עלי'"></span>
                                </button>
                        </ng-template>
                        <ng-template ngSwitchDefault>
                                <button class="button-footer next-level footer-button-active"
                                        mat-raised-button mat-button type="submit" (click)="clickRefLevel()">
                                        <span>
                                                <span [innerHtml]="content|keepHtml"></span>
                                        </span>
                                </button>
                        </ng-template>
                </ng-container>
        </ng-template>
        <ng-template [ngSwitchCase]="'pointerLevel'">
                <div class="pointer-level">
                        <button type="submit" [class]="button.Class?button.Class:''" (click)="clickRefLevel()">
                                <span [innerHtml]="button.Label|keepHtml"></span>
                        </button>
                        <span class="button-Note" *ngIf='button.Note' [innerHtml]="button.Note|keepHtml"></span>
                </div>

        </ng-template>
</ng-container>