import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomValidation } from '../../custom-validation';
import { distinctUntilChanged } from 'rxjs/operators';
import * as JsonObjects from '../../classes'
import { FileInput } from 'ngx-material-file-input';

@Component({
    selector: 'app-file',
    templateUrl: './uploadFile.component.html',
    styleUrls: ['./uploadFile.component.scss']
})
export class FileComponent implements OnInit {

    @Input() input: JsonObjects.UploadFile;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק
    isFileEmpty: boolean;//נועד בשביל העיצוב איזה מחלקה להציג
    errorUploadingFile: string;//שליפת השגיאה בהעלת הקובץ

    constructor(private cd: ChangeDetectorRef) { }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
        this.updateClassUI(this.userForm.controls[this.input.ControlId].value)//בפעם הראשונה כאשר הקומפוננטה נטענת    

        this.userForm.controls[this.input.ControlId].valueChanges.pipe(distinctUntilChanged()).subscribe((valueFile) => {//הוספת האזנה כאשר הערך בהעלאת קובץ משתנה לצורך התאמת העיצוב הויזואלי                    
            this.updateClassUI(valueFile);
        });

        this.cd.detectChanges();//מעדכן תצוגה
    }

    updateClassUI(value) {
        if (value && value.files && value.files.length > 0)
            this.isFileEmpty = false;
        else
            this.isFileEmpty = true;
        this.errorUploadingFile = this.validation(this.userForm.controls[this.input.ControlId + this.idExtension]);
    }
    allowDrop(ev) {//בעץ גרירת קובץ מניעת התנהגות ברירת מחדל (פתיחת הדף בדפדפן ליד)
        ev.preventDefault();
    }
    drop(event, currentInputId) {//גרירה עג החתימה קובץ
        event.preventDefault();
        //שמריה ברדקס את הקובץ 
        let filesControl: File[] = [];
        for (let iFile = 0; iFile < event.dataTransfer.files.length; iFile++) {//שיהיה בפורמט רגיל ולא דיקשנרי
            filesControl.push(event.dataTransfer.files[0]);
        }
        let fileInput: FileInput = new FileInput(filesControl);
        this.fileChangeEvent(fileInput.files, currentInputId);
        this.updateClassUI(event.dataTransfer);

        this.userForm.controls[currentInputId].setValue(fileInput);//שמירת הקובץ
    }
    fileChangeEvent(files: File[], currentInputId: string) {
        if (files[0]) {
            var filename: string = files[0].name;
            $('#' + currentInputId + 'filename').eq(0).text(filename);
            $('#' + currentInputId + 'filename').eq(0).focus();
        }
    }
}