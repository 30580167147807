<ng-container [ngSwitch]="isClassExists('radio-switch')">

  <ng-template [ngSwitchCase]="true">
    <div [class]="input.Class +' radio-switch'">
      <mat-label>
        <span [innerHtml]="input.Label|keepHtml"></span>
        <app-tooltip *ngIf="input.Tooltip!=undefined" [tooltipContent]="input.Tooltip"></app-tooltip>
      </mat-label>
      <mat-button-toggle-group
        [ngClass]="{'error':userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty}"
        class="radio-group" [formControl]="userForm.get(input.ControlId+idExtension)"
        [id]="input.ControlId+idExtension">
        <app-option *ngFor="let option of input.InputOptions" [userForm]="userForm" [classList]="classList"
          [optionsControlId]="input.ControlId" [input]=option [currentComponent]="currentComponent"></app-option>
      </mat-button-toggle-group>
    </div>

  </ng-template>

  <ng-template [ngSwitchCase]="false">
    <ng-container [ngSwitch]="isClassExists('radio-toggle')">
      <ng-template [ngSwitchCase]="true">
        <div class="radio-toggle" [id]="input.ControlId+idExtension">
          <mat-label>
            <span [innerHtml]="input.Label|keepHtml"></span>
            <span class="label-required" *ngIf='input.Validators && input.Validators.Required'> *</span>
            <app-tooltip *ngIf="input.Tooltip!=undefined" [tooltipContent]="input.Tooltip"></app-tooltip>
          </mat-label>
          <div>
            <mat-button-toggle-group
              [ngClass]="{'error':userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty}"
              class="radio-group" [formControl]="userForm.get(input.ControlId+idExtension)">
              <app-option *ngFor="let option of input.InputOptions" [userForm]="userForm" [classList]="classList"
                [optionsControlId]="input.ControlId" [input]=option [currentComponent]="currentComponent"></app-option>
            </mat-button-toggle-group>
            <mat-error *ngIf="userForm.controls[input.ControlId+idExtension].dirty">
              <div>{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
            </mat-error>
          </div>
        </div>

      </ng-template>

      <ng-template ngSwitchDefault>
        <ng-container [ngSwitch]="isClassExists('radio-risk-profile_Long')">
          <ng-template [ngSwitchCase]="true">
            <div [id]="input.ControlId+idExtension" [class]="input.Class">
              <mat-label>
                <span [innerHtml]="input.Label|keepHtml"></span>
                <span class="label-required" *ngIf='input.Validators && input.Validators.Required'> *</span>
              </mat-label>
              <div class="radio-risk-profile_Long_answer" floatPlaceholder="always" appearance="none"
                [formGroup]="userForm.get(input.ControlId+idExtension)">
                <div *ngFor="let option of input.InputOptions">
                  <div class="radio_rectangle">
                    <input (change)="handleChangeStyle()" [formControl]="userForm.get(input.ControlId+idExtension)"
                      type="radio" class="screenreader" aria-hidden="true"
                      [id]="input.ControlId+option.OptionName+idExtension" [value]="option.OptionName" />
                    <label [innerHtml]="option.Label|keepHtml" class="radio_rectangle__label"
                      [for]="input.ControlId+option.OptionName+idExtension"></label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngSwitchDefault>
            <ng-container [ngSwitch]="isClassExists('radio-risk-profile')">
              <ng-template [ngSwitchCase]="true">
                <div [id]="input.ControlId+idExtension" [class]="input.Class">
                  <mat-label>
                    <span [innerHtml]="input.Label|keepHtml"></span>
                    <span class="label-required" *ngIf='input.Validators && input.Validators.Required'> *</span>
                  </mat-label>
                  <div class="radio-risk-profile_answer" floatPlaceholder="always" appearance="none"
                    [formGroup]="userForm.get(input.ControlId+idExtension)">
                    <div *ngFor="let option of input.InputOptions">
                      <div class="radio_rectangle">
                        <input (change)="handleChangeStyle()" [formControl]="userForm.get(input.ControlId+idExtension)"
                          type="radio" class="screenreader" aria-hidden="true"
                          [id]="input.ControlId+option.OptionName+idExtension" [value]="option.OptionName" />
                        <label [innerHtml]="option.Label|keepHtml" class="radio_rectangle__label"
                          [for]="input.ControlId+option.OptionName+idExtension"></label>

                      </div>
                    </div>
                  </div>
                </div>

              </ng-template>
              <ng-template ngSwitchDefault>
                <mat-form-field floatPlaceholder="always" appearance="none" class="radio"
                  [id]="input.ControlId+idExtension">
                  <mat-label>
                    <span [innerHtml]="input.Label|keepHtml"></span>
                    <span class="label-required" *ngIf='input.Validators && input.Validators.Required'> *</span>
                    <app-tooltip *ngIf="input.Tooltip!=undefined" [tooltipContent]="input.Tooltip"></app-tooltip>
                  </mat-label>
                  <input matInput placeholder="Input" style="display: none"
                    [formControl]="userForm.get(input.ControlId+idExtension)" />
                  <mat-radio-group class="radio-group" [formControl]="userForm.get(input.ControlId+idExtension)">
                    <app-option *ngFor="let option of input.InputOptions" [userForm]="userForm" [classList]="classList"
                      [optionsControlId]="input.ControlId" [input]=option
                      [currentComponent]="currentComponent"></app-option>
                  </mat-radio-group>
                  <mat-error class=" error2">
                    <div>{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
                  </mat-error>
                </mat-form-field>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>