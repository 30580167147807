

//namespace a{
 
    import {Input} from './Input' 
import {InputExpandValidation} from './InputExpandValidation' 
    export class Text extends Input {
       
Validators? :InputExpandValidation;
ValueType? :string;
Placeholder? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










