

//namespace a{
 
    
    export class FieldControl {
       
SectionId? :string;
ControlId? :string;
OptionId? :string;
Copy? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










