<div *ngIf="userForm" class="signature-pad">
   
    <div class="signature-header">חתימה</div>
    <input type="file" #removableInput (change)="fileChangeEvent($event.target.files,input.ControlId+idExtension)" [id]="input.ControlId+idExtension+'File'" class="screenreader" aria-label="יש להעלות קובץ חתימה"/>
    <br>
    <div class="signature-pad--body" aria-hidden="true" >
       <div class="signature-background"></div>
        
        <div class="signature-text" *ngIf="signaturePad && signaturePad.isEmpty() && (!file ||file.length==0)"> 
               <img class="img-signature" src="./assets/img-lg/pensvg.svg" alt><br>
            <span class="description">{{input.Label}}</span>
        </div>
        <div  [ngClass]="{'disableDesignFile':(!file ||file.length==0)}" class="signature-text"  >      
            <img class="img_uploadFile" src="./assets/img-lg/file_ok.svg" alt="העלאת הקובץ הסתיימה ,הקובץ הועלה באופן תקין">
                <br/>
                <div class="text_uploadFile_full">
                    <span *ngIf="(file &&file.length>0)" > 
                        {{file[0].name}}                                         
                    </span> 
                    <a aria-label="לחץ כאן להסרת הקובץ שהועלה" matSuffix *ngIf="!removableInput.empty" (click)="removableInput.clear($event)">
                        <img style="width: fit-content;" src="./assets/img-lg/trash_link.svg" alt>
                    </a>
                </div> 
        </div>
        <canvas class="signature-canvas" [id]="input.ControlId+idExtension" (mouseup)="onMouseup()"
        (mouseleave)="onMouseup()"  (touchend)="onMouseup()" (touchcancel)="onMouseup()"
        (touchstart)="onmousedown()" (mousedown)="onmousedown()" [ngClass]="signaturePad && !signaturePad.isEmpty()?'canvas-full':''"></canvas>               
        <div class="signature-text-full" *ngIf="signaturePad && !signaturePad.isEmpty()">         
            <a class="img-signature-trash" mat-icon-button matSuffix (click)="clearButton()" aria-label="לחץ כאן לעריכה מחדש חתימה">
                <img src="./assets/img-lg/trash.svg" alt> 
             </a>
        </div>
    </div>
    <div class="signature-pad--footer">
        <mat-error class=" error">
            <div>{{validation(userForm.controls[input.ControlId+idExtension])}}</div>
        </mat-error>
    </div>
</div>