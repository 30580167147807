import * as JsonObjects from '.';

export class Serializable {

    static fromJSON(type: string, json: any) {
        const newObj = new JsonObjects[type];//new objType();

        for (const prop in json) {
            if (json.hasOwnProperty(prop)) {
                if (newObj[prop] == null && prop != "$type" && json[prop] != null) {//אין להכניס שדה type ואין להכניס שוב על שדה שכבר קיים
                    if (json[prop].$type == null) {//אם לא מכיל אוביקט//relationships[prop] == null //typeof(json[prop])!="object"
                        newObj[prop] = json[prop];//הכנס את השדה
                    }
                    else {
                        if (json[prop].$type.indexOf('System.Collections.Generic.List') >= 0|| json[prop].$type.indexOf('[]')>= 0) {//שדה שמכיל רשימה
                            newObj[prop] = [];//הגדרת אוביקט

                            for (const v in json[prop].$values) {//יצרית רשימה
                                if (json[prop].$values[v].$type == null) {//אם לא מכיל אוביקט
                                    newObj[prop].push(json[prop].$values[v]);//הוסף את השדה
                                }
                                else {//אם הרשימה מכילה אוביקטים
                                    var spliteType = json[prop].$values[v].$type.split('.');
                                    var typeObject = spliteType[spliteType.length - 1].split(',')[0]
                                    newObj[prop].push(this.fromJSON(typeObject, json[prop].$values[v]))//הוסף אוביקט
                                }
                            }
                        }
                        else {
                            newObj[prop] = {};//הגדרת אוביקט
                            var spliteType = json[prop].$type.split('.');
                            var typeObject = spliteType[spliteType.length - 1].split(',')[0]
                            newObj[prop] = this.fromJSON(typeObject, json[prop]);//שליחת האוביקט
                        }
                    }
                }
            }
        }

        return newObj;
    }
}