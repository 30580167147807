import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JsonObjects from '../../classes'
import { CustomValidation } from '../../custom-validation';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

    @Input() input: JsonObjects.Date;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
    }

    onDateChange(value: any) {
        let selectedDate = new Date(value);
        let day = selectedDate.getDate();
        let month = selectedDate.getMonth();
        let year = selectedDate.getFullYear();
        // המרת מחרוזת התאריך לפורמט ISO
        // יצירת תאריך עם שעה 00:00:00 כדי למנוע שינויים ביום
        let isoDate = new Date(Date.UTC(year,month,day)).toISOString(); 
        this.userForm.controls[this.input.ControlId + this.idExtension].setValue(isoDate);
    }
}