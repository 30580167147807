import { NgRedux } from '@angular-redux/store';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { IAppState } from '../../../../store/reducer';
import * as JsonObjects from '../../classes'
import { LevelSectionService } from '../../services/levelSection.service';
import { CounterActions } from '../../../../store/actions';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html'
})
export class OptionComponent implements OnInit, AfterViewInit {
  @Input() input: JsonObjects.Option;//הפקד הנוכחי
  @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
  @Input() classList: string[] = [];//קלאסים של הפקד הנוכחי
  @Input() optionsControlId: string;//מזהה פקד האב options
  @Input() userForm: FormGroup;//טופס משתמש

  idExtension: string = '';//מזהה עותק
  private subscription: Subscription = new Subscription();

  ControlPointed: JsonObjects.Control;
  constructor(private levelSectionService: LevelSectionService, private ngRedux: NgRedux<IAppState>, private actions: CounterActions) { }

  ngOnInit() {
    this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
    if (this.input.ControlNumPointed)
      this.ControlPointed = this.levelSectionService.SectionArray[this.currentComponent.Num].Controls.filter(co => co.Pointed == true).find(c => c.ControlId == this.input.ControlNumPointed);
  }
  ngAfterViewInit(): void {
    if (this.ControlPointed)//במידה ויש פקד מוצבע מקונן יש להאזין לשינויים בתורן השדה ולבחור את רדיו באטן הנוכחי
    {
      this.subscription.add(this.userForm.controls[this.ControlPointed.ControlId + this.idExtension].valueChanges.pipe(distinctUntilChanged()).subscribe(//תאזין לכל שינוי טקסט של הפקד שתלוי בו
        (value) => {
          if (value != "")
            this.userForm.controls[this.optionsControlId + this.idExtension].setValue(this.input.OptionName);//עדכון ערך פקד  UserForm              
        }));
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  focusControlPointed() {
    if (this.ControlPointed) $("#" + this.optionsControlId + this.idExtension + " #" + this.ControlPointed.ControlId).focus();
  }
  isClassExists(isClass: string = '') {
    return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
  }
}