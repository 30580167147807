import { Injectable } from '@angular/core';
import { select } from '@angular-redux/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class DirectAccessGuard implements CanActivate {
  constructor(private router: Router) { }

  @select(['router']) router$;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const url = '\welcome';
    const tree: UrlTree = this.router.parseUrl(url);
    var OriginUrl = this.router.url//.split('/').length;
    let nextIndex;
    this.router$.subscribe(index => nextIndex = index.toString().split('/')[3]);
    var stepNum: number = +(Number(next.params.id));
    if (nextIndex == undefined && stepNum == 0 && OriginUrl.endsWith("l"))
      return true;
    else if ((nextIndex == undefined)//פעם הראשונה שנכנס לFormDynamicComponent וזה לא אפס
      || (stepNum != +nextIndex + 1 && stepNum != +nextIndex - 1))//אם זה לא הצעד העוקב
    {
     // this.router.navigateByUrl(tree);
      return true;
    }
    return true;
  }
}