

//namespace a{
 
    import {Email} from './Email' 
import {EmailDetailsMember} from './EmailDetailsMember' 
    export class EmailUser extends Email {
       
Attachments? :EmailDetailsMember;
To? :EmailDetailsMember;
CC? :EmailDetailsMember;
Bcc? :EmailDetailsMember;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










