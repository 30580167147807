import { Input, OnInit, Component, ViewEncapsulation } from '@angular/core';
import  * as JsonObjects  from '../../../shared/classes';
import { ResponsiveService } from '../../../shared/services/resonsive.service';
import { FormComponent } from '../../../shared/structureObject';

@Component({
    selector: 'app-textSection',
    templateUrl: './textSection.component.html',
    styleUrls: ['./textSection.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TextSectionComponent implements  OnInit {
    @Input() currentSection: JsonObjects.TextSection;//המקטע הנוכחי עם שדות
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו

    constructor(private responsiveService:ResponsiveService) {        
    }
    idExtension: string = '';//מזהה עותק
    get eDevice(){
        return FormComponent.eDevice;
    }
    get device(){
        return  this.responsiveService.device;
    }
    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
    }
}