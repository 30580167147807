import { Injectable, OnDestroy } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserValueEnterrd } from './user-value-entered.service';
import * as JsonObjects from '../classes';
import { IAppState } from '../../../store/reducer';
import { CounterActions } from '../../../store/actions';

@Injectable({ providedIn: 'root' })
export class ConditionalLogic implements OnDestroy {

    userForm: FormGroup;//טופס משתמש
    recheckConditionalLogic: { [currentId: string]: Subject<string> } = {};//מערך ששומר לכל פקד את הפונקציה לבדיקה חוזרת של תצוגה מותנית
    controlValueChangeList: {//שמירת לכל פקד את האזנות שהפקדים משוייכים אליו
        [controlId: string]: string[]
    } = {}

    private subscription: Subscription = new Subscription();//מערך האזנות, כדי למחוק כשהקומפוננטה נהרסת

    constructor(private actions: CounterActions, private userValueService: UserValueEnterrd, private ngRedux: NgRedux<IAppState>, public dialog: MatDialog) {
        this.ngRedux.select(o=>o.UserForm).subscribe((userForm: FormGroup) => {this.userForm = userForm});//שליפת טופס משתמש    
    }

    ngOnDestroy() {
        this.recheckConditionalLogic = {};
        this.subscription.unsubscribe();
        this.controlValueChangeList = {};
    }

    clearRecheckConditionalLogic() {//איפוס האזנות לבדיקת תצוגה מותנית
        this.recheckConditionalLogic = {};
        this.subscription.unsubscribe();
        this.subscription = new Subscription();
        this.controlValueChangeList = {};
    }
    //מקבל מקטע מסיר עבור כל אחד מהפקדים שלו את הולידציות
    disableControlFormSection(controls: JsonObjects.Control[], idExtension: string) {
        var disableControlsId: string[] = [];
        controls.forEach(control => {
            if (this.userForm.controls && this.userForm.controls[control.ControlId + '' + idExtension])
                if (this.userForm.controls[control.ControlId + '' + idExtension].enabled && !(control instanceof JsonObjects.Notification))
                   this.userForm.controls[control.ControlId + '' + idExtension].disable()
        })
        setTimeout(() => { this.ngRedux.dispatch(this.actions.actions.disableControlsForm(this.userForm))}, 0); //מחיקת פקדים
    }
    //מקבל מקטע מאפשר  כל אחד מהפקדים ולידציות 
    enableControlFormSection(controls: JsonObjects.Control[], idExtension: string) {
        //הפעלת הפקדים מופעל כאן כדי שלא תיהנה בעיות אסינכרוניות
        controls.forEach(control => {
            if (this.userForm.controls && this.userForm.controls[control.ControlId + '' + idExtension])
                if (this.userForm.controls[control.ControlId + '' + idExtension].disable && !(control instanceof JsonObjects.Notification) && !(control instanceof JsonObjects.Button))
                    this.userForm.controls[control.ControlId + '' + idExtension].enable()//הכוונה להדליק את הולידציות של הפקד
        })
        setTimeout(() => { this.ngRedux.dispatch(this.actions.actions.enableControlsForm(this.userForm)) }, 0);//הפעלת פקדים
    }

    checkCondition(isConditionProcess:boolean,currentId: string, conditionalLogicGroup: JsonObjects.ConditionalLogicGroup, isOnInit: boolean = false, idExtensionCondition: string = '', currentSectionNum: string = null): boolean {//בדיקת קבוצת תנאים       
        if (conditionalLogicGroup.Activated == true && conditionalLogicGroup.ConditionalList != null) {//יש להתייחס לתנאי רק אם התנאי מופעל ויש תנאים במערך
            var resultFilter;
            for (var c of conditionalLogicGroup.ConditionalList) {
                var result;// = Observable.apply(true);
                if (c.Activated == true) {
                    if (c instanceof JsonObjects.ConditionalLogic) {
                        let conditionalLogic: JsonObjects.ConditionalLogic = c as JsonObjects.ConditionalLogic
                        if (conditionalLogic.Control != null && conditionalLogic.EqualityOperator != null)//תנאי בודד
                        
                        if(isConditionProcess==false||
                            (isConditionProcess&&this.ngRedux.getState().Currentlevel.Children.findIndex(s=>s.Num==conditionalLogic.Control.Section)!=-1))//במידה ומדובר בהודעה של עצירת התהליך תבדוק שאתה נמצא על השלב הזה(ולא שלבים קודמים)
                            result = this.isConditionTrue(currentId, conditionalLogic, isOnInit, idExtensionCondition, currentSectionNum);
                    }
                    else if ((c as JsonObjects.ConditionalLogicGroup).ConditionalList != null && (c as JsonObjects.ConditionalLogicGroup).LogicalOperator != null)//קבוצת תנאים
                    {
                        let conditionalLogicGroup: JsonObjects.ConditionalLogicGroup = c as JsonObjects.ConditionalLogicGroup
                        result = this.checkCondition(isConditionProcess,currentId, conditionalLogicGroup, isOnInit)
                    }
                }
                else
                    result = conditionalLogicGroup.LogicalOperator == "Or" ? false : true;

                if (conditionalLogicGroup.LogicalOperator == "Or") {
                    if (result == true) {
                        resultFilter = true;
                        if (!isOnInit)//באתחול של הפקדים יש לעבור על כל התנאים כדי ליצור להם האזנות
                            break;
                    }
                }
                else
                    if (result == false) {
                        resultFilter = false;
                        if (!isOnInit)//באתחול של הפקדיםיש לעבור על כל התנאים כדי ליצור להם האזנות
                            break;
                    }
            };
            if (resultFilter != null)
                return resultFilter;

            if (conditionalLogicGroup.LogicalOperator == "Or")
                return false;
            else
                return true;
        }
        return true;
    }

    //פקד הודעה רגיל במקטע
    checkNotificationCondition(conditionalLogicGroup: JsonObjects.ConditionalLogicGroup, control: JsonObjects.Control = null, isOnInit: boolean, controlIdChange: string = '', idExtensionCondition: string = '', currentSectionNum: string = null): boolean {//בדיקת קבוצת תנאים להודעה
        if (conditionalLogicGroup.Activated == true) {
            var resultFilter, ControlsId: string[] = [];//מערך הפקדים שנבדקו 
            for (var c of conditionalLogicGroup.ConditionalList) {
                var result, currentControlId = '';//מזהה פקד שנבדק
                if (c.Activated == true) {
                    if (c instanceof JsonObjects.ConditionalLogic) {
                        let conditionalLogic: JsonObjects.ConditionalLogic = c as JsonObjects.ConditionalLogic
                        currentControlId = conditionalLogic.Control.Field;//קבלת מזהה פקד שנבדק
                        ControlsId.push(currentControlId);//שמירת הפקדים שנבדקו
                        if ((c as JsonObjects.ConditionalLogic).Control != null && (c as JsonObjects.ConditionalLogic).EqualityOperator != null)//תנאי בודד
                            result = this.isConditionTrue(control.ControlId, conditionalLogic, isOnInit, idExtensionCondition, currentSectionNum);
                    }
                    else if ((c as JsonObjects.ConditionalLogicGroup).ConditionalList != null && (c as JsonObjects.ConditionalLogicGroup).LogicalOperator != null)//קבוצת תנאים
                    {
                        let conditionalLogicGroup: JsonObjects.ConditionalLogicGroup = c as JsonObjects.ConditionalLogicGroup
                        result = this.checkNotificationCondition(conditionalLogicGroup, control, isOnInit, controlIdChange, idExtensionCondition, currentSectionNum)
                    }
                }
                else
                    result = conditionalLogicGroup.LogicalOperator == "Or" ? false : true;

                if (conditionalLogicGroup.LogicalOperator == "Or") {
                    if (result == true && (currentControlId == '' || currentControlId == controlIdChange)) {//רק לפקד שהשתנה יכול להפסיק את הבדיקה או לקבוצת תנאים
                        resultFilter = true;
                        if (!isOnInit)//באתחול יש לעבור על כל התנאים כדי ליצור להם האזנות
                            break;
                    }
                }
                else
                    if (result == false) {
                        resultFilter = false;
                        if (!isOnInit)//באתחול יש לעבור על כל התנאים כדי ליצור להם האזנות
                            break;
                    }
            };
            if (resultFilter != null)
                return resultFilter;

            if (conditionalLogicGroup.LogicalOperator == "Or")//Or-אף אחד לא היה נכון
                return false;
            else//Also-כולם נכונים
                return ControlsId.findIndex(c => c == controlIdChange) >= 0 ? true : false;//בדיקה אם במערך אחד מהם שווה לפקד לבדיקה
        }
        return true;
    }


    isConditionTrue(currentId: string, conditionalLogic: JsonObjects.ConditionalLogic, isOnInit: boolean = false, idExtensionCondition: string = '', currentSectionNum: string = null) {//בדיקת תנאי בודד        
        //אם כל הערכים מלאים עושים בדיקה
        var idExtensionDoubleSection = '';
        if (conditionalLogic.Activated == true && conditionalLogic.Control.Section !== null && conditionalLogic.Control.Field !== null) {
            //תצוגה מותנית בין פקדים במקטע כפול יש לבדוק רק במקטע הכפול הנוכחי או תצוגה של הודעה שגם שם יש להתייחס למזההי הכפולים
            if (currentSectionNum && idExtensionCondition != '' && (conditionalLogic.Control.Section == currentSectionNum || currentSectionNum == 'NotificationCondition'))//פקד במקטע מוכפל
                idExtensionDoubleSection = idExtensionCondition;//יש להוסיף מזהה ההכפלה
            var fieldToCheck = this.userForm.get(conditionalLogic.Control.Field + idExtensionDoubleSection);// שליפת הפקד מהטופס הנוכחי                   
            if (fieldToCheck != null)//אם אתה באותו שלב                
            {//בדיקת התנאי בפקד 
                if (isOnInit && this.recheckConditionalLogic[currentId])//בעת איתחול בצע האזנה לפקד
                {

                    //בדיקה אם הפקד שבתצוגה מותנית כבר מקושר לפקד הנוכחי כדי למנוע האזנות כפולות
                    var fieldIndex = this.controlValueChangeList[currentId] ? this.controlValueChangeList[currentId].findIndex(fieldId => fieldId == conditionalLogic.Control.Field + idExtensionDoubleSection) : -1;
                    if (fieldIndex == -1)//אם לא קיים לך האזנה אז תיצור 
                    {
                        if (!this.controlValueChangeList[currentId])
                            this.controlValueChangeList[currentId] = [];
                        this.controlValueChangeList[currentId].push(conditionalLogic.Control.Field + idExtensionDoubleSection); //שמירת האזנה שנוצרה עכשיו
                        this.subscription.add(fieldToCheck.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {//האזנה לשינויים                       
                            this.recheckConditionalLogic[currentId].next(conditionalLogic.Control.Field + idExtensionDoubleSection);
                        }));
                        this.subscription.add(fieldToCheck.statusChanges.pipe(distinctUntilChanged()).subscribe(() => {//האזנה לשינויים                       
                            this.recheckConditionalLogic[currentId].next(conditionalLogic.Control.Field + idExtensionDoubleSection);
                        }));//האזנה תיהיה גם לשינוי מצב הפקדים - כדי שפקד שנהיה אינייבל יוכלו לבדוק אותו שנית

                    }

                    //יצירת האזנות גם לפקדים מוכפלים
                    Object.keys(this.userForm.controls).filter(controlId => controlId.startsWith(conditionalLogic.Control.Field + idExtensionDoubleSection + '_COPY_')).forEach(controlId => {
                        //בדיקה אם הפקד שבתצוגה מותנית כבר מקושר לפקד הנוכחי כדי למנוע האזנות כפולות
                        fieldIndex = this.controlValueChangeList[currentId] ? this.controlValueChangeList[currentId].findIndex(fieldId => fieldId == controlId) : -1;
                        if (fieldIndex == -1)//אם לא קיים לך האזנה אז תיצור 
                        {
                            if (!this.controlValueChangeList[currentId])
                                this.controlValueChangeList[currentId] = [];
                            this.controlValueChangeList[currentId].push(controlId); //שמירת האזנה שנוצרה עכשיו
                            this.subscription.add(this.userForm.get(controlId).valueChanges.pipe(distinctUntilChanged()).subscribe(() => {//האזנה לשינויים                       
                                this.recheckConditionalLogic[currentId].next(controlId);
                            }));
                        }
                    })

                }
                if (fieldToCheck.disabled)//אם הפקד דיסייבל 
                    return false;//אין צורך לבדוק את הערך שלו ויש להחזיר ישר false
                return this.isFieldConditionTrue(conditionalLogic, fieldToCheck.value);//בדיקת ערך שדה בהתאם לתנאי

            }
            else {//בדיקה ברידקס
                var selectFieldValue: string = this.userValueService.selectUserValueEntered({ sectionId: conditionalLogic.Control.Section, fieldId: conditionalLogic.Control.Field + idExtensionDoubleSection })
                return this.isFieldConditionTrue(conditionalLogic, selectFieldValue);//בדיקת ערך שדה בהתאם לתנאי                      
            }
        }
        return true;
    }

    isFieldConditionTrue(conditionalLogic: JsonObjects.ConditionalLogic, fieldValue: string) {//בדיקת ערך שדה בהתאם לתנאי        
        //בדיקת התנאי
        if (typeof (fieldValue) == "string") {
            if (conditionalLogic.EqualityOperator == "EqualTo")
                return fieldValue == conditionalLogic.EqualityValue;
            else if (conditionalLogic.EqualityOperator == "SmallerThan")
                return fieldValue < conditionalLogic.EqualityValue;
            else if (conditionalLogic.EqualityOperator == "BiggerThan")
                return fieldValue > conditionalLogic.EqualityValue;
            else if (conditionalLogic.EqualityOperator == "NotEqualTo")
                return fieldValue != conditionalLogic.EqualityValue;
            else if (conditionalLogic.EqualityOperator == "Blank")
                return (fieldValue == '');
            else if (conditionalLogic.EqualityOperator == "NotBlank")
                return (fieldValue != '');
        }
        else
            if (typeof (fieldValue) == "object") {//אם זה פקד של אפשריות אז לשלוף את התוצאה של אמת או שקר
                if (conditionalLogic.EqualityOperator == "EqualTo")
                    return fieldValue && fieldValue[conditionalLogic.EqualityValue] == true
                else if (conditionalLogic.EqualityOperator == "NotEqualTo")
                    return fieldValue && fieldValue[conditionalLogic.EqualityValue] == '';
                else if (conditionalLogic.EqualityOperator == "Blank")
                    return !fieldValue || (Object.keys(fieldValue).length === 0);
                else if (conditionalLogic.EqualityOperator == "NotBlank")
                    return fieldValue && (Object.keys(fieldValue).length > 0);
            }

        return false;
    }

}