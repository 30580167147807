

//namespace a{
 
    import {Control} from './Control' 
    export class RefSection extends Control {
       
SectionNum? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










