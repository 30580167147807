<ng-template ngFor let-section [ngForOf]="refSections" let-i="index" *ngIf="currentRefSection">
  <ng-container [ngSwitch]="section.Template">
    <ng-template [ngSwitchCase]="'text'">
      <app-textSection [currentComponent]=section [currentSection]=currentRefSection>
      </app-textSection>
    </ng-template>
    <ng-template [ngSwitchCase]="'form'">
      <ng-container [ngSwitch]="currentRefSection.IsExpansionPanel">
        <ng-template [ngSwitchCase]="true">
          <mat-expansion-panel *ngIf="device==eDevice.Desktop&&expansionPanelSection[section.Num+i]" [expanded]=" section.CompName==''||expansionPanelSection[section.Num+i].isOpen"
          [disabled]="expansionPanelSection[section.Num+i].isDisable" (opened)="expansionPanelOpen(section.Num+i)" (closed)="expansionPanelSection[section.Num+i].isOpen = false"
          >
            <mat-expansion-panel-header *ngIf="refSections[i].CompName!=''">
              <mat-panel-title>
                <div class="panel-title" role="heading" aria-level="4">
                  <span [innerHtml]="section.CompName|keepHtml"></span>
                  <span class="section-copy">{{section.Hierarchy}}</span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-section [currentLevel]=currentLevel [currentComponent]=section [currentSection]=currentRefSection
              (setSectionCompleted)=setRefSectionCompleted($event)>
            </app-section>
          </mat-expansion-panel>
          <app-section  [ngClass]="currentRefSection.Class" *ngIf="device!=eDevice.Desktop" [currentLevel]=currentLevel [currentComponent]=section [currentSection]=currentRefSection
          (setSectionCompleted)=setRefSectionCompleted($event)>
        </app-section>
        </ng-template>
        <ng-template ngSwitchDefault>
          <app-section [ngClass]="currentRefSection.Class" [currentLevel]=currentLevel [currentComponent]=section
            [currentSection]=currentRefSection (setSectionCompleted)=setRefSectionCompleted($event)>
          </app-section>
        </ng-template>
      </ng-container>
    </ng-template>