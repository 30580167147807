

//namespace a{
 
    
    export class UserInputs {
       
Id? :string;
Value? :any;
Type? :string;
IsDraft? :boolean;
Children? :UserInputs[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










