

//namespace a{
 
    import {Input} from './Input' 
import {DateExpandValidation} from './DateExpandValidation' 
    export class Date extends Input {
       
Validators? :DateExpandValidation;
Placeholder? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










