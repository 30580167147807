import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as JsonObjects from '../../../../classes'
import { NotificationBaseComponent } from '../../notificationBaseComponent';

@Component({
  selector: 'app-notification-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.scss']
})
export class NotificationInfoComponent extends NotificationBaseComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NotificationInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: JsonObjects.Notification) {
    super();
    data.Message }
  
onNoClick(): void {
    this.dialogRef.close();
}   

  ngOnInit(): void {
  }

}

