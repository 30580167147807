

//namespace a{
 
    import {Section} from './Section' 
import {Control} from './Control' 
    export class FormSection extends Section {
       
Controls? :Control[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










