import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: string, controlId: string,ValueType: string): number {
        if(ValueType=='number') {
        let newval = value ? this.localeString(value.toString()) : '';
        if (controlId)
            $($("#" + controlId).parent()).attr('data-value', newval + '0');
        return newval;
    }
}
    localeString(value) {
        if (value === '') return '';
        value = value.toString().replace(/[^0-9.]/g, '');//הורדת פסיקים ומי שאינו מספר מלבד נקודה
        if (value.length > 1)
            value = value.replace(/^0+/gi, "");//הורדת אפס ראשון
        let rgx;
        value += '';
        rgx = /(\d+)(\d{3})/;
        while (rgx.test(value)) {
            value = value.replace(rgx, '$1' + ',' + '$2');
        }
        return value;
    }
    
}