<ng-container [ngSwitch]="isClassExists('button-section')">
    <ng-template [ngSwitchCase]="true">
        <div *ngIf="(input.QuantityLimit && countSection < (input.QuantityLimit | number))">
        <button   type="button" class="button-section" *ngIf="userForm" mat-button  [id]="input.ControlId+idExtension"          
            (click)="eventButton()">
            <span class="button-span">
                <img class="img-man" src="./assets/img-lg/man.svg" alt>
                <span  [innerHtml]="input.Label|keepHtml"></span>
                <img class="img-button-add" src="./assets/img-lg/button_add.svg" alt>
            </span>
        </button>
    </div>
    </ng-template>
    <ng-template [ngSwitchCase]="false">
        <ng-container [ngSwitch]="input.Event">
            <ng-template [ngSwitchCase]="'multiplySection'">
                <button  type="button" class="button-multiply" *ngIf="userForm" mat-button
                    [id]="input.ControlId+idExtension"
                    [disabled]="(input.QuantityLimit && countSection >= (input.QuantityLimit | number))||IsLastToBeDeleted()"
                    (click)="eventButton()">
                    <img src="./assets/img-lg/button_add.svg" alt>
                    {{input.Label}}
                </button>
            </ng-template>
            <ng-template [ngSwitchCase]="'deleteSection'">
                <button  type="button" class="button-delete" *ngIf="userForm && currentComponent.Hierarchy!='1'" mat-button 
                    [id]="input.ControlId+idExtension" (click)="eventButton()">
                    <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.03846 2.23315H6.92308V1.85815C6.92308 1.23683 6.40649 0.733154 5.76923 0.733154H4.23077C3.59351 0.733154 3.07692 1.23683 3.07692 1.85815V2.23315H0.961538C0.430505 2.23315 0 2.6529 0 3.17065V3.92065C0 4.12777 0.172188 4.29565 0.384615 4.29565H9.61539C9.82781 4.29565 10 4.12777 10 3.92065V3.17065C10 2.6529 9.5695 2.23315 9.03846 2.23315ZM3.84615 1.85815C3.84615 1.65144 4.01875 1.48315 4.23077 1.48315H5.76923C5.98125 1.48315 6.15385 1.65144 6.15385 1.85815V2.23315H3.84615V1.85815Z"
                            fill="#797884" />
                        <path
                            d="M0.729904 5.04565C0.661274 5.04565 0.606587 5.10158 0.609856 5.16842L0.927163 11.6616C0.95649 12.2625 1.46274 12.7332 2.07957 12.7332H7.92043C8.53726 12.7332 9.04351 12.2625 9.07284 11.6616L9.39014 5.16842C9.39341 5.10158 9.33873 5.04565 9.2701 5.04565H0.729904ZM6.53846 5.98315C6.53846 5.77597 6.71058 5.60815 6.92308 5.60815C7.13558 5.60815 7.30769 5.77597 7.30769 5.98315V10.8582C7.30769 11.0653 7.13558 11.2332 6.92308 11.2332C6.71058 11.2332 6.53846 11.0653 6.53846 10.8582V5.98315ZM4.61538 5.98315C4.61538 5.77597 4.7875 5.60815 5 5.60815C5.2125 5.60815 5.38462 5.77597 5.38462 5.98315V10.8582C5.38462 11.0653 5.2125 11.2332 5 11.2332C4.7875 11.2332 4.61538 11.0653 4.61538 10.8582V5.98315ZM2.69231 5.98315C2.69231 5.77597 2.86442 5.60815 3.07692 5.60815C3.28942 5.60815 3.46154 5.77597 3.46154 5.98315V10.8582C3.46154 11.0653 3.28942 11.2332 3.07692 11.2332C2.86442 11.2332 2.69231 11.0653 2.69231 10.8582V5.98315Z"
                            fill="#797884" />
                    </svg>

                </button>
            </ng-template>
        </ng-container>
    </ng-template>
</ng-container>