

//namespace a{
 
    import {RequiredValidation} from './RequiredValidation' 
import {ComparingControlValueAmount} from './ComparingControlValueAmount' 
    export class InputExpandValidation extends RequiredValidation {
       
Minlength? :string;
Maxlength? :string;
Pattern? :string;
ComparingValueAmount? :ComparingControlValueAmount;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










