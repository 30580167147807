

//namespace a{
 
    import {Options} from './Options' 
import {ResearchOption} from './ResearchOption' 
    export class ResearchOptions extends Options {
       
Points? :string;
InputOptions? :ResearchOption[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










