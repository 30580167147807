import { Component, OnInit, Input } from '@angular/core';
import * as JsonObjects from '../../classes'
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

    @Input() input: JsonObjects.Header;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    idExtension: string = '';//מזהה עותק
    
     ngOnInit() {
         this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הוספת מזהה עותק
     }
     ngAfterViewInit() {  
        var self = this;       
        let elemetHtml = document.getElementById(`${this.input.ControlId+this.idExtension}`);
        if (elemetHtml) {
          let codeTagHtmls: NodeListOf<Element> = elemetHtml.querySelectorAll('code');
          if (codeTagHtmls.length > 0) {
            codeTagHtmls.forEach((codeTagHtml: Element) => {
                var codeContentSplit: string[] = codeTagHtml.innerHTML.split(',');//שם פונקציה, מזהה מקטע, מזהה פקד
                codeTagHtml.innerHTML =  eval(codeContentSplit[0]);
            })
          }
        }           
   }

}