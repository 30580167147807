

//namespace a{
 
    import {Process} from './Process' 
    export class ResearchProcess extends Process {
       

    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










