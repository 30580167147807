<div class="dialog">
  <div role="heading" class="dialog-header" *ngIf="data.Header" aria-level="2" mat-dialog-title
    [innerHtml]="data.Header| keepHtml">
  </div>
  <div mat-dialog-content>
    <ng-container *ngIf="(statusEmail$ | async)?.statusRequest as statusEmail">
      <div *ngIf="statusEmail=='BeforeRequest'; else response">
        <p *ngIf="data.Message" class="dialog-message" [innerHtml]="data.Message| keepHtml"></p>
        <div class="dialog-controls" *ngIf="data.Controls">
          <form [formGroup]="currentUserForm">
            <ng-template ngFor let-input [ngForOf]="data.Controls">
              <ng-container *ngIf="input.ControlType !='headerSection'" ControlFactory [input]=input
                [userForm]="currentUserForm" [currentComponent]="data.Component"></ng-container>
            </ng-template>
          </form>
        </div>
        <app-resend-sms [userDetails]=userDetailsEntered *ngIf="data.Type == 'endProcess'"></app-resend-sms>
        <app-btn-dialog *ngIf="data.Type=='processStoppedNotify'" [typeEmail]="data.Type"
          (sendEmailProcessStopped)="sendEmailProcessStopped($event)" formInvalid="false"></app-btn-dialog>
        <app-btn-dialog *ngIf="data.Type=='endProcess'" [typeEmail]="data.Type"
          (sendEmailEndProcess)="sendEmailEndProcess($event)" formInvalid="false"></app-btn-dialog>
        <app-btn-dialog *ngIf="data.Type=='TalkWithAs'" [typeEmail]="data.Type"
          (sendEmailTalkus)="sendEmailTalkus($event)" [formInvalid]="currentUserForm.invalid">
        </app-btn-dialog>
      </div>
      <ng-template #response class="statusEmail">
        <div *ngIf="statusEmail=='SucceedRequest'">
          בקשתך התקבלה והועברה לטיפול
          <br>
          תודה שבחרת בנו!
          <br>
          <p *ngIf="data.Type=='endProcess'">
            אין בהודעה הזו כדי לאשר את תקינות המסמכים
          </p>
        </div>
        <div *ngIf="statusEmail=='RejectedRequest'">
          נתוני טופס שגוים
        </div>
        <div *ngIf="statusEmail=='InRequest'">
          <mat-spinner></mat-spinner>
          השליחה מתבצעת, אנא המתן...
        </div>
        <div *ngIf="statusEmail=='FailRequest'">
          השליחה נכשלה, נסה שוב מאוחר יותר
        </div>
        <div class="wrap_phone_infinity">
          <div role="heading" aria-level="4"></div>
          <section role="list" aria-label="מספרי טלפון">
            <span role="listitem"><a class="phone_number_footer" href="tel:3488*">3488* </a></span>
            <span role="listitem"><a class="phone_number_footer" href="tel:09-9579000">09-9579000</a></span>
          </section>
        </div>
      </ng-template>
    </ng-container>
    <button *ngIf="data.Type!='endProcess'||(data.Type=='endProcess' && 
    (statusEmail$ | async)?.statusRequest!='InRequest'    
    )" aria-label="אינפיניטי. לחץ לסגירת ההודעה" class="btn-cancel" mat-button (click)="onNoClick()"><img
        src="./assets/img-lg/x.svg" alt></button>
  </div>
</div>