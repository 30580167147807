

//namespace a{
 
    
    export class Street {
       
Symbol? :string;
Name? :string;
SettlementSymbol? :string;
SettlementName? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










