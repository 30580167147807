import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import * as JsonObjects from '../../classes';
import { NgRedux } from '@angular-redux/store';
import { HostListener} from '@angular/core';


// import { Component } from '@angular/core';
// import { NgRedux } from '@angular-redux/store';
// import { IAppState } from '../../../../store/reducer'//'../../../../src/store/reducer';
// import * as JsonObjects from '../../../shared/classes'//'src/app/shared/classes';
// import { APIService } from '../services/rest.service';
// import { DatePipe } from '@angular/common';
// import { Observable, of, throwError } from 'rxjs';
// import { FileInput } from 'ngx-material-file-input';
// import { PageEvent } from '@angular/material/Paginator';
// import { MatDialog } from '@angular/material/dialog';
// import { DialogComponent } from '../../../shared/controls/dialog/dialog.component';
import { Router } from '@angular/router';
import { CounterActions } from '../../../../store/actions';
import { FormComponent } from '../../../shared/structureObject';
import { Serializable } from '../../../shared/classes/Serializable';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ContentObserver } from '@angular/cdk/observers';
import { promise } from 'protractor';
import { AccountService } from 'src/app/account.service';
import { interval, Subscription } from 'rxjs';





@Component({
  selector: 'app-otp-popup',
  templateUrl: './otp-popup.component.html',
  styleUrls: ['./otp-popup.component.scss']
})
export class OtpPopupComponent implements OnInit {
  countdown: number = 30; // זמן ההתחלה של השעון
  isDisabled: boolean = false; // מצב פעיל/לא פעיל
  interval: any; // משתנה לשמירת ה-interval
  otpInputs = [
    //{ value: '' },
    //{ value: '' },
    //{ value: '' },
    { value: '' }
  ];

  otps = new FormGroup({
    otpInput: new FormControl('')
  })
  RunNum: string;
  //otpUrl = 'https://example.com/otp';



  constructor(public apiService: ApiService,  public dialogRef: MatDialogRef<OtpPopupComponent>//,@Inject(MAT_DIALOG_DATA) public data: any
   ) 
  {
  }

  ngOnInit() {
    //call to the service
    this.apiService.SendPotentialCustomerDetails();
    this.apiService.sendOTP();
    this.startCountdown();
  }


  startCountdown() {
    this.isDisabled = true; // בקש להפעיל את ה-disabled
    this.countdown = 30; // אתחול השעון ל-30 שניות

    this.interval = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {
        clearInterval(this.interval); // עצור את הספירה לאחור
        this.isDisabled = false; // אפשר שוב את הכפתור
      }
    }, 1000); // כל שנייה
  }

  onKeydown(event: KeyboardEvent, index: number) {
    if (event.key === 'Backspace') {
      if (index > 0 && this.otpInputs[index].value === '') {
        this.otpInputs[index - 1].value = '';
        this.setFocus(index - 1);
      }
    } else if (index < this.otpInputs.length - 1 && event.key >= '0' && event.key <= '9') {
      this.otpInputs[index].value = event.key;
      this.setFocus(index + 1);
    }
  }

  setFocus(index: number) {
    const inputElement = document.querySelectorAll('.otp-input')[index];
    //inputElement.focus();
    //inputElement.select();
  }


  isValidOtp() {
    return this.otpInputs.every(otpInput => otpInput.value !== '');
  }

  submitOtp() {
    //const otp = this.otpInputs.map(otpInput => otpInput.value).join('');
    // Call API to verify OTP
    //this.checkOtpPassward();
    console.log('value: ', );
    let r:any;
    this.apiService.checkOtpPassward(this.otps.controls.otpInput.value).then(result => {
      r = result;
      console.log(r);
      if(r!= false){
        this.dialogRef.close({ data:  r })
      }
      else{
        $('.otp-error').removeClass('d-none-md');
      }
      
  });
    
    // this.closePopup();
  }

  @HostListener('document:keyup', ['$event'])
  handleDeleteKeyboardEvent(event) {
      if (event.key === 'Enter') {
          console.log('press enter: ',this.otps.controls.otpInput.value);
          if(this.otps.controls.otpInput.value!= null && this.otps.controls.otpInput.value!='')
          {
            let r:any;
            this.apiService.checkOtpPassward(this.otps.controls.otpInput.value).then(result => {
            r = result;
            console.log(r);
            if(r!= false){
              this.dialogRef.close({ data:  r })
              //send to server- Email, Telephone, First name, Last name-to SQL SERVER- עבור לקוח פוטנציאלי
            } 
            else{
              $('.otp-error').removeClass('d-none-md');
            }
            });
          }
 
      }
  }

  resendOtp(){
    this.startCountdown();
    $('.otp-error').addClass('d-none-md');
    this.otps.controls.otpInput.setValue('');
    this.apiService.sendOTP();
  }

  closePopup() {
    // Close popup
    this.dialogRef.close();
    //$(".popup-container").css("display","none");
  }
  ngOnDestroy() {
    clearInterval(this.interval); // נקי את ה-interval אם הקומפוננטה נהרסת
  }
  
}
