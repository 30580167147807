import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as JsonObjects from '../../../classes';

@Component({
  selector: 'app-btn-dialog',
  templateUrl: './btn-dialog.component.html',
  styleUrls: ['./btn-dialog.component.scss']
})
export class BtnDialogComponent implements OnInit {
  constructor() {
    
   }
  @Output() sendEmailProcessStopped: EventEmitter<void> = new EventEmitter();
  @Output() sendEmailTalkus: EventEmitter<void> = new EventEmitter();
  @Output() sendEmailEndProcess: EventEmitter<void> = new EventEmitter();
  @Input() formInvalid: boolean;
  @Input() typeEmail: string;
  ngOnInit(): void {
  }
  enterInformation() {//המקטע הנוכחי הושלם   
    if (this.typeEmail =='processStoppedNotify')
      this.sendEmailProcessStopped.next();
    else if (this.typeEmail == 'TalkWithAs')
      this.sendEmailTalkus.emit();
    else if (this.typeEmail == 'endProcess')
      this.sendEmailEndProcess.emit();
  }
}