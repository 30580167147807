import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const baseUrl = //"https://localhost:44358";//local
'https://join.infinity.co.il/dataapi';//prod
//'https://dev-join.infinity.co.il/dataapi';//dev

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) {
  }

  private account = new BehaviorSubject<string>("");

  async addAccount() {
    let body = new HttpParams();
    body = body.set('account', this.account.getValue());
    this.http.post(`${baseUrl}/api/values/AddAccount`, body).toPromise();
  }
  getAccount() {
    return this.account.getValue();
  }
  getAccountRec(): Observable<string> {
    return this.http.get(`${baseUrl}/api/values/getAccountNumber`, { responseType: 'text' })
  }
  public async setAccount() {
    this.account.next(await this.getAccountRec().toPromise().then(res => res.replace(/['"]+/g, '')))
  }
}
