<span class="WrapControl">
    <mat-form-field class="file" (dragover)="allowDrop($event)" (drop)="drop($event,input.ControlId+idExtension)"
        floatLabel="never">
        <label
            [ngClass]="{'disableDesignFile':isFileEmpty==false || (userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}"
            class="uploadFile" role="button" aria-controls="filename" [for]="input.ControlId+idExtension"
            [id]="input.ControlId+idExtension+'buttonlabel'">
            <div class="content_uploadFile">
                <img class="img_uploadFile img_file_Empty" src="./assets/img-lg/file_upload.svg" alt>
                <span class="text_uploadFile">לחץ לבחירת תמונה <br /> או גרור את התמונה לכאן</span>
            </div>
        </label>
        <label
            [ngClass]="{'disableDesignFile':isFileEmpty==true && !(userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}"
            class="disable uploadFile" role="button" aria-controls="filename" [for]="input.ControlId+idExtension"
            [id]="input.ControlId+idExtension+'buttonlabelDone'">
            <div [ngClass]="{'disableDesignFile':errorUploadingFile!=''}" class="content_uploadFile">
                <img class="img_uploadFile" src="./assets/img-lg/file_ok.svg"
                    alt="העלאת הקובץ הסתיימה ,הקובץ הועלה באופן תקין">
                <br />
                <div class="text_uploadFile_full">
                    <span [id]="input.ControlId+idExtension+'filename' ">
                        {{this.userForm.get(input.ControlId+idExtension).value&&this.userForm.get(input.ControlId+idExtension).value!=''&&this.userForm.get(input.ControlId+idExtension).value.files?this.userForm.get(input.ControlId+idExtension).value.files[0].name:''}}
                    </span>
                    <a aria-label="לחץ כאן להסרת הקובץ שהועלה" matSuffix *ngIf="!removableInput.empty"
                        (click)="removableInput.clear($event)">
                        <img class="" src="./assets/img-lg/trash_link.svg" alt>
                    </a>
                </div>
            </div>
            <div [ngClass]="{'disableDesignFile':errorUploadingFile=='' || !(userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}"
                class="content_uploadFile">
                <img class="img_uploadFile" src="./assets/img-lg/error_uploading_file.svg"
                    alt="שגיאה הקובץ לא הועלה באופן תקין">
                <br />
                <div class="text_uploadFile_full">
                    <span class="text_uploadFile_error">
                        {{validation(userForm.controls[input.ControlId+idExtension])}}
                    </span>
                </div>
            </div>

        </label>
        <ngx-mat-file-input [acceptFile]="input.Accept" class="hide" #removableInput
            (change)="fileChangeEvent($event.target.files,input.ControlId+idExtension)"
            [id]="input.ControlId+idExtension" [accept]="input.Accept"
            [formControl]="userForm.get(input.ControlId+idExtension)"></ngx-mat-file-input>

        <label [for]="input.ControlId+idExtension+'filename'" class="hide">uploaded file</label>
        <div class="description">
            <div [ngClass]="{'disableDesignFile':isFileEmpty==false ||  (userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}"
                class="content">
                העלאת <span [innerHtml]="input.Label| keepHtml"></span>
            </div>
            <div
                [ngClass]="{'disableDesignFile':isFileEmpty==true  && !(userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}">
                <div [ngClass]="{'disableDesignFile':errorUploadingFile!=''}" class="description-success content">
                    <span [innerHtml]="input.Label| keepHtml"></span> עלה בהצלחה
                </div>
                <div [ngClass]="{'disableDesignFile':errorUploadingFile=='' || !(userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)}"
                    class="description-error content">
                    <span
                        *ngIf="(userForm.controls[input.ControlId+idExtension].invalid && userForm.controls[input.ControlId+idExtension].dirty)">
                        שגיאה בהעלאת </span><span [innerHtml]="input.Label| keepHtml"></span>
                </div>
            </div>

        </div>
    </mat-form-field>

</span>