

//namespace a{
 
    import {ProcessFile} from './ProcessFile' 
    export class ProcessFiles {
       
ProcessNum? :string;
Files? :ProcessFile[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










