<ng-container [ngSwitch]="isClassExists('radio-toggle')||isClassExists('radio-switch')">
<ng-template [ngSwitchCase]="true">
    <mat-button-toggle [value]="input.OptionName"
                  name="{{input.OptionName}}" >
                  <span [innerHtml]="input.Label|keepHtml"></span>
    </mat-button-toggle> 
</ng-template>

<ng-template ngSwitchDefault>
      <mat-radio-button 
      [checked]="userForm.get(optionsControlId+idExtension).value === input.OptionName"
      (change)="focusControlPointed()" class="radio-button" [value]="input.OptionName" name="{{input.OptionName}}">
        <small><span [innerHtml]="input.Label|keepHtml"></span></small>
        <ng-container *ngIf="ControlPointed" ControlFactory [input]=ControlPointed [userForm]="userForm" [currentComponent]=currentComponent></ng-container>
</mat-radio-button>    
</ng-template>
</ng-container>