

//namespace a{
 
    import {Level} from './Level' 
    export class RefLevel extends Level {
       
RefLevelProcess? :string;
RefLevelNum? :string;
RefLevelTemplate? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










