import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const baseUrl = "https://localhost:44358";//local
//'https://join.infinity.co.il/dataapi';//prod
//'https://dev-join.infinity.co.il/dataapi';//dev

@Injectable({ providedIn: 'root' })
export class AutocompleteService {
    constructor(private http: HttpClient) { }


    GetSettlements(settlementName: string): Observable<any> {//קבלת אפשרויות לישובים
        return this.http.get(`${baseUrl}/api/Autocomplete/GetSettlements?settlementName=${settlementName}`);
    }

    GetStreets(streetName: string, settlementName: string): Observable<any> {//קבלת אפשרויות לרחובות
        return this.http.get(`${baseUrl}/api/Autocomplete/GetStreets?streetName=${streetName}&settlementName=${settlementName}`);
    }

    GetBanks(bankName: string): Observable<any> {//קבלת אפשרויות לבנקים
        return this.http.get(`${baseUrl}/api/Autocomplete/GetBanks?bankName=${bankName}`);
    }

    GetBankBranchs(bankBrancName: string, bankName: string): Observable<any> {//קבלת אפשרויות לסניפי בנק
        return this.http.get(`${baseUrl}/api/Autocomplete/GetBankBranchs?bankBrancName=${bankBrancName}&bankName=${ bankName}`);
    }

    IsExistsSettlements(settlementName: string): Observable<any> {//בדיקה האם הישוב קיים
        return this.http.get(`${baseUrl}/api/Autocomplete/IsExistsSettlements?settlementName=`+ settlementName);
    }

    IsExistsStreets(streetName: string, settlementName: string): Observable<any> {//בדיקה האם הרחוב קיים בישוב
        return this.http.get(`${baseUrl}/api/Autocomplete/IsExistsStreets?streetName=` + streetName + "&settlementName=" + settlementName);
    }

    //לא השתמשנו מחכים לאיפיון העיצובי
    IsExistsBanks(bankName: string): Observable<any> {//בדיקה האם הבנק קיים
        return this.http.get(`${baseUrl}/api/Autocomplete/IsExistsBanks?bankName=` + bankName);
    }

    //לא השתמשנו מחכים לאיפיון העיצובי
    IsExistsBankBranchs(bankBrancName: string, bankName: string): Observable<any> {//בדיקה האם הסניף קיים בבנק
        return this.http.get(`${baseUrl}/api/Autocomplete/IsExistsBankBranchs?bankBrancName=` + bankBrancName + "&bankName=" + bankName);
    }
}