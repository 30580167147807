<div *ngIf="(sectionData$|async) && isFinishLoad() else loading">
  <div id="content">
    <form *ngIf="customValidation.getErrorMessages()!=undefined&&isCompletedFormGroup" [formGroup]="userForm">
      <ng-template ngFor let-section [ngForOf]="currentLevel.Children">
        <ng-container
          *ngIf="isSectionsConditionTrue[section.Num +getIdExtension(SectionArray[section.Num])] && !SectionArray[section.Num].Pointed"
          [ngSwitch]="section.Template">
          <ng-template [ngSwitchCase]="'text'">
            <app-textSection [currentComponent]=section [currentSection]=SectionArray[section.Num]>
            </app-textSection>
          </ng-template>

          <ng-template [ngSwitchCase]="section.Template=='form'||section.Template=='research'?section.Template :''">
            <ng-container [ngSwitch]="SectionArray[section.Num].IsExpansionPanel">
              <ng-template [ngSwitchCase]="true">
                <mat-expansion-panel [expanded]="section.CompName==''||expansionPanelSection[section.Num].isOpen"
                  [disabled]="expansionPanelSection[section.Num].isDisable" (opened)="expansionPanelOpen(section.Num)"
                  (closed)="expansionPanelSection[section.Num].isOpen = false"
                  [ngClass]="SectionArray[section.Num].Class">
                  <mat-expansion-panel-header *ngIf="section.CompName!=''">
                    <mat-panel-title>
                      <div class="panel-title" role="heading" aria-level="4">
                        <span [innerHtml]="section.CompName|keepHtml"></span>
                        <span class="section-copy">{{section.Hierarchy}}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngIf="section.CompName==''" class="pre-space-panel"></div>
                  <app-section [currentLevel]=currentLevel [currentComponent]=section
                    [currentSection]=SectionArray[section.Num] (setSectionCompleted)=setSectionCompleted($event)>
                  </app-section>
                </mat-expansion-panel>
              </ng-template>
              <ng-template ngSwitchDefault>
                <app-section class="section" [currentLevel]=currentLevel [currentComponent]=section
                  [currentSection]=SectionArray[section.Num] (setSectionCompleted)=setSectionCompleted($event)>
                </app-section>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </ng-template>
    </form>
  </div>
  <div *ngIf="device==eDevice.Desktop||device==eDevice.Laptop" id="space"></div>
      <button *ngIf="isAsideApear()&&(device!=eDevice.Desktop&&device!=eDevice.Laptop)" aria-label=" אינפיניטי. לחץ כאן למעבר פירוט מידע" id="btnOpen-mobile" onclick="$('.asideContent-mobile')[0].style.visibility='inherit'">
        <img class="img_info" src="./assets/img-lg/Info.svg" alt  loading="lazy">
      </button>

      
      <img class="mobileFooterImg" src="./assets/img-sm/footer_img_mobile.svg" />
      

      <div 
        [ngClass]="{'footer-mobile':device==eDevice.Tablet||device==eDevice.Mobile ,' footerWithoutAside footer':device==eDevice.Desktop&&!isAsideApear(),'footerWithAside footer ':device==eDevice.Desktop&&isAsideApear()}">
        <app-button-ref-level *ngIf="indexLevel!=0" [indication]="currentLevel.Template=='refLevel'?'prevSecondaryProcess':'prevInProcess'"
          [content]="'חזור לשלב הקודם'"></app-button-ref-level>
        <app-button-ref-level [indication]="formSettings.StatusUser=='InProcess'?currentLevel.Template=='refLevel'?'nextSecondaryProcess': 'nextInProcess':'lastInProcess'"
          [content]="formSettings.StatusUser=='InProcess'?'המשך לשלב הבא':'תצוגה מקדימה'" [userForm]="userForm" (saveDataFrom)=saveDataFrom()
          (click)=invalidUserForm()></app-button-ref-level>
      </div>
      <div *ngIf="isAsideApear()&& isCompletedFormGroup" style="visibility: hidden;"
         [ngClass]="{'asideContent-mobile':device==eDevice.Tablet||device==eDevice.Mobile ,'asideContent': device==eDevice.Desktop||device==eDevice.Laptop }">
        <button *ngIf="device!=eDevice.Desktop&&device!=eDevice.Laptop" id="btnClose-mobile" onclick="$('.asideContent-mobile')[0].style.visibility='hidden'">
          <img class="img_close" src="./assets/img-lg/x.svg" alt=" אינפיניטי. לחץ כאן לסגירת פירוט מידע"/>
          <!-- <img class="img_close" src="./assets/img-lg/up.svg" alt=" אינפיניטי. לחץ כאן לסגירת פירוט מידע"/> -->        </button>
        <ng-template ngFor let-section [ngForOf]="getInformationSections()">
          <div 
          [ngClass]="device==eDevice.Tablet||device==eDevice.Mobile?('d-none-sm d-none-md'):('sidebar-title')"
          role="heading" aria-level="3" [innerHtml]="section.CompName|keepHtml"
          
          ></div>
          <app-section [currentLevel]=currentLevel [currentComponent]=section [currentSection]=SectionArray[section.Num]
            (setSectionCompleted)=setSectionCompleted($event)>
          </app-section>
        </ng-template>
      </div>
</div>
<ng-template #loading>
  <div *ngIf='formSettings.SecondaryProcess.StatusUser=="InLastLevel"'>
    <div class="loading cdk-overlay-container">
      <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
      <div class="cdk-global-overlay-wrapper">
        <div class="loading-dialog">
          <img src="./assets/img-sm/loading_screen_robot-sm.svg"
            srcset="./assets/img-sm/loading_screen_robot-sm.svg 800w, ./assets/img-lg/loading_screen_robot-lg.svg 1440w"
            alt="טוען נתונים" />
        </div>
      </div>
    </div>
  </div>
</ng-template>