

//namespace a{
 
    
    export class ProgrammingExpression {
       
Expression? :string;
WatchExpression? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










