<div *ngIf="currentSection&&userForm"
[ngClass]="device==eDevice.Tablet||device==eDevice.Mobile?(currentSection.ClassMobile?getClassSection(currentSection.ClassMobile):getClassSection(currentSection.Class)):getClassSection(currentSection.Class)">
  <ng-template ngFor let-input [ngForOf]="currentSection.Controls">
    <ng-container *ngIf="isControlsConditionTrue[input.ControlId+idExtension]&&input.Pointed!=true" #container [ngSwitch]="input.ControlType">
      <ng-template [ngSwitchCase]="'refSection'">
        <app-refSection [ngClass]="'app-refSection '+input.Class" [input]="input" [currentComponent]="currentComponent" (setSectionCompleted)=setCurrentSectionCompleted($event)></app-refSection>
      </ng-template>
      <ng-template [ngSwitchCase]="input.ControlType ==='button'&&input.Event == 'pointerLevel'?input.ControlType:''" >
        <app-button-ref-level class="app-button-ref-level" [indication]="'pointerLevel'" [userForm]="userForm" [button]="input" ></app-button-ref-level>
      </ng-template>
      <ng-template ngSwitchDefault>
        <ng-container *ngIf="input.ControlType !='headerSection'" ControlFactory [input]=input  [userForm]="userForm" [currentComponent]=currentComponent></ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</div>