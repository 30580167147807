

//namespace a{
 
    import {FieldControl} from './FieldControl' 
    export class FormFieldData {
       
Id? :string;
Label? :string;
Control? :FieldControl;
Type? :string;
Value? :string;
Rect? :number[];
RectPDF? :number[];
SectionLabel? :string;
LevelLabel? :string;
TypeControl? :string;
IsConditionalActivated? :boolean;
IsConditionalActivatedSectionOrLevel? :boolean;
IsIncorrectField? :boolean;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










