import { Injectable } from '@angular/core';
import * as JsonObjects from '../classes';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserValueEnterrd } from './user-value-entered.service';
import { CustomValidation } from '../custom-validation';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducer';
import { CounterActions } from '../../../store/actions';

@Injectable({ providedIn: 'root' })
export class AbstractControl {//TODO לשנות שם
    formControls: {
        [key: string]: FormControl | FormGroup
    } = {};//פקדי טופס
    userForm: FormGroup = new FormGroup({});//טופס משתמש

    private subscriptionControl: Subscription = new Subscription();
    constructor(private userValueService: UserValueEnterrd, private customValidation: CustomValidation, private ngRedux: NgRedux<IAppState>, private actions: CounterActions) {
        this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => { this.userForm = userForm });//שליפת טופס משתמש   
      
    }

    getFormControls() {//קבלת פקדי טופס
        return this.formControls;
    }

    initFormControls() {//איתחול פקדי טופס
        this.formControls = {};//יש צורך לאתחל את הפקדים
        this.subscriptionControl.unsubscribe();//איתחול האזנות של הפקדים
    }

    public FillControlsFormGroup(section: JsonObjects.RefJsonFile, controls: JsonObjects.Control[], resolve) {//יצירת טופס    
        //אם למשתנה שלב יש מספר סימן שזהו מקטע מוכפל יש להוסיף למזהי הפקדים שלו את המזהה בסוף
        let idExtension = section.Copy != null ? `_COPY_${section.Copy}` : '';
        let requests = controls.filter(formControl => formControl.ControlType != 'headerSection').map((formControl) => {//יצירת פקד טופס עבור כל פקד שבמקטע מלבד כותרות
            return new Promise((resolve) => {//החזרת הבטחה
                if (this.formControls[formControl.ControlId + '' + idExtension] == null)//אם הפקד קיים אל תיצור חדש        
                    this.formControls[formControl.ControlId + '' + idExtension] = this.createFormControls(formControl, idExtension, this.formControls);
                resolve(undefined);
            })
        });

        if (resolve)
            Promise.all(requests).then(() => {//כשהסתיימה יצירת פקדים
                resolve(undefined);
            })

    }

    //פונקציה שמקבלת פקדים ומחזירה טופס- לא בשימוש
    getFormControlsByControls(section, controls: JsonObjects.Control[]): Promise<{}> {//יצירת טופס    
        var formControls = {};//פקדי טופס
        //אם למשתנה שלב יש מספר סימן שזהו מקטע מוכפל יש להוסיף למזהי הפקדים שלו את המזהה בסוף
        let idExtension = section.Copy != null ? `_COPY_${section.Copy}` : '';
        let requests = controls.filter(formControl => formControl.ControlType != 'headerSection').map((formControl) => {//יצירת פקד טופס עבור כל פקד שבמקטע מלבד כותרות
            return new Promise((resolve) => {//החזרת הבטחה
                if (formControls[formControl.ControlId + '' + idExtension] == null)//אם הפקד קיים אל תיצור חדש        
                    formControls[formControl.ControlId + '' + idExtension] = this.createFormControls(formControl, idExtension, formControls);
                resolve(undefined);
            })
        });
        return new Promise(resolve => {
            Promise.all(requests).then(() => {//כשהסתיימה יצירת פקדים
                return resolve(formControls)
            });
        });

    }

    public createFormControls(formControl: JsonObjects.Control, idExtension: string, currentFormControls: {}) {//יצירת פקדי טופס 
        if (!formControl.ControlType.includes("checkbox"))
            return this.createControl(formControl, idExtension, currentFormControls);

        else//אם הפקד הוא תיבת סימון יש ליצור לכל אפשרות שמירה משלו
        {
            let controlOptions: JsonObjects.Options = formControl as JsonObjects.Options
            let groupControl: FormGroup = new FormGroup({});
            let control = this.createControl(controlOptions, idExtension, currentFormControls);//יצירת פקד בודד
            groupControl.setValidators(control.validator);//הכנסת הולידציות
            groupControl.setAsyncValidators(control.asyncValidator);//הכנסת הולידציות אסינכרוניות
            if (control.disabled)
                setTimeout(() => groupControl.disable());

            controlOptions.InputOptions.forEach((option) => {//checkbox יצירת פקד לכל אפשרות בנפרד בפקד  
                let subControl: FormControl = new FormControl('');
                if(typeof control.value === 'object' && control.value !== null && option.OptionName === Object.keys(control.value).toString()){
                    subControl.setValue(control.value[Object.keys(control.value).toString()]);
                }
                groupControl.addControl(option.OptionName + '' + idExtension, subControl);
            })

            return groupControl;//הכנסת הפקד והאפשריות למערך הפקדים
        }

    }

    public createControl(formControl: JsonObjects.Control, idExtension: string, currentFormControls: {}): FormControl {//יצירת פקד בודד
        let control: FormControl = new FormControl('');
        if (formControl instanceof JsonObjects.Input) {
            let controlInput: JsonObjects.Input = formControl as JsonObjects.Input;
            control.setValidators(controlInput.Validators ? this.customValidation.InsertValidation(control, controlInput) as [] : [])//הכנסת הולידציות
            control.setAsyncValidators(this.customValidation.InsertAsyncValidation(control, controlInput, currentFormControls, idExtension))//הכנסת הולידציות אסינכרוניות

            if (controlInput.ReadOnly == true)//במקרה שהפקד לקריאה בלבד והוא קיים בטפס
                control.disable();//השבת פקד

            if (controlInput.DefaultValue && controlInput.DefaultValue.Disabled) {//השמת ערך ברירת מחדל
                this.setDefaultValue(control, controlInput, idExtension, currentFormControls);
            }
            control.updateValueAndValidity();
        }

        return control;//החזר פקד
    }

    setDefaultValue(control: FormControl, controlInput: JsonObjects.Input, idExtension: string, currentFormControls: {}) {
        if (controlInput.DefaultValue.SourceValue == "Text") //השמת ערך טקסט חופשי
        {
            if(controlInput.ControlType == "checkbox")
            {
                let newValue: { [key: string]: Boolean} = { [controlInput.DefaultValue.Value]: true}; //השמת הערך TRUE בתוך אובייקט עם שם האופציה הנבחרת
                control.setValue(newValue);//השמת הערך    
            }
            else
                control.setValue(controlInput.DefaultValue.Value);//השמת הערך           
        }
             
        else //השמת ערך מפקד
        {
            if (controlInput.DefaultValue.Controls) {
                var DefaultValue: { [key: string]: string } = {};//ערך ברירת מחדל
                controlInput.DefaultValue.Controls.forEach(defaultValueControl => {
                    if (defaultValueControl.Section && defaultValueControl.Field)//לא חסר מזהה מקטע ופקד
                    {
                        if (currentFormControls[defaultValueControl.Field + idExtension])//אם הפקד קיים באותו טופס
                        {
                            //פקד מקושר רק לפקד אחד
                            if (controlInput.DefaultValue.IsBindingControl && controlInput.DefaultValue.Controls.length == 1)//אם הפקד מקושר ניצור ארועי האזנה לשינוים לשני הפקדים
                            {
                                currentFormControls[defaultValueControl.Field + idExtension].valueChanges.pipe(distinctUntilChanged())//כשהפקד הראשון משתנה שנה את השני
                                    .subscribe(val => {
                                        if (control)
                                            control.setValue(val);//אם זה לא עובד- אז יש לעדכן ברידקס ולא במקומי
                                    });
                                control.valueChanges.pipe(distinctUntilChanged())//כשהפקד השני משתנה שנה את הראשון
                                    .subscribe(val => {
                                        if (currentFormControls[defaultValueControl.Field + idExtension])
                                            currentFormControls[defaultValueControl.Field + idExtension].setValue(val);//אם זה לא עובד- אז יש לעדכן ברידקס ולא במקומי
                                    });
                            }
                            else {
                                if (controlInput.Validators instanceof JsonObjects.InputExpandValidation && controlInput.ReadOnly)
                                    var inputValidator: JsonObjects.InputExpandValidation = controlInput.Validators as JsonObjects.InputExpandValidation;

                                currentFormControls[defaultValueControl.Field + idExtension].valueChanges.pipe(distinctUntilChanged())//כשהפקד משתנה אז תחשב שוב את ערך פקד
                                    .subscribe(selectFieldValue => {
                                        if (control) {
                                            control.setValue(this.getValueControl(DefaultValue, defaultValueControl, selectFieldValue, controlInput))
                                            if (inputValidator && inputValidator.ComparingValueAmount != null && inputValidator.ComparingValueAmount.Amount != null) {
                                                //בדיקת הולידציה של השוואת סכומים לפקדים שונים
                                                this.checkValidationComparingControlValueAmount(control, idExtension, currentFormControls, DefaultValue, defaultValueControl, inputValidator)
                                            }

                                        }
                                    });

                                currentFormControls[defaultValueControl.Field + idExtension].statusChanges.pipe(distinctUntilChanged())//כשהסטטוס פקד משתנה אז תחשב שוב את ערך פקד
                                    .subscribe(currentStatus => {
                                        //אם הפקד ניהיה דיסאבל אז נחשב אין לחשב את הערך שלו
                                        if (currentStatus == 'DISABLED') {
                                            if (control) {
                                                control.setValue(this.getValueControl(DefaultValue, defaultValueControl, '', controlInput))
                                                if (inputValidator && inputValidator.ComparingValueAmount != null && inputValidator.ComparingValueAmount.Amount != null)
                                                    //בדיקת הולידציה של השוואת סכומים לפקדים שונים
                                                    this.checkValidationComparingControlValueAmount(control, idExtension, currentFormControls, DefaultValue, defaultValueControl, inputValidator)
                                            }
                                        }
                                        else {
                                            //אם הפקד היה דיסייבל אז יש להחזיר את ערכו
                                            if (currentFormControls[defaultValueControl.Field + idExtension].value != DefaultValue[defaultValueControl.Field]) {
                                                if (control) {
                                                    control.setValue(this.getValueControl(DefaultValue, defaultValueControl, currentFormControls[defaultValueControl.Field + idExtension].value, controlInput))
                                                    if (inputValidator && inputValidator.ComparingValueAmount != null && inputValidator.ComparingValueAmount.Amount != null)
                                                        //בדיקת הולידציה של השוואת סכומים לפקדים שונים
                                                        this.checkValidationComparingControlValueAmount(control, idExtension, currentFormControls, DefaultValue, defaultValueControl, inputValidator)
                                                }
                                            }
                                        }

                                    })
                               
                            }
                        }
                        else {//כאשר צריך לבצע האזנה לפקד בשלב אחר
                            // שלוף מהרידקס -שולף כאן למקרה של מחיקת והוספת פקד
                            var selectFieldValue = this.userValueService.selectUserValueEntered({ sectionId: defaultValueControl.Section, fieldId: defaultValueControl.Field });
                            if(controlInput.ControlType == "checkbox")
                            {
                                let Str :string=this.getValueControl(DefaultValue, defaultValueControl, selectFieldValue, controlInput)
                                // let ss=controlInput.DefaultValue.Value
                                let newValue: { [key: string]: Boolean} = { [Str]: true}; //השמת הערך TRUE בתוך אובייקט עם שם האופציה הנבחרת
                                control.setValue(newValue);//השמת הערך    
                            }
                            else
                            {
                                control.setValue(this.getValueControl(DefaultValue, defaultValueControl, selectFieldValue, controlInput))
                            }
                        }//השמת הערך
                    }
                });
            }
        }
    }

    getValueControl(DefaultValue: { [key: string]: string }, defaultValueControl: JsonObjects.SectionControl, selectFieldValue: any, controlInput: JsonObjects.Input, ) {
        DefaultValue[defaultValueControl.Field] = selectFieldValue;
        let value = '';
        //חישוב ערך הפקד
        if (controlInput.DefaultValue.CalculationControls == 'Addition')//חיבור
        {
            let t_controlInput: JsonObjects.Text = controlInput;
            if (t_controlInput.ValueType == 'number')//אם סוג הפקד הוא מספר
                Object.keys(DefaultValue).forEach(key => {
                    if(DefaultValue[key])
                       value = (Number(value) + Number(DefaultValue[key].toString().replace(/[\D]/gi, "").toString())).toString();
                });
            //אז יש לסכום את הערכים
            else
                Object.keys(DefaultValue).forEach(key => {
                    value += DefaultValue[key] ? DefaultValue[key] : '';
                });//אם לא, אז לשרשר את הערכים
        }

        else if (controlInput.DefaultValue.CalculationControls == 'Subtraction')//חיסור
        {
            Object.keys(DefaultValue).forEach(key => {
                value = (Number(value) - Number(DefaultValue[key].toString().replace(/[\D]/gi, "").toString())).toString();
            });
        }

        else if (controlInput.DefaultValue.CalculationControls == 'AgeCalculation')//חישוב גיל
        {
            let timeDiff = Math.abs(Date.now() - Date.parse(DefaultValue[Object.keys(DefaultValue)[0]]));
            value = (Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25)).toString();
        }

        return value != 'NaN' ? value : ' ';
    }

    //בדיקת הולידציה של השוואת סכומים לפקדים שונים
    checkValidationComparingControlValueAmount(control: FormControl, idExtension: string, currentFormControls: {}, DefaultValue: { [key: string]: string }, defaultValueControl: JsonObjects.SectionControl, inputValidator: JsonObjects.InputExpandValidation) {

        //שליפת פקד עם שגיאה אחרת- אם קיים פקד שגוי אז אין לבצע בדיקת ולידציה זו
        var controlIndexInvalidOtherError: number = Object.keys(DefaultValue).findIndex(key =>            
            this.userForm.controls[key]?.errors && !this.userForm.controls[key]?.getError('valueAmount')
        );
        
        //אם לא קיים, אז יש לבדוק ערך הפקדים
        if (controlIndexInvalidOtherError == -1) {

            //השוואת הסכום ועדכון השגיאות ברידקס
            var resultComparing = this.customValidation.isComparingControlValueAmountValid(inputValidator.ComparingValueAmount, control.value)
            //הורדת השגיאה מהפקדים האחרים
                Object.keys(DefaultValue).forEach(key => {//הורדת השגיאה הזו מהפקדים
                            setTimeout(() => { 
                            this.ngRedux.dispatch(this.actions.actions.setErrorControlForm(key, null)) 
                            },0)
                              
                });

            if (resultComparing)//הערכים חוקיים
                setTimeout(() =>{
                  this.ngRedux.dispatch(this.actions.actions.setErrorControlForm(defaultValueControl.Field + idExtension, null)) 
                }, 0);
            else //כשיש שגיאה על תוצאת הפקד
            {

                //יש לתת שגיאה למי שגרם לשגיאה
                if (DefaultValue[defaultValueControl.Field] && DefaultValue[defaultValueControl.Field] != '' && DefaultValue[defaultValueControl.Field] != '0')//בתנאי שהפקד שהשתנה יש בו ערך                         
                     //setTimeout(() => {
                        this.ngRedux.dispatch(this.actions.actions.setErrorControlForm(defaultValueControl.Field + idExtension, {
                            'valueAmount': {
                                operator: inputValidator.ComparingValueAmount.Operator,
                                amount: inputValidator.ComparingValueAmount.Amount
                            }
                        }))
                    //}, 0);

                else//אם הפקד הנוכחי ריק נותנים את השגיאה לפקד אחר שכן מלא
                {
                    //שליפת מזהה פקד אחר (עדיף אחרון) שאינו ריק כדי להציף עליו את השגיאה
                    let errorKey: string = Object.keys(DefaultValue).reverse().find(key => currentFormControls[key].value != '' && currentFormControls[key].value != '0')
                    if (errorKey)
                        setTimeout(() => {
                            this.ngRedux.dispatch(this.actions.actions.setErrorControlForm(errorKey, {
                                'valueAmount': {
                                    operator: inputValidator.ComparingValueAmount.Operator,
                                    amount: inputValidator.ComparingValueAmount.Amount
                                }
                            }))
                        }, 0);

                }
            }
        }
    }
}