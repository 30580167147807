import * as JsonObjects from '../classes'
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sizeField'
})
export class SizeFieldPipe implements PipeTransform {
    transform(value: JsonObjects.Input): number {
        var Maxlength: string = (value.Validators as JsonObjects.InputExpandValidation).Maxlength;
        return Maxlength&&Number(Maxlength)!=0&&Maxlength.trim().length?Maxlength.trim().length:63;
    }
}
