

//namespace a{
 
    import {Control} from './Control' 
    export class HeaderSection extends Control {
       
Class? :string;
Icon? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










