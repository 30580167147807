import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducer';
import * as JsonObjects from '../classes'

@Injectable({ providedIn: 'root' })
export class UserValueEnterrd {
  userDetailsEntered: JsonObjects.UserInputs;

  constructor(private ngRedux: NgRedux<IAppState>) {
    this.ngRedux.select(o => o.UserDetailsEntered).subscribe((UserDetailsEntered: JsonObjects.UserInputs) => {this.userDetailsEntered = UserDetailsEntered })
  }

  public selectUserValueEntered(userValue: { sectionId: string, fieldId: string }): string {//שליפת ערך משתמש של פקד מהרידקס 
    var selectSection = this.userDetailsEntered.Children.find(section => section.Id == userValue.sectionId);
    if (selectSection != null) {//אם יש מקטע            
      var selectField = selectSection.Children.find(field => field.Id == userValue.fieldId)//שליפת הפקד 
      if (selectField != null)
        return selectField.Value//החזרת ערך משתמש של הפקד           
    }
    return null;
  }
}