import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormComponent } from '../structureObject';


@Injectable({ providedIn: 'root' })
export class ResponsiveService {
    private changeWidthDevice = new Subject();
    public device:FormComponent.eDevice;

    constructor(public breakpointObserver: BreakpointObserver)
    {
      this.breakpointObserver
      .observe([FormComponent.eDeviceBreakpoint.Mobile])//(max-width: 599.99px) and (orientation: portrait)"
      .subscribe((state: BreakpointState) => {//לא רואה מתי יבוא לידי שימוש
        if (state.matches) {
          this.device=FormComponent.eDevice.Mobile;
          this.changeWidthDevice.next();
        }          
      });
        this.breakpointObserver
        .observe([FormComponent.eDeviceBreakpoint.Tablet])//(max-width: 599.99px) and (orientation: portrait)"
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.device=FormComponent.eDevice.Tablet;
            this.changeWidthDevice.next();
          }          
        });
        this.breakpointObserver
        .observe([FormComponent.eDeviceBreakpoint.Laptop])//(max-width: 599.99px) and (orientation: portrait)"
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.device=FormComponent.eDevice.Laptop;
            this.changeWidthDevice.next();
          }          
        });
        this.breakpointObserver
        .observe([FormComponent.eDeviceBreakpoint.Desktop])//(max-width: 599.99px) and (orientation: portrait)"
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.device=FormComponent.eDevice.Desktop;  
            this.changeWidthDevice.next();
          }          
        }); 
    }

    getDevice() {//יצירה ראשונית 
        if(this.breakpointObserver.isMatched(FormComponent.eDeviceBreakpoint.Desktop))
        this.device=FormComponent.eDevice.Desktop;
        else if(this.breakpointObserver.isMatched(FormComponent.eDeviceBreakpoint.Laptop))
        this.device=FormComponent.eDevice.Laptop;
        else if(this.breakpointObserver.isMatched(FormComponent.eDeviceBreakpoint.Tablet))
        this.device=FormComponent.eDevice.Tablet;
        else if(this.breakpointObserver.isMatched(FormComponent.eDeviceBreakpoint.Mobile))
        this.device=FormComponent.eDevice.Mobile;
       return  this.device;            
    }

    getUpdatesDeviceSubject(): Observable<any> {//הרשמה למאזין
        return this.changeWidthDevice.asObservable()
    }

    removeUpdateDeviceSubject() {//ביטול הרשמה
        this.changeWidthDevice.complete();    
    }
}