

//namespace a{
 
    import {Control} from './Control' 
    export class Header extends Control {
       

    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










