import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
@Injectable({
  providedIn: 'root'
})
export class ShowSumService {

  private textControlHidden: {
    [key: string]: BehaviorSubject<any>
  } = {};
  private hidden = new BehaviorSubject<boolean>(false);

  constructor() { }

  getHidden(controlId): Observable<any> {
    return this.textControlHidden[controlId] = new BehaviorSubject<boolean>(false);
  }

  setHidden(controlId: string, isHidden: boolean): void {
    this.textControlHidden[controlId].next(isHidden);
  }

}
