import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputComponent } from '../controls/input/input.component';
import { RadioComponent } from '../controls/radio/radio.component';
import { SelectComponent } from '../controls/select/select.component';
import { SignaturePadComponent } from '../controls/signature-pad/signature_pad.component';
import { TextControlComponent } from '../controls/text-control/textControl.component';
import { FileComponent } from '../controls/uploadFile/uploadFile.component';
import { AutocompleteComponent } from '../controls/autocomplete/autocomplete.component';
import { ButtonComponent } from '../controls/button/button.component';
import { ChartComponent } from '../controls/chart/chart.component';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';
import { DateComponent } from '../controls/date/date.component';
import { HeaderComponent } from '../controls/header/header.component';
import { HowItWorkComponent } from '../controls/how-it-work/how-it-work.component';

import * as JsonObjects from '../classes';
import { HowItWork } from '../classes';
@Directive({
  selector: '[ControlFactory]'
})
export class ControlFactoryComponent implements OnInit, OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
  }

  @Input() input: JsonObjects.Control;//הפקד הנוכחי
  @Input() currentComponent: JsonObjects.RefJsonFile;
  @Input() userForm: FormGroup;//טופס משתמש

  insertInputsComponent = new EventEmitter<string>();

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef, private cdr: ChangeDetectorRef) { }

  ComponentDynamic;
  component;
  ngOnInit() {
    const dicComponent = this.getDicComponent();
    const factory = this.resolver.resolveComponentFactory<any>(dicComponent[this.input.ControlType]);
    this.component = this.container.createComponent(factory);
    this.component.instance.input = this.input;
    this.component.instance.userForm = this.userForm;
    this.component.instance.currentComponent = this.currentComponent;
  }
  getDicComponent() {
    return {
      "input": InputComponent,
      "uploadFile": FileComponent,
      "checkbox": CheckboxComponent,
      "research-checkbox": CheckboxComponent,
      "radio": RadioComponent,
      "research-radio": RadioComponent,
      "select": SelectComponent,
      "research-select": SelectComponent,
      "button": ButtonComponent,
      "autocomplete": AutocompleteComponent,
      "date": DateComponent,
      "header": HeaderComponent,
      "headerSection": HeaderComponent,
      "textControl": TextControlComponent,
      "chart": ChartComponent,
      "signature": SignaturePadComponent,
      "howItWork" :  HowItWorkComponent
    };
  }
}

