

//namespace a{
 
    
    export class Email {
       
Body? :string;
Subject? :string;
From? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










