

//namespace a{
 
    import {ResearchProcess} from './ResearchProcess' 
import {Classification} from './Classification' 
    export class RiskProfileProcess extends ResearchProcess {
       
Classifications? :Classification[];
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










