
<div id="wrapper">


    <div id="contentPage">     
      <div class="titleHeader">העמוד המבוקש איננו זמין</div>
      <div id="displayPage">
      <img aria-hidden="true" id="electricalOutlet" srcset="./assets/img-sm/electricalOutlet-sm.svg 600w, ./assets/img-lg/electricalOutlet-lg.svg 1440w" src="./assets/img-sm/electricalOutlet-sm.svg"/>
    
      <div id="textPage">                   
            <img id="electricalOutletMan" srcset="./assets/img-sm/404-sm.svg 800w, ./assets/img-lg/404-lg.svg 1440w"
            src="./assets/img-sm/404-sm.svg" alt="אינפינטי. דף 404 "/>
            <button onclick="window.location.href='/'" mat-raised-button mat-button class="custom-button btn btn-primary-outline pull-right">
              חזרה לחוף מבטחים          
            </button>
      </div>  
      <img  aria-hidden="true" id="electricalOutletMan" srcset="./assets/img-sm/electricalOutletMan-sm.svg 600w, ./assets/img-lg/electricalOutletMan-lg.svg 1440w" src="./assets/img-sm/electricalOutletMan-sm.svg"/>
   </div>
    </div>
</div>