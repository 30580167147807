import { Component, OnInit, Input ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as JsonObjects from '../../classes'
@Component({
  selector: 'app-notification-base',
  template: ``
})
export class NotificationBaseComponent implements OnInit {

  ngOnInit() {}

  constructor() {}
}