import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as JsonObjects from '../../../classes'
import { NotificationBaseComponent } from '../notificationBaseComponent';

@Component({
    selector: 'app-notificationContactInfo.component',
    templateUrl: './notificationCustomerContactInfo.component.html',
    styleUrls: ['./notificationCustomerContactInfo.component.scss']
})
export class NotificationCustomerContactInfoComponent extends NotificationBaseComponent{
    constructor(public dialogRef: MatDialogRef<NotificationCustomerContactInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: JsonObjects.Notification) {
            super();
            data.Message;
         }
      
    onNoClick(): void {
        this.dialogRef.close();
    }   
}