

//namespace a{
 
    import {ConditionalLogicGroup} from './ConditionalLogicGroup' 
    export class Section {
       
SectionNum? :string;
Disabled? :boolean;
Pointed? :boolean;
Class? :string;
ClassMobile?:string;
IsExpansionPanel? :boolean;
ConditionalLogicGroup? :ConditionalLogicGroup;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










