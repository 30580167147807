import { menuComponent } from './menu/menu.component';
import { DirectAccessGuard } from './shared/services/directAccessGuard.service';
import { LevelComponent } from './menu/level/level.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const Routes: Routes = [
  { path: '', redirectTo: '/l/registeration/0', pathMatch: 'full' },
  //
  {
    path: "l", component: menuComponent,
    children: [
      {
        path: "registeration/:id", canActivate: [DirectAccessGuard], component: LevelComponent,
        children: [
          { path: "secondaryProcess/:id_second", component: LevelComponent }
        ],
      },
      { path: 'ViewFiles', loadChildren: () => (import('./export-files/export-files.module').then(mod=>mod.ExportFilesModule)) },
    ],
  },

  { path: 'Error/:Type', component: ErrorPageComponent },
  { path: '**', component: ErrorPageComponent },
];
export const appRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(Routes, { relativeLinkResolution: 'legacy' })