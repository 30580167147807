

//namespace a{
 
    import {Option} from './Option' 
    export class ResearchOption extends Option {
       
Points? :number;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










