

//namespace a{
 
    
    export class Option {
       
OptionName? :string;
Label? :string;
Tooltip? :string;
ControlNumPointed? :string;
    }

// $instanceOf
// $convertInstanceOf
// $convertInstanceOfArr
//}










