import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import * as JsonObjects from '../classes';
@Directive({
    selector: 'input[minNumber]',
    providers: [
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: MinLengthDirective
        }
    ]
})
export class MinLengthDirective implements Validator {
    @Input('minNumber')
    input: JsonObjects.Input ;

    constructor() { }
    validate(control: AbstractControl): ValidationErrors | null {
        if(this.input instanceof  JsonObjects.Text)
        if ((this.input as JsonObjects.Text).ValueType == "number" && this.input.Validators instanceof  JsonObjects.InputExpandValidation ) {
            let minlength=(this.input.Validators as  JsonObjects.InputExpandValidation ).Minlength;
            if(minlength)
            {
            let minNumNumber: string = minlength.toString().replace(/\D/g, '');
            if (!control.value)
                return null;
            let value = control.value.toString().replace(/\D/g, '');//הורדת פסיקים	

            if (Number(value) < Number(minNumNumber))//אם ערך הפקד קטן מהמינימום		
                return { 'min': { 'min': minNumNumber } };//לא חוקי
            }
        }
        return null;

    }

}

