
export namespace FormComponent {
  export enum eDevice{    
    Desktop="Desktop",
    Laptop="Laptop",
    Mobile="Mobile",
    Tablet="Tablet"    
  }
  export enum eDeviceBreakpoint{    
    Desktop="(min-width: 1321px)",  
    Laptop="(min-width: 992px) and (max-width: 1320px)",  
    Tablet="(max-width: 991.99px)",
    Mobile="(max-width: 576px)"
  }
  export interface ErrorServer {
    ErrorMessage?: string;
    LevelNum?: string;
    NumSection?: string;
    ControlId?: string;
  }
  export enum eStatusRequestServer {//מצב קריאה לשרת
    BeforeRequest = "BeforeRequest",
    InRequest = "InRequest",//באמצע תהליך
    FailRequest="FailRequest",
    SucceedRequest="SucceedRequest",
    RejectedRequest ="RejectedRequest"
  }
  export enum eStatusUser {//מצב משתמש
    StartProcess="StartProcess",
    InProcess = "InProcess",//באמצע תהליך
    InLastLevel = "InLastLevel",//בשלב האחרון
    EndProcess = "EndProcess",//סיים תהליך
    CancelProcess="CancelProcess"
  }
  export enum eStatusNavigate{    
    prevSecondaryProcess="prevSecondaryProcess",
    prevInProcess="prevInProcess",
    nextSecondaryProcess="nextSecondaryProcess",
    nextInProcess="nextInProcess",
    pointerLevel="pointerLevel",
    backToProcess="backToProcess"
  }
  export interface Navigate{
    StatusNavigate?:eStatusNavigate,
    LevelNum:string

  }
  export interface MappedData <T>{
    message: T;
    statusRequest: eStatusRequestServer;
  }
  export interface GeneralSetting {
    StatusUser?: eStatusUser;//מצב משתמש
    ProcessNum?: string;//תהליך הנוכחי
    RunNum?: string;//מזהה ריצה נוכחית כאשר מעלים קבצים לשרת ישלח מזהה זה
    IsCompletedSections: boolean;//האם הושלמו בניית המקטעים
    ServerErrorMessage?: ErrorServer;
    SecondaryProcess?:GeneralSetting;
  }
   

}
